import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import PatientForm from '../Patient/PatientForm';
import { VisitDetailsFormValues } from './VisitDetailsEdit';

const ViewPatient = () => {
	const { t } = useTranslation();
	const patientUU = useWatch<VisitDetailsFormValues, 'Patient.UU'>({ name: 'Patient.UU' });
	const [showPatientForm, setShowPatientForm] = useState(false);
	return patientUU ? (
		<>
			<Button
				variant="link"
				className="text-decoration-none"
				onClick={(event) => {
					event.preventDefault();
					setShowPatientForm(true);
				}}
			>
				{t(uiText.visit.form.patient.DETAILS)}
			</Button>
			{showPatientForm && (
				<PatientForm
					renderAsModal={true}
					canSaveMany={false}
					uuid={patientUU}
					onFinish={() => {
						setShowPatientForm(false);
					}}
				/>
			)}
		</>
	) : null;
};

export default ViewPatient;
