import { BaseEntityDB } from './BaseEntity';
import DBFilter from './DBFilter';

export const businessPartnerGroupName = {
	INSURANCE_CAPTITATION: 'Capitation Insurance - DO NOT CHANGE',
	OTC: 'OTC Patient',
	PATIENTS: 'Patients - DO NOT CHANGE',
	STANDARD: 'Standard',
} as const;

export const businessPartnerGroupSubType = { Insurance: 'I', Waiver: 'W', Donation: 'D' } as const;

export interface BusinessPartnerGroupDB extends BaseEntityDB {
	bh_subtype: string;
}

export const businessPartnerGroupInsurerAndDonorFilter = () =>
	DBFilter<BusinessPartnerGroupDB>()
		.property('bh_subtype')
		.isIn([businessPartnerGroupSubType.Donation, businessPartnerGroupSubType.Insurance]);
