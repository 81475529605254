import { useContext, useEffect, useState } from 'react';
import UserContext from '../contexts/UserContext';
import { PageUuid } from '../utils/Constants';
import { Ad_RoleWithWindowAccessQuery } from '../graphql/__generated__/graphql';

/**
 * Gets the privileges for the logged-in user for the specified page
 * @param pageUuid The uuid of the page to get privileges for
 */
export default function useActionPrivileges(pageUuid: PageUuid) {
	const windowAccessList: NonNullable<Ad_RoleWithWindowAccessQuery['AD_Role']>['AD_Window_AccessList'] =
		useContext(UserContext).role.AD_Window_AccessList;

	const canView = !!windowAccessList?.find((windowAccess) => windowAccess.AD_Window.UU === pageUuid);
	const canWrite =
		windowAccessList?.find((windowAccess) => windowAccess.AD_Window.UU === pageUuid && windowAccess.IsReadWrite) ||
		false;
	const canDeactivate =
		windowAccessList?.find((windowAccess) => windowAccess.AD_Window.UU === pageUuid && windowAccess.BH_CanDeactivate) ||
		false;

	const [disableWrite, setDisableWrite] = useState(!canWrite);
	const [disableView, setDisableView] = useState(!canView);
	const [disableDeactivate, setDisableDeactivate] = useState(!canDeactivate);

	useEffect(() => {
		setDisableWrite(!canWrite);
		setDisableDeactivate(!canDeactivate);
		setDisableView(!canView);
	}, [canView, canWrite, canDeactivate, pageUuid]);

	return {
		canWrite: !disableWrite,
		disableWrite,
		canView: !disableView,
		disableView,
		canDeactivate: !disableDeactivate,
		disableDeactivate,
	} as const;
}
