import axios from 'axios';
import AuthorizationResponse from '../models/AuthorizationResponse';
import ChangeAccess from '../models/ChangeAccess';
import { configs } from '../utils/Configs';
import { LOGIN_URI } from '../utils/Constants';
import BaseService from './BaseService';

export default class AuthService extends BaseService {
	map(data: {}): {} {
		throw new Error('Method not implemented.');
	}
	reverseMap(data: {}): {} {
		throw new Error('Method not implemented.');
	}
	loginUrl = `${configs.apiUrl}${LOGIN_URI}`;
	changeAccessUrl = `${configs.apiUrl}/change-access`;

	/**
	 * Login
	 * @param username
	 * @param password
	 */
	async login(
		username: string,
		password: string,
		clientUuid: string,
		roleUuid: string,
		organizationUuid: string,
		warehouseUuid: string,
		language: string,
	) {
		const loginCredentials = {
			username,
			password,
			clientUuid,
			roleUuid,
			organizationUuid,
			warehouseUuid,
			language,
		};
		return axios.post<AuthorizationResponse>(this.loginUrl, loginCredentials);
	}

	async changeAccess(changeAccess: ChangeAccess) {
		return axios.post<AuthorizationResponse>(this.changeAccessUrl, changeAccess);
	}
}
