import { useApolloClient } from '@apollo/client';
import { debounce } from 'lodash';
import { Suspense, useEffect, useMemo } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import {
	C_BPartnerForPatientOpenBalanceWaivingDocument,
	C_ChargeForWaivingOpenBalancesDocument,
	C_DocTypeForFormsDocument,
	C_InvoiceAndC_InvoiceLinesSaveForWaivingOpenBalancesDocument,
} from '../../graphql/__generated__/graphql';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import {
	ChargeDB,
	chargeDefaultNames,
	chargeOneOffsFilter,
	DBFilter,
	DocAction,
	documentBaseType,
	referenceListUuids,
} from '../../models';
import { debounceTimeouts } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import { getDocumentBaseTypeFilter } from '../../utils/ModelUtils';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import LoadSpinner from '../LoadSpinner/LoadSpinner';

type WaiveOpenBalanceFormFields = {
	amountToWaive: number | null;
	waiveAll: boolean;
};

type WaiveOpenBalanceProps = { C_BPartnerUU: string; onClose: () => void };

const fetchCustomerInvoiceDocumentTypeArguments = [documentBaseType.ARInvoice, null, null, true, false, false] as const;

function WaiveOpenBalanceBase({ C_BPartnerUU, onClose }: WaiveOpenBalanceProps) {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { data: [patient, badDebtWriteOffCharge, customerInvoiceDocumentType] = [] } = useSuspenseAsync(
		'waive-open-balance-' + C_BPartnerUU,
		async () =>
			Promise.all([
				graphqlClient
					.query({
						query: C_BPartnerForPatientOpenBalanceWaivingDocument,
						variables: { UU: C_BPartnerUU },
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.C_BPartner),
				graphqlClient
					.query({
						query: C_ChargeForWaivingOpenBalancesDocument,
						variables: {
							Filter: DBFilter<ChargeDB>()
								.property('name')
								.equals(chargeDefaultNames.BAD_DEBT)
								.and(chargeOneOffsFilter())
								.toString(),
						},
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.C_ChargeGet.Results[0]),
				graphqlClient
					.query({
						query: C_DocTypeForFormsDocument,
						variables: { Filter: getDocumentBaseTypeFilter(...fetchCustomerInvoiceDocumentTypeArguments).toString() },
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.C_DocTypeGet.Results[0]),
			]),
	);
	const patientsOpenBalance = patient?.TotalOpenBalance || null;

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		control,
		formState: { errors, submitCount },
	} = useForm<WaiveOpenBalanceFormFields>({ defaultValues: { waiveAll: true, amountToWaive: patientsOpenBalance } });

	const [{ loading }, saveWaivedDebt] = useCustomAsyncFn<SubmitHandler<WaiveOpenBalanceFormFields>>(
		async (formData) => {
			let amountToWaive = formData.waiveAll
				? patientsOpenBalance || 0
				: (formData.amountToWaive || 0) > (patientsOpenBalance || 0)
					? patientsOpenBalance || 0
					: formData.amountToWaive || 0;

			const invoiceUU = v4();
			await graphqlClient.mutate({
				mutation: C_InvoiceAndC_InvoiceLinesSaveForWaivingOpenBalancesDocument,
				variables: {
					C_Invoice: {
						UU: invoiceUU,
						C_BPartner: { UU: C_BPartnerUU },
						C_DocTypeTarget: customerInvoiceDocumentType ? { UU: customerInvoiceDocumentType.UU } : undefined,
						DateInvoiced: new Date().getTime(),
						IsSOTrx: customerInvoiceDocumentType?.IsSOTrx,
						PaymentRule: { UU: referenceListUuids.CASH_DRAWER },
					},
					C_InvoiceLines: [
						{
							C_Invoice: { UU: invoiceUU },
							C_Charge: badDebtWriteOffCharge ? { UU: badDebtWriteOffCharge.UU } : undefined,
							Qty: 1,
							Price: -amountToWaive,
						},
					],
					C_InvoiceUU: invoiceUU,
					DocumentAction: DocAction.COMPLETE,
				},
			});
			onClose();
		},
		[customerInvoiceDocumentType, C_BPartnerUU, badDebtWriteOffCharge, graphqlClient, onClose],
	);
	const debouncedSubmit = useMemo(() => debounce(saveWaivedDebt, debounceTimeouts.SUBMIT_HANDLER), [saveWaivedDebt]);
	const shouldWaiveAll = watch('waiveAll');
	const amountEnteredToWaive = watch('amountToWaive');

	useEffect(() => {
		if (shouldWaiveAll) {
			setValue('amountToWaive', patientsOpenBalance);
		}
	}, [shouldWaiveAll, patientsOpenBalance, setValue]);
	useEffect(() => {
		if (amountEnteredToWaive !== null && amountEnteredToWaive > (patientsOpenBalance || 0)) {
			setValue('amountToWaive', patientsOpenBalance, { shouldValidate: submitCount > 0 });
		}
	}, [amountEnteredToWaive, patientsOpenBalance, setValue, submitCount]);

	return (
		<>
			<Modal show>
				<Form onSubmit={handleSubmit(debouncedSubmit)}>
					<Modal.Header>{t(uiText.patient.button.WAIVE_OPEN_BALANCE)}</Modal.Header>
					<Modal.Body>
						{loading ? (
							<LoadSpinner inline title={t(uiText.patient.PROCESSING)} />
						) : (
							<Row className="gy-3">
								<Col xs={12}>
									{t(uiText.patient.LABEL)}: {patient?.Name}, {t(uiText.patient.OPEN_BALANCE)}:{' '}
									{patient?.TotalOpenBalance}
								</Col>
								<Col xs={6} className="d-flex align-items-center">
									<Form.Check id="waiveAll" label={t(uiText.patient.WAIVE_ALL)} {...register('waiveAll')} />
								</Col>
								<Col xs={6} className="d-flex align-items-center">
									<Controller<WaiveOpenBalanceFormFields, 'amountToWaive'>
										name="amountToWaive"
										control={control}
										rules={{ max: patientsOpenBalance || 1, min: 1, required: true }}
										render={({ field }) => (
											<FormatNumberInput
												aria-label={t(uiText.patient.AMOUNT_TO_WAIVE)}
												disabled={shouldWaiveAll}
												{...field}
											/>
										)}
									/>
								</Col>
								{errors.amountToWaive && (
									<Col xs="auto">
										<span className="text-danger">{t(uiText.patient.error.INCORRECT_WAIVE_AMOUNT)}</span>
									</Col>
								)}
							</Row>
						)}
					</Modal.Body>
					{!loading && (
						<Modal.Footer>
							<Button variant="danger" onClick={onClose}>
								{t(uiText.patient.button.CANCEL)}
							</Button>
							<Button type="submit" variant="success" className="ms-auto">
								{t(uiText.patient.button.WAIVE)}
							</Button>
						</Modal.Footer>
					)}
				</Form>
			</Modal>
		</>
	);
}

function WaiveOpenBalance(props: WaiveOpenBalanceProps) {
	const { t } = useTranslation();
	return (
		<Modal show>
			<Suspense
				fallback={
					<Modal.Body>
						<LoadSpinner inline title={t(uiText.patient.LOADING)} />
					</Modal.Body>
				}
			>
				<WaiveOpenBalanceBase {...props} />
			</Suspense>
		</Modal>
	);
}

export default WaiveOpenBalance;
