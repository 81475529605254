export const VISIT_INVOICE = '477cdda4-82ff-4bac-834f-08de384df412';
export const VISIT_INVOICE_PARAM_BH_VISIT_UU = '989edccc-2afd-4096-ad58-bf076ab4b698';
export const VISIT_INVOICE_PARAM_SHOW_INSURANCE = 'b5f0a5bb-fa16-43fd-bca6-8fe675165c57';
export const DEBT_PAYMENT_RECEIPT = '173a691b-ba89-4987-9216-9b3f0a60c864';
export const PATIENT_RECEIPT = '30dd7243-11c1-4584-af26-5d977d117c84';
export const PAYMENT_TRAIL = 'a7ac9f65-45d7-4ae0-80f3-72019de35a4a';
export const OPEN_BALANCE_INVOICE = '199f56a6-8e1f-47b4-8f22-e2bdb8da7505';
export const PATIENT_VISIT_SUMMARY = '53fc02c9-001a-4536-8d2a-3c003c93effa';
export const CLEAN_EXPIRED_STOCK = 'e79541fb-9b70-4a10-bfef-7401401b8c56';
export const PRESCRIPTION_FORM = '9fdbe1af-a79c-49ca-8081-0d32de89e053';
export const LAB_REPORT = '1a7175fe-2afe-4404-9c56-58d2fda9bc57';
