import { BaseEntityDB } from './BaseEntity';

export const fieldUuid = {
	CLINICAL_NOTES: '1b28d618-0529-4680-bfe2-2fcb5025fecd',
	BMI: '70b5bfa1-9c75-4fea-bf7e-076f4f4163fb',
	HEIGHT: '2842fb94-b841-4973-903e-89c7f24455b2',
	LAST_MENSTRUAL_PERIOD: 'da211453-46c6-44a6-8ee2-ded8f8630b23',
	WEIGHT: 'e0f68d60-0610-4caa-9dc3-b0143101ccd3',
} as const;

export interface FieldDB extends BaseEntityDB {}
