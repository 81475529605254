import { ApolloClient, useApolloClient, useLazyQuery } from '@apollo/client';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	Ad_Ref_ListPatientTypesForVisitsDocument,
	Ad_Ref_ListPatientTypesForVisitsQueryVariables,
	Ad_Ref_ListProcessStagesForVisitsDocument,
	Ad_Ref_ListProcessStagesForVisitsQueryVariables,
	Ad_Ref_ListReferralsForVisitsDocument,
	Ad_Ref_ListReferralsForVisitsQueryVariables,
	Ad_UserForVisitCliniciansDocument,
	Ad_UserForVisitCliniciansQueryVariables,
	Bh_VisitForEditingQuery,
	Bh_VisitInput,
	C_BPartnerDisplayForVisitsFragmentDoc,
	C_BPartnerForVisitsDocument,
	C_BPartnerSearchForVisitsDocument,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import { ClientUU, DBFilter, referenceUuids, roleUuid, UserDB } from '../../models';
import { ReferenceListDB, sortProcessStageList } from '../../models/ReferenceList';
import { pageUuid } from '../../utils/Constants';
import { getPatientSearchFilter } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import EntityLookup from '../entity-lookup/EntityLookup';
import PatientForm from '../Patient/PatientForm';
import ViewPatient from './ViewPatient';
import { VisitFormValues } from './VisitForm';

export type VisitDetailsFormValues = {
	BH_Clinician_User: { UU: string | null };
	BH_NewVisit: boolean;
	BH_PatientType: { UU: string | null };
	BH_Process_Stage: { UU: string | null };
	bh_referral: { UU: string | null };
	BH_ReferredFromTo: string | null;
	BH_VisitDate: Date;
	Patient: { UU: string | null };
};

export const convertToVisitDetailsFormFields: (
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
	patientUU?: string,
) => VisitDetailsFormValues = (initialData, patientUU) => {
	if (!initialData) {
		return {
			BH_Clinician_User: { UU: null },
			BH_NewVisit: true,
			BH_PatientType: { UU: null },
			BH_Process_Stage: { UU: null },
			bh_referral: { UU: null },
			BH_ReferredFromTo: null,
			BH_VisitDate: new Date(),
			Patient: { UU: patientUU ? patientUU : null },
		};
	}
	return {
		BH_Clinician_User: { UU: initialData.BH_Clinician_User?.UU || null },
		BH_NewVisit: initialData.BH_NewVisit,
		BH_PatientType: { UU: initialData.BH_PatientType?.UU || null },
		BH_Process_Stage: { UU: initialData.BH_Process_Stage?.UU || null },
		bh_referral: { UU: initialData.bh_referral?.UU || null },
		BH_ReferredFromTo: initialData.BH_ReferredFromTo || null,
		BH_VisitDate: initialData.BH_VisitDate ? new Date(initialData.BH_VisitDate) : new Date(),
		Patient: { UU: initialData.Patient.UU },
	};
};

export const constructVisitDetailsFormDataToSave = (data: VisitFormValues): Bh_VisitInput => {
	return {
		UU: data.UU,
		BH_Clinician_User: data.BH_Clinician_User.UU ? { UU: data.BH_Clinician_User.UU } : null,
		BH_NewVisit: data.BH_NewVisit,
		BH_PatientType: data.BH_PatientType.UU ? { UU: data.BH_PatientType.UU } : null,
		BH_Process_Stage: data.BH_Process_Stage.UU ? { UU: data.BH_Process_Stage.UU } : null,
		bh_referral: data.bh_referral.UU ? { UU: data.bh_referral.UU } : null,
		BH_ReferredFromTo: data.BH_ReferredFromTo || null,
		BH_VisitDate: data.BH_VisitDate.getTime(),
		Patient: data.Patient.UU ? { UU: data.Patient.UU } : undefined,
	};
};

const cliniciansVariables: Ad_UserForVisitCliniciansQueryVariables = {
	Filter: DBFilter<UserDB>()
		.nested('ad_user_roles.ad_role')
		.nested('ad_role_included.ad_role::included_role_id')
		.property('ad_role_uu')
		.isIn([roleUuid.CLINICIAN_NURSE_ADVANCED, roleUuid.CLINICIAN_NURSE_BASIC])
		.up()
		.up()
		.nested('ad_client')
		.property('ad_client_uu')
		.doesNotEqual(ClientUU.System)
		.up()
		.toString(),
	Sort: JSON.stringify([['name', 'asc']]),
} as const;
const referralsVariables: Ad_Ref_ListReferralsForVisitsQueryVariables = {
	Filter: DBFilter<ReferenceListDB>()
		.nested('ad_reference')
		.property('ad_reference_uu')
		.equals(referenceUuids.REFERRALS)
		.up()
		.toString(),
} as const;
const patientTypeVariables: Ad_Ref_ListPatientTypesForVisitsQueryVariables = {
	Filter: DBFilter<ReferenceListDB>()
		.nested('ad_reference')
		.property('ad_reference_uu')
		.equals(referenceUuids.PATIENT_TYPES)
		.up()
		.toString(),
	Sort: JSON.stringify([['created', 'asc']]),
} as const;
const processStageVariables: Ad_Ref_ListProcessStagesForVisitsQueryVariables = {
	Filter: DBFilter<ReferenceListDB>()
		.nested('ad_reference')
		.property('ad_reference_uu')
		.equals(referenceUuids.PROCESS_STAGE)
		.up()
		.toString(),
} as const;

export const primeVisitDetailData = (graphqlClient: ApolloClient<object>) =>
	Promise.all([
		graphqlClient.query({
			query: Ad_UserForVisitCliniciansDocument,
			variables: cliniciansVariables,
			fetchPolicy: 'network-only',
		}),
		graphqlClient.query({
			query: Ad_Ref_ListReferralsForVisitsDocument,
			variables: referralsVariables,
			fetchPolicy: 'cache-first',
		}),
		graphqlClient.query({
			query: Ad_Ref_ListPatientTypesForVisitsDocument,
			variables: patientTypeVariables,
			fetchPolicy: 'cache-first',
		}),
		graphqlClient.query({
			query: Ad_Ref_ListProcessStagesForVisitsDocument,
			variables: processStageVariables,
			fetchPolicy: 'cache-first',
		}),
	]);

type VisitDetailsEditProps = {
	isDataReadOnly: boolean;
};

const VisitDetailsEdit = ({ isDataReadOnly }: VisitDetailsEditProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const {
		register,
		formState: { errors },
		setValue,
	} = useFormContext<VisitFormValues>();
	const { disableWrite } = useActionPrivileges(pageUuid.VISITS);
	const { canWrite: canCreatePatients } = useActionPrivileges(pageUuid.PATIENTS);

	const patientUU = useWatch<VisitFormValues, 'Patient.UU'>({ name: 'Patient.UU' });
	const [fetchPatient, { data: patient }] = useLazyQuery(C_BPartnerForVisitsDocument, { fetchPolicy: 'cache-first' });
	useEffect(() => {
		if (patientUU) {
			fetchPatient({ variables: { UU: patientUU } });
		}
	}, [patientUU, fetchPatient]);
	const firstVisit =
		!patientUU ||
		patient?.C_BPartner?.UU !== patientUU ||
		(!patient.C_BPartner?.LastVisitDate && patient.C_BPartner?.TotalVisits === 0);
	// Make sure that we clear the new visit flag if this can't be their first visit
	useEffect(() => {
		if (!firstVisit) {
			setValue('BH_NewVisit', false);
		}
	}, [firstVisit, setValue]);
	const clinicians = useMemo(
		() =>
			graphqlClient.readQuery({
				query: Ad_UserForVisitCliniciansDocument,
				variables: cliniciansVariables,
			})?.AD_UserGet.Results,
		[graphqlClient],
	);
	const referrals = useMemo(
		() =>
			graphqlClient.readQuery({
				query: Ad_Ref_ListReferralsForVisitsDocument,
				variables: referralsVariables,
			})?.AD_Ref_ListGet.Results,
		[graphqlClient],
	);
	const patientTypes = useMemo(
		() =>
			graphqlClient.readQuery({
				query: Ad_Ref_ListPatientTypesForVisitsDocument,
				variables: patientTypeVariables,
			})?.AD_Ref_ListGet.Results,
		[graphqlClient],
	);
	const [showPatientForm, setShowPatientForm] = useState(false);
	const processStageList = useMemo(
		() =>
			graphqlClient.readQuery({
				query: Ad_Ref_ListProcessStagesForVisitsDocument,
				variables: processStageVariables,
			})?.AD_Ref_ListGet.Results,
		[graphqlClient],
	);
	const canViewPatient = useActionPrivileges(pageUuid.PATIENTS).canView;

	const [onSearchPatient, { data: patientOptions, loading: areLoadingPatients }] = useLazyQuery(
		C_BPartnerSearchForVisitsDocument,
		{ fetchPolicy: 'network-only' },
	);

	return (
		<Card className="bh-card mb-0">
			<Card.Body>
				<Row className="gy-3">
					<Form.Group as={Fragment} controlId="txPatientSearch">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.form.patient.LABEL)}</Form.Label>
						</Col>
						<Col xs={7} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<EntityLookup<VisitDetailsFormValues, 'Patient'>
									name="Patient"
									rules={{ required: true }}
									isLoading={areLoadingPatients}
									id="txPatientSearch"
									emptyLabel={t(uiText.visit.form.patient.NOT_FOUND)}
									labelKey={(data) =>
										graphqlClient.readFragment({ id: data.UU, fragment: C_BPartnerDisplayForVisitsFragmentDoc })
											?.Name || ''
									}
									placeholder={t(uiText.visit.form.patient.SEARCH)}
									createNew={
										canCreatePatients
											? {
													onCreateNew: () => setShowPatientForm(true),
													newEntityName: t(uiText.entityLookup.PATIENT),
												}
											: undefined
									}
									promptText={t(uiText.visit.form.patient.SEARCHING)}
									searchText={t(uiText.visit.form.patient.SEARCHING)}
									options={areLoadingPatients ? [] : patientOptions?.C_BPartnerGet.Results || []}
									onSearch={(query) =>
										onSearchPatient({ variables: { Filter: getPatientSearchFilter(query).toString() } })
									}
									disabled={isDataReadOnly}
								/>
								{errors?.Patient && <span className="text-danger">{t(uiText.visit.form.patient.NAME_REQUIRED)}</span>}
							</fieldset>
						</Col>
						{showPatientForm && (
							<PatientForm
								renderAsModal={true}
								canSaveMany={false}
								onFinish={(refreshData?: boolean, savedEntity?: string) => {
									if (refreshData && savedEntity) {
										setValue('Patient.UU', savedEntity);
									}
									setShowPatientForm(false);
								}}
							/>
						)}
					</Form.Group>
					<Col xs={2} className="d-flex align-items-center">
						{canViewPatient && <ViewPatient />}
					</Col>
					<Col xs={2} className="d-flex align-items-center">
						{firstVisit && (
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<Form.Group as={Fragment} controlId="checkFirstVisit">
									<Form.Check {...register('BH_NewVisit')} label={t(uiText.visit.form.FIRST_VISIT)} />
								</Form.Group>
							</fieldset>
						)}
					</Col>
					<Form.Group as={Fragment} controlId="txProcessStage">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.form.patient.SEND_PATIENT_TO)}</Form.Label>
						</Col>
						<Col xs={3} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<Form.Select {...register('BH_Process_Stage.UU')}>
									<option value="">&nbsp;</option>
									{sortProcessStageList(processStageList || [])?.map((processStage) => (
										<option key={processStage.UU} value={processStage.UU}>
											{processStage.Name}
										</option>
									))}
								</Form.Select>
							</fieldset>
						</Col>
					</Form.Group>
					<Form.Group as={Fragment} controlId="txClinician">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.CLINICIAN)}</Form.Label>
						</Col>
						<Col xs={3} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<Form.Select {...register('BH_Clinician_User.UU')}>
									<option>&nbsp;</option>
									{clinicians?.map((clinician) => (
										<option key={clinician.UU} value={clinician.UU}>
											{clinician.Name}
										</option>
									))}
								</Form.Select>
							</fieldset>
						</Col>
					</Form.Group>

					<Form.Group as={Fragment} controlId="txPatientType">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.form.patient.TYPE)}</Form.Label>
						</Col>
						<Col xs={3} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<Form.Select {...register('BH_PatientType.UU')}>
									<option>&nbsp;</option>
									{patientTypes?.map((patientType) => (
										<option key={patientType.UU} value={patientType.UU}>
											{patientType.Name}
										</option>
									))}
								</Form.Select>
							</fieldset>
						</Col>
					</Form.Group>

					<Form.Group as={Fragment} controlId="visitDate">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.form.DATE)}</Form.Label>
						</Col>
						<Col xs={3} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<Controller<VisitDetailsFormValues, 'BH_VisitDate'>
									name="BH_VisitDate"
									render={({ field }) => (
										<BandaDatePicker
											dateFormat="yyyy-MM-dd h:mm aa"
											{...field}
											value={undefined}
											selected={field.value}
											timeInputLabel={`${t(uiText.visit.form.TIME)}:`}
											showTimeInput
											maxDate={new Date()}
										/>
									)}
								/>
							</fieldset>
						</Col>
					</Form.Group>

					<Form.Group as={Fragment} controlId="txReferral">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.form.patient.REFERRAL)}</Form.Label>
						</Col>
						<Col xs={3} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<Form.Select {...register('bh_referral.UU')}>
									<option>&nbsp;</option>
									{referrals?.map((referral) => (
										<option key={referral.UU} value={referral.UU}>
											{referral.Name}
										</option>
									))}
								</Form.Select>
							</fieldset>
						</Col>
					</Form.Group>

					<Form.Group as={Fragment} controlId="referredFromTo">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.form.patient.REFERRED_FROM_TO)}</Form.Label>
						</Col>
						<Col xs={3} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<Form.Control {...register('BH_ReferredFromTo')} />
							</fieldset>
						</Col>
					</Form.Group>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default VisitDetailsEdit;
