import { useApolloClient } from '@apollo/client';
import { sortBy } from 'lodash';
import { Fragment, Suspense } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import {
	Bh_Bp_General_Payer_InfoSaveManyDocument,
	Bh_Bp_Payer_InfoGetForVisitPatientQuery,
	Bh_Bp_Payer_InfoSaveManyDocument,
	C_BPartnerForVisitsInsurersAndDonorsQuery,
	C_BPartnerForVisitsPatientInformationEditingDocument,
} from '../../graphql/__generated__/graphql';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import { businessPartnerGroupSubType } from '../../models';
import { uiText } from '../../utils/Language';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import PayerInformationInput from '../Patient/PayerInformationInput';

type EditPatientInformationFormValues = {
	data: Array<{ UU: string; BH_Payer_Info_Fld: { UU: string }; Name: string | null }>;
	BH_BP_Payer_Info: { UU: string };
};

type EditPatientInformationProps = {
	patientUU: string;
	onCancel: () => void;
	onSave: () => void;
	businessPartnerPayerInformation?: Bh_Bp_Payer_InfoGetForVisitPatientQuery['BH_BP_Payer_InfoGet']['Results'][0];
	selectedPayer: C_BPartnerForVisitsInsurersAndDonorsQuery['C_BPartnerGet']['Results'][0];
};

const EditPatientInformationInternal = ({
	patientUU,
	onCancel,
	onSave,
	businessPartnerPayerInformation,
	selectedPayer,
}: EditPatientInformationProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { data: patient } = useSuspenseAsync('loading-patient-for-information-editing' + patientUU, async () =>
		graphqlClient
			.query({ query: C_BPartnerForVisitsPatientInformationEditingDocument, variables: { UU: patientUU } })
			.then((response) => response.data.C_BPartner),
	);
	const payerInformationFieldList = sortBy(selectedPayer.BH_Payer_Info_FldList || [], 'Line').filter(
		(payerInformationField) => payerInformationField.IsActive && payerInformationField.BH_FillFromPatient,
	);
	const formMethods = useForm<EditPatientInformationFormValues>({
		defaultValues: {
			data: payerInformationFieldList.map((payerInformationField) => {
				const generalPayerInformation = businessPartnerPayerInformation?.BH_BP_General_Payer_InfoList?.find(
					(businessPartnerGeneralPayerInformation) =>
						businessPartnerGeneralPayerInformation.BH_Payer_Info_Fld.UU === payerInformationField.UU,
				);
				return {
					UU: generalPayerInformation?.UU || v4(),
					BH_Payer_Info_Fld: { UU: payerInformationField.UU },
					Name: generalPayerInformation?.Name || null,
				};
			}),
			BH_BP_Payer_Info: { UU: businessPartnerPayerInformation?.UU || v4() },
		},
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = formMethods;

	const onSubmit = async ({ data, BH_BP_Payer_Info }: EditPatientInformationFormValues) => {
		await Promise.all([
			!businessPartnerPayerInformation
				? graphqlClient.mutate({
						mutation: Bh_Bp_Payer_InfoSaveManyDocument,
						variables: {
							BH_BP_Payer_InfoList: [
								{
									UU: BH_BP_Payer_Info.UU,
									BH_Payer: { UU: selectedPayer.UU },
									C_BPartner: { UU: patientUU },
									IsActive: true,
								},
							],
						},
					})
				: undefined,
			graphqlClient.mutate({
				mutation: Bh_Bp_General_Payer_InfoSaveManyDocument,
				variables: {
					BH_BP_General_Payer_InfoList:
						// RHF version 7.12.2 doesn't always reset arrays, so make sure the optional operator is used
						// (7.13.0+ does, but they cause other errors that haven't been fixed up to 7.53.0)
						data?.map((generalPayerInformation) => ({
							UU: generalPayerInformation.UU,
							BH_BP_Payer_Info: { UU: BH_BP_Payer_Info.UU },
							BH_Payer_Info_Fld: { UU: generalPayerInformation.BH_Payer_Info_Fld.UU },
							Name: generalPayerInformation.Name,
						})) || [],
				},
			}),
		]);
		onSave();
	};

	return (
		<FormProvider {...formMethods}>
			<Form>
				<Modal.Header>
					<Modal.Title>{t(uiText.visit.form.additionalInformation.EDIT_PATIENT_INFORMATION)}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<span>{t(uiText.visit.info.NAME, { name: patient?.Name || '-' })}</span>
					<span className="ms-4">
						{t(uiText.visit.info.PATIENT_NUMBER_WITH_DATA, { patientNumber: patient?.BH_PatientID || '-' })}
					</span>
					<Form.Group as={Row} controlId="chargeName" className="mt-3">
						<Col className="d-flex align-items-center">
							<Form.Label column>
								{t(
									selectedPayer.C_BP_Group.BH_SubType?.Value === businessPartnerGroupSubType.Donation
										? uiText.visit.form.additionalInformation.DONOR
										: uiText.visit.form.additionalInformation.INSURER,
								)}
							</Form.Label>
						</Col>
						<Col xs={8} className="d-flex align-items-center">
							<Form.Control disabled={true} defaultValue={selectedPayer.Name} />
							<input type="hidden" {...register('BH_BP_Payer_Info.UU')} />
						</Col>
					</Form.Group>
					{payerInformationFieldList.map((payerInformationField, payerInformationFieldIndex) => {
						let businessPartnerGeneralPayerInformationIndex =
							businessPartnerPayerInformation?.BH_BP_General_Payer_InfoList?.findIndex(
								(businessPartnerChargeInformation) =>
									businessPartnerChargeInformation.BH_Payer_Info_Fld.UU === payerInformationField.UU,
							);
						if (businessPartnerGeneralPayerInformationIndex === -1) {
							businessPartnerGeneralPayerInformationIndex = payerInformationFieldIndex;
						}
						const subFieldName = `data.${payerInformationFieldIndex}`;
						return (
							<Fragment key={payerInformationField.UU}>
								<input
									type="hidden"
									{...register(`${subFieldName}.BH_Payer_Info_Fld.UU` as 'data.0.BH_Payer_Info_Fld.UU')}
								/>
								<Form.Group as={Row} controlId={payerInformationField.UU} className="mt-3">
									<Col className="d-flex align-items-center">
										<Form.Label column>{payerInformationField.Name}</Form.Label>
									</Col>
									<Col xs={8} className="d-flex align-items-center">
										<input type="hidden" {...register(`${subFieldName}.UU` as 'data.0.UU')} />
										<PayerInformationInput
											payerInformationField={payerInformationField}
											inputPlaceholder={`${t(uiText.patient.additionalInformation.ENTER_PREFIX)} ${
												payerInformationField.Name || ''
											}`}
											registerReturn={register(`${subFieldName}.Name` as 'data.0.Name', { required: true })}
											selectPlaceholder={`${t(uiText.patient.additionalInformation.SELECT_PREFIX)} ${
												payerInformationField.Name || ''
											}`}
										/>
									</Col>
								</Form.Group>
							</Fragment>
						);
					})}
					{!!(errors.data || []).length && (
						<div className="text-danger">{t(uiText.patient.validationMessages.REQUIRE_INFO_TO_BE_ENTERED)}</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						type="button"
						variant="danger"
						onClick={() => {
							onCancel();
						}}
					>
						{t(uiText.visit.button.CANCEL)}
					</Button>
					<Button
						type="submit"
						variant="success"
						className="ms-auto"
						onClick={(e) => {
							e.preventDefault();
							handleSubmit(onSubmit)();
						}}
					>
						{t(uiText.visit.button.SAVE)}
					</Button>
				</Modal.Footer>
			</Form>
		</FormProvider>
	);
};

export default function EditPatientInformation(props: EditPatientInformationProps) {
	const { t } = useTranslation();
	return (
		<Modal show>
			<Suspense
				fallback={
					<>
						<Modal.Header>
							<Modal.Title>{t(uiText.visit.form.additionalInformation.EDIT_PATIENT_INFORMATION)}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<LoadSpinner inline title={t(uiText.visit.form.payment.LOADING)} />
						</Modal.Body>
					</>
				}
			>
				<EditPatientInformationInternal {...props} />
			</Suspense>
		</Modal>
	);
}
