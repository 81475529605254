import { ApolloError, isApolloError, useApolloClient } from '@apollo/client';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import logo from '../../assets/images/b-logo.png';
import UserContext from '../../contexts/UserContext';
import {
	Ad_ClientGetDocument,
	Ad_ClientGetQuery,
	Ad_LanguageGetDocument,
	Ad_RoleWithWindowAccessDocument,
	ChangeAccessDocument,
	ChangePasswordDocument,
	SignInDocument,
	SignInMutation,
} from '../../graphql/__generated__/graphql';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import useService from '../../hooks/useService';
import { convertLocaleToBackend, convertLocaleToUI } from '../../models/Language';
import { exception } from '../../utils/analytics';
import { transformAuthenticationDataToUserContextData } from '../../utils/AuthenticationUtil';
import {
	BANDA_HEALTH_CONTACT_PAGE,
	BANDA_HEALTH_HOME_PAGE,
	SAVE_PASSWORD,
	SAVE_REMEMBER_ME,
	SAVE_USERNAME,
	TOS_PAGE,
	VISITS_PAGE,
} from '../../utils/Constants';
import { getGraphQLErrorMessages } from '../../utils/ErrorUtil';
import { uiText } from '../../utils/Language';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import PasswordInput from '../password-input/PasswordInput';
import ChangePassword from './ChangePassword';
import './LoginPanel.scss';

export default function LoginPanel() {
	const { t, i18n } = useTranslation();
	const { authService } = useService();

	const [loginUserName, setLoginUserName] = useState(localStorage.getItem(SAVE_USERNAME) || '');
	const [loginPassword, setLoginPassword] = useState(localStorage.getItem(SAVE_PASSWORD) || '');
	const [rememberMe, setRememberMe] = useState(localStorage.getItem(SAVE_REMEMBER_ME) || false);
	const [onLoginErrors, setOnLoginErrors] = useState(false);
	const [clients, setClients] = useState<Ad_ClientGetQuery['AD_ClientGet']['Results']>([]);
	const [roles, setRoles] = useState<
		NonNullable<Ad_ClientGetQuery['AD_ClientGet']['Results'][0]['AD_Orgs'][0]['AD_Roles']>
	>([]);
	const [organizations, setOrganizations] = useState<Ad_ClientGetQuery['AD_ClientGet']['Results'][0]['AD_Orgs']>([]);
	const [warehouses, setWarehouses] = useState<
		NonNullable<Ad_ClientGetQuery['AD_ClientGet']['Results'][0]['AD_Orgs'][0]['M_Warehouses']>
	>([]);
	const [clientUuid, setClientUuid] = useState<string | undefined>();
	const [organizationUuid, setOrganizationUuid] = useState<string | undefined>();
	const [warehouseUuid, setWarehouseUuid] = useState<string | undefined>();
	const [roleUuid, setRoleUuid] = useState<string | undefined>();
	const [errorMessage, setErrorMessage] = useState('');
	const [needsToResetPassword, setNeedsToResetPassword] = useState(false);
	const [changePasswordError, setChangePasswordError] = useState('');
	const hasUserAcceptedTermsOfUse = useRef(false);
	const loggedInUser = useRef<SignInMutation['SignIn']['AD_User']>();

	const { update } = useContext(UserContext);
	const history = useHistory();

	const graphqlClient = useApolloClient();

	const { value: languages } = useAsync(
		async () =>
			(await graphqlClient.query({ query: Ad_LanguageGetDocument, fetchPolicy: 'cache-first' })).data.AD_LanguageGet
				.Results,
		[graphqlClient],
	);
	useEffect(() => {
		// Confirm the desired language is in the list
		let languageLocaleToPotentiallyUpdate = i18n.language;
		if (languages?.length) {
			let languageModel = languages?.find(
				(availableLanguage) =>
					availableLanguage.AD_Language === languageLocaleToPotentiallyUpdate ||
					availableLanguage.LanguageISO === languageLocaleToPotentiallyUpdate,
			);
			if (!languageModel) {
				languageModel = languages.find((availableLanguage) => availableLanguage.IsBaseLanguage);
			}
			if (languageModel) {
				languageLocaleToPotentiallyUpdate = convertLocaleToUI(languageModel.AD_Language)!;
			}
		}
		if (languageLocaleToPotentiallyUpdate !== i18n.language) {
			i18n.changeLanguage(languageLocaleToPotentiallyUpdate);
		}
	}, [languages, i18n]);

	const getClients = useCallback(async () => {
		// With a successful sign-in (regardless of password), get the clients the user has access to
		const clients = (
			await graphqlClient.query({ query: Ad_ClientGetDocument, variables: { Sort: JSON.stringify(['name']) } })
		).data.AD_ClientGet.Results;

		// user with no client assigned
		if (!clients.length) {
			setOnLoginErrors(true);
			setErrorMessage(t(uiText.login.error.NO_CLIENTS));
		} else if (
			clients.length === 1 &&
			clients[0].AD_Orgs?.length === 1 &&
			clients[0].AD_Orgs[0].AD_Roles?.length === 1 &&
			clients[0].AD_Orgs[0].M_Warehouses?.length === 1
		) {
			// user with one of everything assigned.. no need to select client, role, warehouse, organization.
			setOtherEntitiesBasedOnSelectedClient(clients[0]);
			changeAccess(clients[0], {
				selectedOrganizationUU: clients[0].AD_Orgs[0].UU,
				selectedRoleUU: clients[0].AD_Orgs[0].AD_Roles![0].UU,
				selectedWarehouseUU: clients[0].AD_Orgs[0].M_Warehouses![0].UU,
			});
		} else {
			// show clients dropdown to choose from
			setClients(clients);
		}
	}, []);

	const [{ loading: isLoggingIn }, login] = useCustomAsyncFn(async () => {
		// clear all items for this domain
		localStorage.clear();
		await graphqlClient.cache.reset();

		// check remember me checkbox
		if (rememberMe) {
			localStorage.setItem(SAVE_USERNAME, loginUserName);
			localStorage.setItem(SAVE_PASSWORD, loginPassword);
			localStorage.setItem(SAVE_REMEMBER_ME, rememberMe.toString());
		}

		setOnLoginErrors(false);

		try {
			// Send GraphQL request
			loggedInUser.current = (
				await graphqlClient.mutate({
					mutation: SignInDocument,
					variables: {
						Credentials: {
							AD_Language: convertLocaleToBackend(i18n.language),
							Username: loginUserName,
							Password: loginPassword,
						},
					},
				})
			).data?.SignIn.AD_User;

			hasUserAcceptedTermsOfUse.current = loggedInUser.current?.BH_HasAcceptedTermsOfUse !== false;

			if (loggedInUser.current?.IsExpired) {
				setNeedsToResetPassword(true);
				return;
			}
			setNeedsToResetPassword(false);
			await getClients();
		} catch (error: any) {
			setOnLoginErrors(true);
			if (error.response?.status === 401) {
				setErrorMessage(t(uiText.login.error.WRONG_USERNAME_PASSWORD));
			} else if (error instanceof ApolloError) {
				if (
					(error.networkError && 'statusCode' in error.networkError && error.networkError.statusCode === 401) ||
					error.graphQLErrors[0].message.includes('Unauthorized')
				) {
					setErrorMessage(t(uiText.login.error.WRONG_USERNAME_PASSWORD));
				} else {
					setErrorMessage(getGraphQLErrorMessages(error)[0]);
				}
			} else {
				setErrorMessage(error.response?.statusText);
			}
		}
	}, [graphqlClient, loginUserName, loginPassword, rememberMe, i18n, getClients]);

	const [{ loading: areChangingAccess }, changeAccess] = useCustomAsyncFn(
		async (
			client: Ad_ClientGetQuery['AD_ClientGet']['Results'][0],
			{
				selectedOrganizationUU,
				selectedRoleUU,
				selectedWarehouseUU,
			}: { selectedOrganizationUU: string; selectedRoleUU: string; selectedWarehouseUU: string },
		) => {
			try {
				// Run the REST login now and wait on it's completion later
				const restLogin = authService.login(
					loginUserName,
					loginPassword,
					client.UU,
					selectedRoleUU,
					selectedOrganizationUU,
					selectedWarehouseUU,
					convertLocaleToBackend(i18n.language),
				);
				// Send the change access request first so that everything will be ready on the back-end
				await graphqlClient.mutate({
					mutation: ChangeAccessDocument,
					variables: {
						Access: {
							AD_Client_UU: client.UU,
							AD_Org_UU: selectedOrganizationUU,
							AD_Role_UU: selectedRoleUU,
							M_Warehouse_UU: selectedWarehouseUU,
						},
					},
				});
				const role = (
					await graphqlClient.query({
						query: Ad_RoleWithWindowAccessDocument,
						variables: { UU: selectedRoleUU },
						fetchPolicy: 'cache-first',
					})
				).data.AD_Role;

				update(
					transformAuthenticationDataToUserContextData(
						loggedInUser.current!,
						clients.length ? clients : [client],
						{ clientUU: client.UU, organizationUU: selectedOrganizationUU, warehouseUU: selectedWarehouseUU },
						role,
					),
				);
				// Ensure the REST login completes
				await restLogin;
			} catch {
				exception({ description: `couldn't generate GraphQL cookie` });
			}
			history.push(hasUserAcceptedTermsOfUse.current ? VISITS_PAGE : TOS_PAGE);
		},
		[graphqlClient, loginUserName, loginPassword, i18n, clients],
	);

	const selectClient = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const clientUuid = event.target.value;
		const client = clients.find((client) => client.UU === clientUuid);
		setOtherEntitiesBasedOnSelectedClient(client);
		setClientUuid(clientUuid);
	};

	const setOtherEntitiesBasedOnSelectedClient = (client?: Ad_ClientGetQuery['AD_ClientGet']['Results'][0]) => {
		if (!client) {
			setOrganizations([]);
			setRoles([]);
			setWarehouses([]);
			setOrganizationUuid(undefined);
			setRoleUuid(undefined);
			setWarehouseUuid(undefined);
			return;
		}

		const organizations = sortBy(client.AD_Orgs, 'Name');
		const organization = organizations[0];
		const organizationUuid = organization.UU;

		const roles = sortBy(
			(JSON.parse(JSON.stringify(organization.AD_Roles || [])) as NonNullable<typeof organization.AD_Roles>).map(
				(role) => {
					role.Name = role.Name.replace(client.Name, '').trim();
					return role;
				},
			),
			'Name',
		);
		const roleUuid =
			roles.find((role) => role.Name === 'Clinic Admin')?.UU ||
			roles.find((role) => role.Name === 'Admin')?.UU ||
			roles[0]?.UU;
		const warehouses = sortBy(organization.M_Warehouses || [], 'Name');
		const warehouseUuid = warehouses.find((warehouse) => warehouse.BH_DefaultWarehouse)?.UU || warehouses[0]?.UU;

		setOrganizations(organizations);
		setRoles(roles);
		setWarehouses(warehouses);
		setOrganizationUuid(organizationUuid);
		setRoleUuid(roleUuid);
		setWarehouseUuid(warehouseUuid);
	};

	const [{ loading: areSubmittingPasswordChange }, changePassword] = useCustomAsyncFn(
		async (newPassword: string) => {
			try {
				await graphqlClient.mutate({
					mutation: ChangePasswordDocument,
					variables: { PasswordInfo: { Username: loginUserName, Password: loginPassword, NewPassword: newPassword } },
				});
				setChangePasswordError('');
				setNeedsToResetPassword(false);
				await getClients();
			} catch (error) {
				if (error instanceof Error && isApolloError(error)) {
					setChangePasswordError(getGraphQLErrorMessages(error)[0]);
				} else {
					setChangePasswordError(t(uiText.login.error.ERROR_OCCURRED));
				}
			}
		},
		[graphqlClient, loginUserName, loginPassword],
	);

	return (
		<Row className="login">
			<Col xs={4} className="login login__sidebar d-flex flex-column justify-content-between">
				<Row>
					<Col className="login login__logo pt-4">
						<a
							href={BANDA_HEALTH_HOME_PAGE}
							target={'_blank'}
							aria-label={BANDA_HEALTH_HOME_PAGE}
							rel="noopener noreferrer"
						>
							<img src={logo} width="100px" alt="BandaHealth Logo" />
						</a>
					</Col>
				</Row>
				<Row>
					<Col>
						<h1>{t(uiText.login.WELCOME)}</h1>
						<p className="fw-light">{t(uiText.login.MOTTO)}</p>
					</Col>
				</Row>
				<Row className="login login__footer pb-2">
					<Col xs={8}>
						<small>&copy;BandaHealth {new Date().getFullYear()}</small>
					</Col>
					<Col xs={2}>
						<small>
							<a href={BANDA_HEALTH_HOME_PAGE} target={'_blank'} rel="noopener noreferrer">
								{t(uiText.login.ABOUT)}
							</a>
						</small>
					</Col>
					<Col xs={2}>
						<small>
							<a href={BANDA_HEALTH_CONTACT_PAGE} target={'_blank'} rel="noopener noreferrer">
								{t(uiText.login.CONTACT)}
							</a>
						</small>
					</Col>
				</Row>
			</Col>

			<Col xs={8}>
				<Form
					autoComplete="off"
					className="login login__form"
					onSubmit={(e) => {
						e.preventDefault();
						if (clients.length === 0) {
							if (!loginUserName || !loginPassword) {
								setOnLoginErrors(true);
								setErrorMessage(
									!loginUserName ? t(uiText.login.error.ENTER_USERNAME) : t(uiText.login.error.ENTER_PASSWORD),
								);
								return;
							}
							login();
						} else {
							const client = clients.find((client) => client.UU === clientUuid);
							if (!clientUuid || !organizationUuid || !roleUuid || !warehouseUuid || !client) {
								return;
							}
							changeAccess(client, {
								selectedOrganizationUU: organizationUuid,
								selectedRoleUU: roleUuid,
								selectedWarehouseUU: warehouseUuid,
							});
						}
					}}
				>
					<Col xs={5}>
						{(isLoggingIn || areSubmittingPasswordChange || areChangingAccess) && (
							<Row>
								<Col className="mb-5">
									<LoadSpinner title={t(uiText.login.LOADING)} inline />
								</Col>
							</Row>
						)}
						{needsToResetPassword ? (
							<ChangePassword
								onSubmit={changePassword}
								serverErrorMessage={changePasswordError}
								areLoading={areSubmittingPasswordChange}
							/>
						) : (
							<>
								{clients.length === 0 ? (
									<>
										<Row>
											<Col className="login login__form__header">
												<h4>{t(uiText.login.SIGN_IN)}</h4>
												<h6>{t(uiText.login.ENTER_USERNAME_PASSWORD)}</h6>
											</Col>
										</Row>
										<Row>
											{onLoginErrors === true && (
												<Col xs={8} className="p-1 m-auto bg-danger text-center rounded text-white">
													<span id="error">{errorMessage}</span>
												</Col>
											)}
										</Row>
										<Form.Group as={Row} controlId="loginUserName" className="my-2">
											<Form.Control
												onChange={(e) => setLoginUserName(e.target.value)}
												placeholder={t(uiText.login.USERNAME_OR_EMAIL)}
												value={loginUserName}
											/>
										</Form.Group>
										<Form.Group as={Row} controlId="loginPassword" className="mb-2">
											<PasswordInput onChange={(e) => setLoginPassword(e.target.value)} value={loginPassword} />
										</Form.Group>
										{(languages?.length || 0) > 1 && (
											<Form.Group as={Row} controlId="loginLanguage" className="mb-2">
												<Form.Select
													onChange={(e) => i18n.changeLanguage(convertLocaleToUI(e.target.value))}
													value={i18n.language}
												>
													{languages?.map((language) => (
														<option key={language.UU} value={language.AD_Language}>
															{language.PrintName}
														</option>
													))}
												</Form.Select>
											</Form.Group>
										)}
									</>
								) : (
									<>
										<Row className="mb-2">
											<div className="login login__form__header">
												<h5>{t(uiText.login.SELECT_CLIENT)}</h5>
											</div>
										</Row>
										<Form.Group as={Row} className="mb-2" controlId="clientId">
											<Form.Select name="clientId" onChange={selectClient} aria-label={t(uiText.login.SELECT_CLIENT)}>
												<option></option>
												{clients.map((client) => (
													<option key={client.UU} value={client.UU}>
														{client.Name}
													</option>
												))}
											</Form.Select>
										</Form.Group>

										<Form.Group as={Row} className="mb-2" controlId="roleUuid">
											<Form.Select
												name="roleUuid"
												onChange={(event) => setRoleUuid(event.target.value)}
												value={roleUuid}
												aria-label={t(uiText.login.SELECT_ROLE)}
											>
												{roles.map((role) => (
													<option key={role.UU} value={role.UU}>
														{role.Name}
													</option>
												))}
											</Form.Select>
										</Form.Group>

										<Form.Group as={Row} className="mb-2" controlId="organizationUuid">
											<Form.Select
												name="organizationUuid"
												onChange={(e) => setOrganizationUuid(e.target.value)}
												value={organizationUuid}
												aria-label={t(uiText.login.SELECT_ORGANIZATION)}
											>
												{organizations.map((organization) => (
													<option key={organization.UU} value={organization.UU}>
														{organization.Name}
													</option>
												))}
											</Form.Select>
										</Form.Group>

										<Form.Group as={Row} className="mb-2" controlId="warehouseUuid">
											<Form.Select
												name="warehouseUuid"
												onChange={(event) => setWarehouseUuid(event.target.value)}
												value={warehouseUuid}
												aria-label={t(uiText.login.SELECT_STOREROOM)}
											>
												{warehouses.map((warehouse) => (
													<option key={warehouse.UU} value={warehouse.UU}>
														{warehouse.Name}
													</option>
												))}
											</Form.Select>
										</Form.Group>
									</>
								)}
								<Row className="mb-2 align-items-center" key="loginButton">
									<Col xs={7} className="ps-0">
										<Form.Group controlId="rememberMe">
											<Form.Check
												onChange={(e) => setRememberMe(e.target.checked)}
												defaultChecked={rememberMe === 'true' || rememberMe === true}
												value={rememberMe.toString()}
												label={t(uiText.login.REMEMBER_ME)}
											/>
										</Form.Group>
									</Col>
									<Col xs={5} className="text-end pe-0">
										<Button type="submit" variant="secondary" disabled={isLoggingIn || areChangingAccess}>
											<FontAwesomeIcon icon={faSignInAlt} className="me-1" />
											<span>{t(uiText.login.LOG_IN)}</span>
										</Button>
									</Col>
								</Row>
							</>
						)}
					</Col>
				</Form>
			</Col>
		</Row>
	);
}
