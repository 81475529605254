const uiLanguageCountrySeparator = '-';
const backendLanguageCountrySeparator = '_';

export const convertLocaleToUI = (locale?: string) => {
	return locale && locale.replace(backendLanguageCountrySeparator, uiLanguageCountrySeparator);
};

export const convertLocaleToBackend = (locale?: string): string => {
	return (locale && locale.replace(uiLanguageCountrySeparator, backendLanguageCountrySeparator)) || '';
};
