import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	C_OrderForReceiveProductsPageDocument,
	C_OrderForReceiveProductsPageQuery,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useListPageFunctionality from '../../hooks/useListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useStateWithReset from '../../hooks/useStateWithReset';
import DBFilter, { Filter } from '../../models/DBFilter';
import DocumentStatus from '../../models/DocumentStatus';
import ListPageState from '../../models/ListPageState';
import { OrderDB } from '../../models/Order';
import { exception } from '../../utils/analytics';
import { IS_ACTIVE } from '../../utils/CommonFilters';
import { pageUuid } from '../../utils/Constants';
import { formatDate } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import { formatNumber } from '../../utils/NumberUtil';
import BHGraphQLTable from '../BHTable/BHGraphQLTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import StatusBadge from '../StatusBadge/StatusBadge';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import ReceiveProductForm from './ReceiveProductForm';

const availableFilters = {
	onStatus: {
		[uiText.receiveProduct.filter.ALL]: IS_ACTIVE as unknown as Filter<OrderDB>,
		[uiText.receiveProduct.filter.COMPLETED]: DBFilter<OrderDB>()
			.property('docStatus')
			.equals(DocumentStatus.COMPLETED),
		[uiText.receiveProduct.filter.DRAFTED]: DBFilter<OrderDB>().property('docStatus').equals(DocumentStatus.DRAFTED),
		[uiText.receiveProduct.filter.VOIDED]: DBFilter<OrderDB>().property('docStatus').equals(DocumentStatus.VOIDED),
	},
} as const;

type OrderLocationState = { toView?: boolean };

/**
 * Main Receive Products page.
 */
const ReceiveProducts = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();

	const { disableWrite } = useActionPrivileges(pageUuid.RECEIVE_PRODUCTS);
	const [searchText, setSearchText] = useState('');
	const { state } = useLocation<OrderLocationState | undefined>();
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useListPageFunctionality<C_OrderForReceiveProductsPageQuery['C_OrderGet']['Results'][0]>(
		{
			fetch: useCallback(
				async (variables) =>
					(
						await graphqlClient.query({
							query: C_OrderForReceiveProductsPageDocument,
							variables,
							fetchPolicy: 'network-only',
						})
					).data.C_OrderGet,
				[graphqlClient],
			),
			onError: useCallback(
				(error) => {
					if (error.response) {
						toast.error(t(uiText.expense.error.COULD_NOT_LOAD));
					}
					exception({ description: `Receive Product fetch error: ${error}` });
				},
				[t],
			),
			refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
		},
		{
			sorted: JSON.stringify([['dateordered', 'desc']]),
			viewState: state?.toView ? ListPageState.ADD_EDIT : undefined,
			fetchDataInitially: false,
		},
	);

	// Filter state
	const [statusFilter, setStatusFilter, { reset: resetStatusFilter }] = useStateWithReset<
		keyof (typeof availableFilters)['onStatus']
	>(uiText.receiveProduct.filter.ALL);

	// Handle searching and filtering
	useEffect(() => {
		let filter = DBFilter<OrderDB>()
			.property('issotrx')
			.equals(false)
			.property('bh_visit_id')
			.isNull()
			.and(availableFilters.onStatus[statusFilter]);
		if (searchText) {
			filter.and(
				DBFilter<OrderDB>()
					.or(DBFilter<OrderDB>().nested('c_bpartner').property('name').contains(searchText).up())
					.or(
						DBFilter<OrderDB>()
							.nested('c_orderline')
							.nested('m_product')
							.property('name')
							.contains(searchText)
							.up()
							.up(),
					),
			);
		}
		onFilterUpdate(filter.toString());
	}, [searchText, onFilterUpdate, statusFilter]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		resetStatusFilter();
		setSearchText('');
		reset();
	});

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.receiveProduct.title.LIST)}
							showRefreshIcon={true}
							areRefreshing={areRefreshing}
							onRefresh={() => {
								refresh({ resetPage: true });
							}}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search initialText={searchText} onSearch={setSearchText} />
							<WorkspaceMenu.Filters>
								<Form.Group controlId="status">
									<Form.Label column>{t(uiText.receiveProduct.STATUS)}</Form.Label>
									<Form.Select
										className="ms-2 w-auto d-inline-block"
										value={statusFilter}
										onChange={(e) => setStatusFilter(e.target.value)}
									>
										{Object.entries(availableFilters.onStatus).map(([filter]) => (
											<option key={filter} value={filter}>
												{t(filter)}
											</option>
										))}
									</Form.Select>
								</Form.Group>
							</WorkspaceMenu.Filters>
							{!disableWrite && <WorkspaceMenu.NewButton onClick={() => setViewState(ListPageState.ADD_EDIT)} />}
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							<BHGraphQLTable<C_OrderForReceiveProductsPageQuery['C_OrderGet']['Results'][0]>
								data={data}
								columns={[
									{
										Header: () => <div className={'React-table-header'}>{t(uiText.receiveProduct.DATE_RECEIVED)}</div>,
										id: 'dateOrdered',
										accessor: (d) => formatDate(new Date(d.DateOrdered)),
									},
									{
										id: 'C_BPartner.name',
										Header: () => <div className={'React-table-header'}>{t(uiText.supplier.name.byOthers.LABEL)}</div>,
										accessor: (d) => d.C_BPartner.Name,
									},
									{
										id: 'grandTotal',
										Header: () => <div className={'React-table-header'}>{t(uiText.visit.BILL_TOTAL)}</div>,
										disableSortBy: true,
										accessor: (d) => <div className="d-flex justify-content-center">{formatNumber(d.GrandTotal)}</div>,
									},
									{
										id: 'docStatus',
										Header: () => <div className={'React-table-header'}>{t(uiText.visit.BILL_STATUS)}</div>,
										accessor: (d) => <StatusBadge documentStatus={d.DocStatus} />,
										disableSortBy: true,
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => {
									return <LoadSpinner show={isLoading} title={t(uiText.receiveProduct.loading.LOADING)} />;
								}}
								onFetchData={onTableUpdate}
								getTrGroupProps={rowProperties}
								sortBy={sorted}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : (
				<ReceiveProductForm
					uuid={selectedUuid}
					onFinish={() => {
						setViewState(ListPageState.LIST);
						refresh();
					}}
				/>
			)}
		</Layout>
	);
};

export default ReceiveProducts;
