import { useApolloClient } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { Button, Col, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import { LogoutDocument } from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import {
	BANDA_HEALTH_HOW_TO_VIDEOS_PAGE,
	BANDA_HEALTH_PRIVACY_POLICY_PAGE,
	BANDA_HEALTH_TERMS_OF_SERVICE_PAGE,
	LOGIN_PAGE,
	MANAGE_USERS_PAGE,
	pageUuid,
	SAVE_PASSWORD,
	SAVE_REMEMBER_ME,
	SAVE_USERNAME,
} from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import ChangePasswordModal from '../ChangePassword/ChangePasswordModal';
import ChangeAccessModal from '../Modal/ChangeAccessModal';

const zIndexSlightlyHigherThanBootstrapsStickyZIndex = 1021;

const LayoutMenu = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { clear, availableClients: clients, organization, client, user } = useContext(UserContext);

	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	const { disableView } = useActionPrivileges(pageUuid.USERS);
	const [showChangeAccessModal, setShowChangeAccessModal] = useState(false);

	const onLogout = async () => {
		// don't clear out login credentials if remember me is true
		const userName = localStorage.getItem(SAVE_USERNAME) || '';
		const password = localStorage.getItem(SAVE_PASSWORD) || '';
		const rememberMe = localStorage.getItem(SAVE_REMEMBER_ME) || false;

		clear();
		localStorage.clear(); //clear all items for this domain
		await graphqlClient.cache.reset();

		// TODO Move this to the back-end
		if (rememberMe) {
			localStorage.setItem(SAVE_USERNAME, userName);
			localStorage.setItem(SAVE_PASSWORD, password);
			localStorage.setItem(SAVE_REMEMBER_ME, rememberMe);
		}

		await graphqlClient.mutate({ mutation: LogoutDocument });
	};

	return (
		<>
			{showChangePasswordModal && (
				<ChangePasswordModal
					onHandleClose={() => {
						setShowChangePasswordModal(false);
					}}
					serverErrorMessage={''}
				/>
			)}
			{showChangeAccessModal && (
				<ChangeAccessModal
					onHandleClose={() => {
						setShowChangeAccessModal(false);
					}}
				/>
			)}
			<Col xs="auto" className="px-0">
				<Dropdown
					className="ps-0 d-inline-block h-100"
					align="end"
					style={{ zIndex: zIndexSlightlyHigherThanBootstrapsStickyZIndex }}
				>
					<Dropdown.Toggle variant="light" className="rounded-0 layout-top-bar layout-top-bar__user-dropdown h-100">
						{user.Name}
					</Dropdown.Toggle>
					<Dropdown.Menu className="rounded-0">
						{(clients.length > 1 ||
							client.AD_Orgs.length > 1 ||
							(organization.AD_Roles?.length || 0) > 1 ||
							(organization.M_Warehouses?.length || 0) > 1) && (
							<Dropdown.Item
								as={Button}
								className="rounded-0"
								onClick={() => {
									setShowChangeAccessModal(true);
								}}
							>
								{t(uiText.layout.CHANGE_STOREROOM)}
							</Dropdown.Item>
						)}
						<Dropdown.Item href={BANDA_HEALTH_HOW_TO_VIDEOS_PAGE}>{t(uiText.layout.HOW_TO_VIDEOS)}</Dropdown.Item>
						<Dropdown.Item href={BANDA_HEALTH_TERMS_OF_SERVICE_PAGE}>{t(uiText.layout.TERMS_OF_SERVICE)}</Dropdown.Item>
						<Dropdown.Item href={BANDA_HEALTH_PRIVACY_POLICY_PAGE}>{t(uiText.layout.PRIVACY_POLICY)}</Dropdown.Item>
						{!disableView && (
							<Dropdown.Item as={Link} to={MANAGE_USERS_PAGE}>
								{t(uiText.layout.MANAGE_USERS)}
							</Dropdown.Item>
						)}
						<Dropdown.Item
							as={Button}
							className="rounded-0"
							onClick={() => {
								setShowChangePasswordModal(true);
							}}
						>
							{t(uiText.layout.CHANGE_PASSWORD)}
						</Dropdown.Item>
						<Dropdown.Item as={Link} onClick={onLogout} to={LOGIN_PAGE}>
							{t(uiText.layout.SIGN_OUT)}
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Col>
		</>
	);
};

export default LayoutMenu;
