import { useApolloClient } from '@apollo/client';
import { useContext } from 'react';
import { useAsync } from 'react-use';
import UserContext from '../contexts/UserContext';
import { DocumentStatusActionMapDocument } from '../graphql/__generated__/graphql';
import {
	DocumentActionValue,
	DocumentBaseType,
	DocumentStatus,
	DocumentStatusActionMap,
	DocumentStatusValue,
} from '../models';

/**
 * Determine if, based on the current user's status and the current status of the entity
 * being worked with, that desired document action is available
 * @param processType The process type to help narrow the list of actions
 * @param currentDocumentStatus The current status of the document being considered
 * @param desiredDocumentAction The desired action to perform on the document
 * @returns Whether the information is being loaded and what it's value is
 */
export default function useIsDocumentActionAvailable(
	processType: DocumentBaseType,
	currentDocumentStatus: DocumentStatusValue | string = DocumentStatus.DRAFTED,
	desiredDocumentAction: DocumentActionValue,
) {
	const graphqlClient = useApolloClient();

	// Get the role's name so we can update this value if the user's role changes
	const { role: { Name: name } = { Name: '' } } = useContext(UserContext) || {};
	return useAsync(
		async () =>
			(
				(await graphqlClient.query({ query: DocumentStatusActionMapDocument, fetchPolicy: 'cache-first' })).data
					.DocumentStatusActionMap as unknown as DocumentStatusActionMap
			)[processType][currentDocumentStatus as DocumentStatusValue].includes(desiredDocumentAction),
		[graphqlClient, name, processType, currentDocumentStatus, desiredDocumentAction],
	);
}
