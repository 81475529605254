import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { SEARCH } from '../../utils/Constants';
import { uiText } from '../../utils/Language';

export type WorkspaceMenuSearchProps = {
	initialText?: string;
	onSearch: (searchText: string) => void;
};

const WorkspaceMenuSearch = ({ initialText, onSearch }: WorkspaceMenuSearchProps) => {
	const { t } = useTranslation();
	const [searchText, setSearchText] = useState(initialText);
	const doSearch = useMemo(
		() =>
			debounce((searchString) => {
				onSearch(searchString);
			}, SEARCH.INPUT_DELAY),
		[onSearch],
	);
	useUpdateEffect(() => {
		doSearch(searchText);
	}, [doSearch, searchText]);
	useUpdateEffect(() => {
		setSearchText(initialText);
	}, [initialText]);

	return (
		<Col xs="auto" className="my-2 workspace-menu workspace-menu__page-search pe-0">
			<InputGroup>
				<Form.Control
					type="search"
					aria-label={t(uiText.search.PLACEHOLDER)}
					placeholder={t(uiText.search.PLACEHOLDER)}
					onChange={({ target }) => {
						setSearchText(target.value);
					}}
					value={searchText}
				/>
				<InputGroup.Text>
					<FontAwesomeIcon icon="search" />
				</InputGroup.Text>
			</InputGroup>
		</Col>
	);
};

export default WorkspaceMenuSearch;
