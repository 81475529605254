import { ApolloClient, useApolloClient } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { Button, Col, Table } from 'react-bootstrap';
import { SubmitHandler, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import {
	Bh_EncounterInput,
	Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
	Bh_Encounter_Type_WindowGetForVisitsQuery,
	Bh_ObservationInput,
	Bh_VisitForEditingQuery,
} from '../../graphql/__generated__/graphql';
import { EncounterTypeUU, EncounterTypeWindowUU, fieldUuid, referenceUuids } from '../../models';
import { formatDate, formatDateAndTime } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import EncounterModal from './EncounterModal';
import { VisitFormValues } from './VisitForm';
import { getObservationFields, isFieldGroup } from './VisitUtil';

export type ObservationTableFormValues = {
	vitalsEncounters: Array<{
		UU: string;
		BH_Encounter_Date: Date;
		BH_Encounter_Type: { UU: string };
		BH_Observations: Array<{ UU: string; AD_Field: { UU: string }; BH_Value: string | null }>;
	}>;
};

export const convertToObservationTableFormFields = (
	graphqlClient: ApolloClient<object>,
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
): ObservationTableFormValues => {
	const vitalsEncounterTypeWindow = graphqlClient.readFragment({
		id: EncounterTypeWindowUU.TriageDetails,
		fragment: Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
		fragmentName: 'BH_Encounter_Type_WindowDataForVisits',
	});
	if (!vitalsEncounterTypeWindow || !initialData) {
		return { vitalsEncounters: [] };
	}
	const sortedFields = sortBy(vitalsEncounterTypeWindow.AD_Window.AD_Tabs?.[0].AD_Fields || [], 'SeqNo');
	return {
		vitalsEncounters:
			initialData.BH_Encounters?.filter(
				(encounter) => encounter.BH_Encounter_Type.UU === vitalsEncounterTypeWindow.BH_Encounter_Type.UU,
			).map((encounter) => ({
				UU: encounter.UU,
				BH_Encounter_Date: encounter.BH_Encounter_Date ? new Date(encounter.BH_Encounter_Date) : new Date(),
				BH_Encounter_Type: { UU: vitalsEncounterTypeWindow.BH_Encounter_Type.UU },
				BH_Observations: sortedFields.map((field) => {
					const observationToUse = encounter.BH_Observations?.find(
						(observation) => observation.AD_Field.UU === field.UU,
					);
					return {
						UU: observationToUse?.UU || v4(),
						AD_Field: { UU: field.UU },
						BH_Value: observationToUse?.BH_Value || null,
					};
				}),
			})) || [],
	};
};

export const constructObservationTableFormDataToSave = (
	data: VisitFormValues,
	graphqlClient: ApolloClient<object>,
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
): [{ save: Bh_EncounterInput[]; ignore: string[] }, { save: Bh_ObservationInput[]; ignore: string[] }] => {
	const vitalsEncounterTypeWindow = graphqlClient.readFragment({
		id: EncounterTypeWindowUU.TriageDetails,
		fragment: Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
		fragmentName: 'BH_Encounter_Type_WindowDataForVisits',
	});
	// For some reason, the vitals encounters are sometimes null, so account for that
	if ((!data.vitalsEncounters || data.vitalsEncounters.length === 0) && !vitalsEncounterTypeWindow) {
		// Get the encounter we're dealing with in this "window" so we can ignore those
		let encountersToIgnore =
			initialData?.BH_Encounters?.filter(
				(encounter) => encounter.BH_Encounter_Type.UU === EncounterTypeUU.TriageDetails,
			) || [];
		return [
			{ save: [], ignore: encountersToIgnore.map((encounter) => encounter.UU) },
			{
				save: [],
				ignore: encountersToIgnore.flatMap((encounter) =>
					encounter.BH_Observations?.length ? encounter.BH_Observations.map((observation) => observation.UU) : [],
				),
			},
		];
	}
	let vitalsEncounters: Bh_EncounterInput[] = [];
	let vitalsEncounterObservations: Bh_ObservationInput[] = [];
	for (let encounter of data.vitalsEncounters || []) {
		let vitalsEncounter = {
			UU: encounter.UU,
			BH_Encounter_Date: encounter.BH_Encounter_Date.getTime(),
			BH_Encounter_Type: encounter.BH_Encounter_Type,
			BH_Visit: { UU: data.UU },
			IsActive: true,
		};
		// RHF version 7.12.2 doesn't always reset arrays, so make sure the optional operator is used
		// (7.13.0+ does, but they cause other errors that haven't been fixed up to 7.53.0)
		let vitalsEncounterObservationList =
			encounter.BH_Observations?.filter((observation) => !!observation.BH_Value).map((observation) => ({
				UU: observation.UU,
				AD_Field: { UU: observation.AD_Field.UU },
				BH_Encounter: { UU: encounter.UU },
				BH_Value: observation.BH_Value,
				IsActive: true,
			})) || [];
		if (vitalsEncounterObservationList.length) {
			vitalsEncounters.push(vitalsEncounter);
			vitalsEncounterObservations.push(...vitalsEncounterObservationList);
		}
	}
	return [
		{ save: vitalsEncounters, ignore: [] },
		{ save: vitalsEncounterObservations, ignore: [] },
	];
};

// Let the parent fetch the encounter type windows
export const primeObservationTableData = async () => {};

const createNewVitalEncounter = (
	vitalsEncounterTypeWindow?: Bh_Encounter_Type_WindowGetForVisitsQuery['BH_Encounter_Type_WindowGet']['Results'][0],
): ObservationTableFormValues['vitalsEncounters'][0] => {
	return {
		UU: v4(),
		BH_Encounter_Date: new Date(),
		BH_Encounter_Type: { UU: vitalsEncounterTypeWindow?.BH_Encounter_Type.UU! },
		BH_Observations: sortBy(vitalsEncounterTypeWindow?.AD_Window?.AD_Tabs?.[0].AD_Fields || [], 'SeqNo').map(
			(field) => ({ UU: v4(), BH_Value: null, AD_Field: { UU: field.UU } }),
		),
	};
};

type FieldComponentProps = {
	visitUuid: string;
	isDataReadOnly?: boolean;
};

const ObservationTable = ({ visitUuid, isDataReadOnly }: FieldComponentProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();

	const [selectedEncounter, setSelectedEncounter] = useState<
		ObservationTableFormValues['vitalsEncounters'][0] | undefined
	>();
	const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
	const { fields, remove, prepend, update } = useFieldArray<ObservationTableFormValues, 'vitalsEncounters', 'UU'>({
		name: 'vitalsEncounters',
		keyName: 'UU',
	});

	// We don't need to re-render when we're tracking this, so just use a reference
	const areCreatingNewVitalsEncounter = useRef(false);
	const vitalsEncounterTypeWindow = graphqlClient.readFragment({
		id: EncounterTypeWindowUU.TriageDetails,
		fragment: Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
		fragmentName: 'BH_Encounter_Type_WindowDataForVisits',
	})!;
	// get display fields for the first encounter
	const observationHeaderDisplayList = useMemo(
		() => getObservationFields(graphqlClient, createNewVitalEncounter(vitalsEncounterTypeWindow).BH_Observations),
		[vitalsEncounterTypeWindow, graphqlClient],
	);

	const onSubmit: SubmitHandler<ObservationTableFormValues['vitalsEncounters'][0]> = (formData) => {
		areCreatingNewVitalsEncounter.current = false;
		setSelectedEncounter(undefined);
		setSelectedIndex(undefined);
		if (selectedIndex === undefined) {
			prepend(formData);
		} else {
			update(selectedIndex, formData);
		}
	};

	const onEditVitals = (selectedIndex?: number, encounter?: ObservationTableFormValues['vitalsEncounters'][0]) => {
		areCreatingNewVitalsEncounter.current = !encounter;
		setSelectedEncounter(encounter || createNewVitalEncounter(vitalsEncounterTypeWindow));
		setSelectedIndex(selectedIndex);
	};

	return (
		<>
			<Col xs={12}>
				<Table hover className="bh-table--form table-fixed w-100">
					<thead>
						<tr>
							<th style={{ width: '150px' }}>{t(uiText.visit.DATE_STROKE_TIME)}</th>
							{observationHeaderDisplayList?.map((observationDisplay) => (
								<th
									key={observationDisplay.index}
									style={{
										width:
											!isFieldGroup(observationDisplay) &&
											observationDisplay.field.UU === fieldUuid.LAST_MENSTRUAL_PERIOD
												? '150px'
												: 'auto',
									}}
								>
									{isFieldGroup(observationDisplay)
										? observationDisplay?.observations[0].field?.AD_FieldGroup?.BH_Abbreviation
										: observationDisplay?.field.BH_Abbreviation}
								</th>
							))}
							{!isDataReadOnly && <th style={{ width: '75px' }}>{t(uiText.visit.button.DELETE)}</th>}
						</tr>
					</thead>
					<tbody>
						{fields.map((encounter, encounterIndex) => (
							<tr
								key={encounter.UU}
								onClick={() => {
									if (!isDataReadOnly) {
										onEditVitals(encounterIndex, encounter);
									}
								}}
								className={`${isDataReadOnly ? 'bg-gray-200' : ''}`}
							>
								<td className="align-middle text-center">{formatDateAndTime(encounter.BH_Encounter_Date)}</td>
								{getObservationFields(graphqlClient, encounter?.BH_Observations)?.map((observationDisplay) => (
									<td
										className="align-middle text-center"
										key={encounter.UU + observationDisplay.index}
										aria-label={
											(isFieldGroup(observationDisplay)
												? observationDisplay.observations[0].field?.AD_FieldGroup?.BH_Abbreviation
												: observationDisplay.field.BH_Abbreviation) || ''
										}
									>
										{(isFieldGroup(observationDisplay)
											? observationDisplay.observations
													.map(
														(observationGroupDisplay) =>
															(observationGroupDisplay.field.AD_Column.AD_Reference.UU === referenceUuids.DATE
																? (observationGroupDisplay.observation.BH_Value &&
																		formatDate(new Date(parseInt(observationGroupDisplay.observation.BH_Value, 10)))) ||
																	'-'
																: observationGroupDisplay.observation.BH_Value) || '-',
													)
													.join(' / ')
											: observationDisplay.field.AD_Column.AD_Reference.UU === referenceUuids.DATE
												? (observationDisplay.observation.BH_Value &&
														formatDate(new Date(parseInt(observationDisplay.observation.BH_Value, 10)))) ||
													'-'
												: observationDisplay.observation.BH_Value) || '-'}
									</td>
								))}
								{!isDataReadOnly && (
									<td className="align-middle text-center">
										<button
											aria-label={t(uiText.visit.button.DELETE)}
											type="button"
											className="btn p-0"
											tabIndex={-1}
											onClick={(e) => {
												e.stopPropagation();
												remove(encounterIndex);
											}}
										>
											<FontAwesomeIcon icon="trash" className="border-0" />
										</button>
									</td>
								)}
							</tr>
						))}
					</tbody>
					{!isDataReadOnly && (
						<tfoot>
							<tr>
								<td colSpan={observationHeaderDisplayList.length + 1} className="align-middle px-2 py-2">
									<Button type="button" variant="success" onClick={() => onEditVitals()}>
										<FontAwesomeIcon icon={faPlus} className="me-1" />
										{t(uiText.visit.button.ADD_NEW_VITAL)}
									</Button>
								</td>
							</tr>
						</tfoot>
					)}
				</Table>
			</Col>
			{selectedEncounter && (
				<EncounterModal
					visitUuid={visitUuid}
					encounter={selectedEncounter}
					isNew={areCreatingNewVitalsEncounter.current}
					onHandleClose={() => setSelectedEncounter(undefined)}
					onSubmit={onSubmit}
				/>
			)}
		</>
	);
};
export default ObservationTable;
