import { useApolloClient } from '@apollo/client';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoginCheckOtherClientsDocument } from '../../graphql/__generated__/graphql';
import { uiText } from '../../utils/Language';
import PasswordInput from '../password-input/PasswordInput';

type ResetPasswordModalProperties = {
	username: string;
	onHandleClose: () => void;
	onHandleSubmit: SubmitHandler<{ resetPassword: string }>;
};

const ResetPasswordModal = ({ username, onHandleClose, onHandleSubmit }: ResetPasswordModalProperties) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();

	const formMethods = useForm<{ resetPassword: string }>({
		defaultValues: { resetPassword: '' },
	});

	const validatePassword = async (password: string) => {
		// Check if the selected username and password exists in any other clients,
		// and if so return false to indicate the validation has failed
		const checkOtherClients = await graphqlClient.mutate({
			mutation: LoginCheckOtherClientsDocument,
			variables: { Credentials: { Username: username, Password: password, AD_Language: '' } },
		});
		if (checkOtherClients.data?.LoginCheckOtherClients) {
			return t(uiText.manageUsers.error.ERROR_WITH_PASSWORD);
		}
		return true;
	};

	return (
		<Modal show={true}>
			<Form onSubmit={formMethods.handleSubmit(onHandleSubmit)}>
				<Modal.Header>{t(uiText.manageUsers.RESET_PASSWORD)}</Modal.Header>
				<Modal.Body>
					<Form.Group as={Row} className={`my-2`}>
						<Form.Label column className={'col-auto'}>
							{t(uiText.manageUsers.ONE_TIME_PASSWORD)}
						</Form.Label>
						<Col xs={8}>
							<PasswordInput
								placeholder={t(uiText.manageUsers.ENTER_PASSWORD)}
								{...formMethods.register('resetPassword', {
									required: t(uiText.manageUsers.validationMessages.REQUIRE_ONE_TIME_PASSWORD),
									validate: validatePassword,
								})}
							/>
						</Col>
					</Form.Group>
					{formMethods.formState?.errors?.resetPassword && (
						<span className="text-danger">{formMethods.formState?.errors?.resetPassword.message}</span>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" type="button" onClick={onHandleClose}>
						{t(uiText.modal.button.CANCEL)}
					</Button>
					<Button variant="success" type="submit" className="ms-auto">
						{t(uiText.manageUsers.RESET)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default ResetPasswordModal;
