import { useApolloClient } from '@apollo/client';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import UserContext from '../../contexts/UserContext';
import { ChangePasswordDocument, LoginCheckOtherClientsDocument } from '../../graphql/__generated__/graphql';
import { convertLocaleToBackend } from '../../models/Language';
import { uiText } from '../../utils/Language';
import PasswordInput from '../password-input/PasswordInput';

type ChangePasswordModalProps = {
	serverErrorMessage?: string;
	onHandleClose: () => void;
};

const ChangePasswordModal = ({ onHandleClose, serverErrorMessage }: ChangePasswordModalProps) => {
	const graphqlClient = useApolloClient();
	const [newPassword, setNewPassword] = useState('');
	const [retypedNewPassword, setRetypedNewPassword] = useState('');
	const [errorMessageToDisplay, setErrorMessageToDisplay] = useState(serverErrorMessage);
	const [hasFormBeenSubmitted, setHasFormBeenSubmitted] = useState(false);
	const [currentPassword, setCurrentPassword] = useState('');
	const { user } = useContext(UserContext);
	const { t, i18n } = useTranslation();

	const arePasswordsValid = useCallback(
		(shouldDisplayErrors) => {
			if (!newPassword) {
				setErrorMessageToDisplay(t(uiText.changePassword.error.NO_NEW_PASSWORD_ENTERED));
				return false;
			}
			if (newPassword !== retypedNewPassword) {
				if (shouldDisplayErrors) {
					setErrorMessageToDisplay(t(uiText.changePassword.error.PASSWORDS_DIFFER));
				}
				return false;
			}
			setErrorMessageToDisplay('');
			return true;
		},
		[newPassword, retypedNewPassword, t],
	);

	useEffect(() => {
		arePasswordsValid(hasFormBeenSubmitted);
	}, [arePasswordsValid, hasFormBeenSubmitted, newPassword, retypedNewPassword]);

	useEffect(() => {
		setErrorMessageToDisplay(serverErrorMessage);
	}, [serverErrorMessage]);

	const onSubmitClick = async () => {
		setHasFormBeenSubmitted(true);
		if (!arePasswordsValid(true)) {
			return;
		}

		if (!isCurrentPasswordValid(true)) {
			return;
		}
		try {
			// Check if the selected username and password exists in any other clients,
			// and if so raise an error
			const checkOtherClients = await graphqlClient.mutate({
				mutation: LoginCheckOtherClientsDocument,
				variables: {
					Credentials: {
						Username: user.Name,
						Password: newPassword,
						AD_Language: convertLocaleToBackend(t(i18n.language)),
					},
				},
			});
			if (checkOtherClients.data?.LoginCheckOtherClients) {
				setErrorMessageToDisplay(t(uiText.manageUsers.error.ERROR_WITH_PASSWORD));
				return;
			}

			// Apply the new password
			await graphqlClient
				.mutate({
					mutation: ChangePasswordDocument,
					variables: { PasswordInfo: { Username: user.Name, Password: currentPassword, NewPassword: newPassword } },
				})
				.then(() => {
					onHandleClose();
					toast.success(t(uiText.changePassword.PASSWORD_CHANGED));
				})
				.catch(() => {
					setErrorMessageToDisplay(t(uiText.login.error.ERROR_OCCURRED));
				});
		} catch (error) {
			setErrorMessageToDisplay(t(uiText.login.error.WRONG_PASSWORD));
		}
	};

	const updateInput = (stateUpdateFunction: (valueToSet: string) => void) => (event: React.ChangeEvent) => {
		stateUpdateFunction((event.target as HTMLInputElement).value);
	};

	const isCurrentPasswordValid = (shouldDisplayErrors: boolean) => {
		if (!currentPassword) {
			if (shouldDisplayErrors) {
				setErrorMessageToDisplay(t(uiText.changePassword.error.NO_CURRENT_PASSWORD));
			}
			return false;
		}
		return true;
	};

	return (
		<Modal show={true}>
			<Form>
				<Modal.Header>
					<Modal.Title>{t(uiText.login.changePassword.CHANGE)}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="my-2">
						<Form.Label column className="d-flex align-items-center" htmlFor="currentPassword" xs={5}>
							{t(uiText.login.changePassword.CURRENT_PASSWORD)}
						</Form.Label>
						<Col className="d-flex align-items-center">
							<PasswordInput
								value={currentPassword}
								onChange={updateInput(setCurrentPassword)}
								placeholder={t(uiText.login.changePassword.CURRENT_PASSWORD)}
							/>
						</Col>
					</Row>
					<Row className="my-2">
						<Form.Label column className="d-flex align-items-center" htmlFor="currentPassword" xs={5}>
							{t(uiText.login.changePassword.NEW_PASSWORD)}
						</Form.Label>
						<Col className="d-flex align-items-center">
							<PasswordInput
								value={newPassword}
								onChange={updateInput(setNewPassword)}
								placeholder={t(uiText.login.changePassword.NEW_PASSWORD)}
							/>
						</Col>
					</Row>
					<Row className="my-2">
						<Form.Label column className="d-flex align-items-center" htmlFor="retypedNewPassword" xs={5}>
							{t(uiText.login.changePassword.RETYPE_PASSWORD)}
						</Form.Label>
						<Col className="d-flex align-items-center">
							<PasswordInput
								value={retypedNewPassword}
								onChange={updateInput(setRetypedNewPassword)}
								placeholder={t(uiText.login.changePassword.RETYPE_PASSWORD)}
							/>
						</Col>
					</Row>
					{errorMessageToDisplay ? (
						<Row>
							<Col xs={8} className="ms-auto text-end">
								<span className="text-danger">{errorMessageToDisplay}</span>
							</Col>
						</Row>
					) : (
						''
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						onClick={() => {
							onHandleClose();
						}}
					>
						{t(uiText.modal.button.CANCEL)}
					</Button>
					<Button variant="success" className="ms-auto" data-dismiss="modal" onClick={onSubmitClick}>
						{t(uiText.modal.button.CHANGE)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default ChangePasswordModal;
