import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';

export enum ClientUU {
	System = '11237b53-9592-4af1-b3c5-afd216514b5d',
}

export interface ClientDB extends BaseEntityDB {
	ad_client_uu: string;
}

export interface ClientDto extends BaseEntityDto {}

export default class Client extends BaseEntity {
	constructor(props?: Partial<Client | ClientDto>) {
		props = cloneDeep(props || {});
		super(props);
	}
}
