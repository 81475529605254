import { useApolloClient } from '@apollo/client';
import React, { Fragment, useState } from 'react';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Bh_ConceptDiagnosisForEditingDocument } from '../../graphql/__generated__/graphql';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import { ConceptExtraKey, ConceptMappingMapType, ConceptSource } from '../../models';
import EntityFormProperties from '../../types/EntityFormProperties';
import { uiText } from '../../utils/Language';
import { getCodedDiagnosisIcd10Code } from '../../utils/ModelUtils';
import BasicButton from '../ActionButtons/BasicButton';
import { withFormModalSuspenseWrapper } from '../HOCs/withFormModalSuspenseWrapper';
import Layout from '../Layout/Layout';

export type CodedDiagnosisFormProps = EntityFormProperties;
export type CodedDiagnosisFormValues = {
	BH_Display_Name: string | null;
	bh_concept_class: string | null;
	BH_Concept_Extras: {
		MOH_705A_LESSTHAN5: string | null;
		MOH_705B_GREATERTHAN5: string | null;
		INDEX_TERMS: string | null;
	};
	Description: string | null;
	ToBH_Concept_Mappings: {
		CEIL_ID: string | null;
		Icd10Who: string | null;
		SnoMed_CT: string | null;
		SnoMed_NP: string | null;
	};
};

const getTitle = (_uuid?: string) => uiText.codedDiagnosis.title.VIEW;

const CodedDiagnosisForm = ({ uuid, onFinish, renderAsModal }: CodedDiagnosisFormProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();

	const { data: codedDiagnosis } = useSuspenseAsync(uuid || 'view-empty-diagnosis', async () =>
		uuid
			? graphqlClient
					.query({
						query: Bh_ConceptDiagnosisForEditingDocument,
						variables: { UU: uuid },
						fetchPolicy: 'cache-first',
					})
					.then((result) => result.data.BH_Concept)
			: undefined,
	);

	const title = getTitle(uuid);
	const [data] = useState(codedDiagnosis);

	const {
		register,
		handleSubmit,
		formState: { isDirty },
	} = useForm<CodedDiagnosisFormValues>({
		defaultValues: {
			BH_Display_Name: data?.BH_Display_Name || null,
			bh_concept_class: data?.bh_concept_class || null,
			BH_Concept_Extras: {
				MOH_705A_LESSTHAN5:
					data?.BH_Concept_Extras?.find(
						(extra) => extra.BH_Key?.toLowerCase() === ConceptExtraKey.MOH_705A_LESSTHAN5.toLowerCase(),
					)?.BH_Value || null,
				MOH_705B_GREATERTHAN5:
					data?.BH_Concept_Extras?.find(
						(extra) => extra.BH_Key?.toLowerCase() === ConceptExtraKey.MOH_705B_GREATERTHAN5.toLowerCase(),
					)?.BH_Value || null,
				INDEX_TERMS:
					data?.BH_Concept_Extras?.find(
						(extra) => extra.BH_Key?.toLowerCase() === ConceptExtraKey.INDEX_TERMS.toLowerCase(),
					)?.BH_Value || null,
			},
			Description: data?.Description || null,
			ToBH_Concept_Mappings: {
				CEIL_ID:
					data?.ToBH_Concept_Mappings?.find(
						(conceptMapping) =>
							conceptMapping.BH_Map_Type?.toLowerCase() === ConceptMappingMapType.SAME_AS.toLowerCase() &&
							conceptMapping.BH_To_Source_Name === ConceptSource.Ciel,
					)?.BH_To_Concept_Code || null,
				Icd10Who: getCodedDiagnosisIcd10Code(data) || null,
				SnoMed_CT:
					data?.ToBH_Concept_Mappings?.find(
						(conceptMapping) =>
							conceptMapping.BH_Map_Type?.toLowerCase() === ConceptMappingMapType.SAME_AS.toLowerCase() &&
							conceptMapping.BH_To_Source_Name === ConceptSource.SnoMed_CT,
					)?.BH_To_Concept_Code || null,
				SnoMed_NP:
					data?.ToBH_Concept_Mappings?.find(
						(conceptMapping) =>
							conceptMapping.BH_Map_Type?.toLowerCase() === ConceptMappingMapType.NARROWER_THAN.toLowerCase() &&
							conceptMapping.BH_To_Source_Name === ConceptSource.SnoMed_CT,
					)?.BH_To_Concept_Code || null,
			},
		},
	});
	useConfirmRefresh(isDirty);

	const inputLabelWidth = renderAsModal ? 3 : 2;
	const inputDisplayWidth = renderAsModal ? 10 : 7;
	const inputs = (
		<Form onSubmit={handleSubmit(() => {})}>
			<fieldset disabled={true}>
				<Row className="gy-3">
					<Form.Group as={Fragment} controlId="CEIL_ID">
						<Col xs={inputLabelWidth} className="d-flex align-items-center">
							<Form.Label>{t(uiText.codedDiagnosis.labels.CIEL_ID)}</Form.Label>
						</Col>
						<Col xs={inputDisplayWidth} className="d-flex align-items-center">
							<Form.Control {...register('ToBH_Concept_Mappings.CEIL_ID')} />
						</Col>
						{renderAsModal ? null : <Col xs={12 - inputLabelWidth - inputDisplayWidth} />}
					</Form.Group>

					<Form.Group as={Fragment} controlId="BH_Display_Name">
						<Col xs={inputLabelWidth} className="d-flex align-items-center">
							<Form.Label>{t(uiText.codedDiagnosis.labels.DISPLAY_NAME)}</Form.Label>
						</Col>
						<Col xs={inputDisplayWidth} className="d-flex align-items-center">
							<Form.Control {...register('BH_Display_Name')} />
						</Col>
						{renderAsModal ? null : <Col xs={12 - inputLabelWidth - inputDisplayWidth} />}
					</Form.Group>

					<Form.Group as={Fragment} controlId="bh_concept_class">
						<Col xs={inputLabelWidth} className="d-flex align-items-center">
							<Form.Label>{t(uiText.codedDiagnosis.labels.CONCEPT_CLASS)}</Form.Label>
						</Col>
						<Col xs={inputDisplayWidth} className="d-flex align-items-center">
							<Form.Control {...register('bh_concept_class')} />
						</Col>
						{renderAsModal ? null : <Col xs={12 - inputLabelWidth - inputDisplayWidth} />}
					</Form.Group>

					<Form.Group as={Fragment} controlId="Icd10Who">
						<Col xs={inputLabelWidth} className="d-flex align-items-center">
							<Form.Label>{t(uiText.codedDiagnosis.labels.ICD10_WHO)}</Form.Label>
						</Col>
						<Col xs={inputDisplayWidth} className="d-flex align-items-center">
							<Form.Control {...register('ToBH_Concept_Mappings.Icd10Who')} />
						</Col>
						{renderAsModal ? null : <Col xs={12 - inputLabelWidth - inputDisplayWidth} />}
					</Form.Group>

					<Form.Group as={Fragment} controlId="SnoMed_CT">
						<Col xs={inputLabelWidth} className="d-flex align-items-center">
							<Form.Label>{t(uiText.codedDiagnosis.labels.SYNOMED_CT)}</Form.Label>
						</Col>
						<Col xs={inputDisplayWidth} className="d-flex align-items-center">
							<Form.Control {...register(`ToBH_Concept_Mappings.SnoMed_CT`)} />
						</Col>
						{renderAsModal ? null : <Col xs={12 - inputLabelWidth - inputDisplayWidth} />}
					</Form.Group>

					<Form.Group as={Fragment} controlId="SnoMed_NP">
						<Col xs={inputLabelWidth} className="d-flex align-items-center">
							<Form.Label>{t(uiText.codedDiagnosis.labels.SYNOMED_NP)}</Form.Label>
						</Col>
						<Col xs={inputDisplayWidth} className="d-flex align-items-center">
							<Form.Control {...register(`ToBH_Concept_Mappings.SnoMed_NP`)} />
						</Col>
						{renderAsModal ? null : <Col xs={12 - inputLabelWidth - inputDisplayWidth} />}
					</Form.Group>

					<Form.Group as={Fragment} controlId="MOH_705A_LESSTHAN5">
						<Col xs={inputLabelWidth} className="d-flex align-items-center">
							<Form.Label>{t(uiText.codedDiagnosis.labels.MOH705A_LESSTHAN5)}</Form.Label>
						</Col>
						<Col xs={inputDisplayWidth} className="d-flex align-items-center">
							<Form.Control {...register(`BH_Concept_Extras.MOH_705A_LESSTHAN5`)} />
						</Col>
						{renderAsModal ? null : <Col xs={12 - inputLabelWidth - inputDisplayWidth} />}
					</Form.Group>

					<Form.Group as={Fragment} controlId="MOH_705B_GREATERTHAN5">
						<Col xs={inputLabelWidth} className="d-flex align-items-center">
							<Form.Label>{t(uiText.codedDiagnosis.labels.MOH705B_GREATERTHAN5)}</Form.Label>
						</Col>
						<Col xs={inputDisplayWidth} className="d-flex align-items-center">
							<Form.Control {...register(`BH_Concept_Extras.MOH_705B_GREATERTHAN5`)} />
						</Col>
						{renderAsModal ? null : <Col xs={12 - inputLabelWidth - inputDisplayWidth} />}
					</Form.Group>

					<Form.Group as={Fragment} controlId="INDEX_TERMS">
						<Col xs={inputLabelWidth} className="d-flex align-items-center">
							<Form.Label>{t(uiText.codedDiagnosis.labels.SEARCH_TERMS)}</Form.Label>
						</Col>
						<Col xs={inputDisplayWidth} className="d-flex align-items-center">
							<Form.Control {...register(`BH_Concept_Extras.INDEX_TERMS`)} />
						</Col>
						{renderAsModal ? null : <Col xs={12 - inputLabelWidth - inputDisplayWidth} />}
					</Form.Group>

					<Form.Group as={Fragment} controlId="Description">
						<Col xs={inputLabelWidth}>
							<Form.Label>{t(uiText.codedDiagnosis.description.LABEL)}</Form.Label>
						</Col>
						<Col xs={inputDisplayWidth} className="d-flex align-items-center">
							<Form.Control as="textarea" rows={3} {...register('Description')} />
						</Col>
						{renderAsModal ? null : <Col xs={12 - inputLabelWidth - inputDisplayWidth} />}
					</Form.Group>
				</Row>
			</fieldset>
		</Form>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'mt-4'}`}>
			<Col xs="auto" className="me-auto">
				<BasicButton
					name={uiText.codedDiagnosis.button.BACK}
					text={t(uiText.codedDiagnosis.button.BACK)}
					variant="danger"
					icon="arrow-left"
					active={true}
					onClick={() => onFinish(false)}
				/>
			</Col>
		</Row>
	);

	return renderAsModal ? (
		<>
			<Modal.Header closeButton>
				<Modal.Title>{t(title)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{inputs}</Modal.Body>
			<Modal.Footer>
				<div className="w-100">{buttons}</div>
			</Modal.Footer>
		</>
	) : (
		<>
			<Layout.Header>
				<Layout.Title title={t(title)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<div className="ps-2_5 pb-0_5 bg-white">
					<Card className="bh-card">
						<Card.Body>{inputs}</Card.Body>
					</Card>
					{buttons}
				</div>
			</Layout.Body>
		</>
	);
};

export default withFormModalSuspenseWrapper<CodedDiagnosisFormProps>({
	loadingLabel: uiText.codedDiagnosis.LOADING,
	getTitle,
	size: 'lg',
})(CodedDiagnosisForm);
