import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
	M_ProductForServicesListPageDocument,
	M_ProductForServicesListPageQuery,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useListPageFunctionality from '../../hooks/useListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useStateWithReset from '../../hooks/useStateWithReset';
import { DBFilter, Filter, ListPageState, ProductDB, ProductType } from '../../models';
import { exception } from '../../utils/analytics';
import { IS_ACTIVE } from '../../utils/CommonFilters';
import { pageUuid } from '../../utils/Constants';
import { formatDate } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import { formatNumber } from '../../utils/NumberUtil';
import BHGraphQLTable from '../BHTable/BHGraphQLTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import ServiceForm from './ServiceForm';

const availableFilters = {
	[uiText.service.filter.INACTIVE]: DBFilter<ProductDB>().property('isActive').equals(false),
	[uiText.service.filter.ACTIVE]: IS_ACTIVE as unknown as Filter<ProductDB>,
};

const Service = () => {
	const { t } = useTranslation();
	const { disableWrite } = useActionPrivileges(pageUuid.SERVICES);
	const graphqlClient = useApolloClient();

	const [searchText, setSearchText] = useState('');
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useListPageFunctionality<M_ProductForServicesListPageQuery['M_ProductGet']['Results'][0]>(
		{
			fetch: useCallback(
				async (variables) =>
					(
						await graphqlClient.query({
							query: M_ProductForServicesListPageDocument,
							variables,
							fetchPolicy: 'network-only',
						})
					).data.M_ProductGet,
				[graphqlClient],
			),
			onError: useCallback(
				(error) => {
					if (error.response) {
						toast.error(t(uiText.service.loading.FAIL));
					}
					exception({ description: `Service fetch error: ${error}` });
				},
				[t],
			),
			refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
		},
		{
			sorted: JSON.stringify([['created', 'desc']]),
		},
	);

	// Filter states
	const [inactiveFilter, setInactiveFilter, { reset: resetInactiveFilter }] = useStateWithReset<
		keyof typeof availableFilters
	>(uiText.service.filter.ACTIVE);

	// Handle searching and filtering
	useEffect(() => {
		let defaultFilter = DBFilter<ProductDB>()
			.property('producttype')
			.equals(ProductType.SERVICE)
			.and(availableFilters[inactiveFilter]);
		if (searchText) {
			defaultFilter = defaultFilter.and(DBFilter<ProductDB>().property('name').contains(searchText));
		}
		onFilterUpdate(defaultFilter.toString());
	}, [searchText, onFilterUpdate, inactiveFilter]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		resetInactiveFilter();
		setSearchText('');
		reset();
	});

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.service.title.LIST)}
							showRefreshIcon
							onRefresh={() => {
								refresh({ resetPage: true });
							}}
							areRefreshing={areRefreshing}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search initialText={searchText} onSearch={setSearchText} />
							<WorkspaceMenu.Filters>
								<Form.Group controlId="activeFilter" className="mt-2">
									<Form.Check
										checked={inactiveFilter === uiText.service.filter.INACTIVE}
										onChange={(e) =>
											setInactiveFilter(
												e.target.checked ? uiText.service.filter.INACTIVE : uiText.service.filter.ACTIVE,
											)
										}
										label={t(uiText.service.filter.SHOW_INACTIVE)}
									/>
								</Form.Group>
							</WorkspaceMenu.Filters>
							{!disableWrite && <WorkspaceMenu.NewButton onClick={() => setViewState(ListPageState.ADD_EDIT)} />}
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							<BHGraphQLTable<M_ProductForServicesListPageQuery['M_ProductGet']['Results'][0]>
								data={data}
								columns={[
									{
										id: 'created',
										Header: () => <div className={'React-table-header'}>{t(uiText.service.CREATED)}</div>,
										accessor: (d) => formatDate(new Date(d.Created)),
									},
									{
										Header: () => <div className={'React-table-header'}>{t(uiText.service.name.LABEL)}</div>,
										accessor: 'Name',
									},
									{
										id: 'BH_SellPrice',
										Header: () => <div className={'React-table-header'}>{t(uiText.service.sellingPrice.LABEL)}</div>,
										accessor: (d) => (
											<div className="w-50 d-flex justify-content-center">
												{d.BH_SellPrice ? formatNumber(d.BH_SellPrice) : null}
											</div>
										),
										disableSortBy: true,
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => <LoadSpinner show={isLoading} title={t(uiText.service.loading.LOADING)} />}
								onFetchData={onTableUpdate}
								getTrGroupProps={rowProperties}
								sortBy={sorted}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : (
				<ServiceForm
					uuid={selectedUuid}
					onFinish={(refreshData?: boolean) => {
						setViewState(ListPageState.LIST);
						if (refreshData) {
							refresh();
						}
					}}
				/>
			)}
		</Layout>
	);
};

export default Service;
