import { useApolloClient, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import {
	C_Charge_AcctSaveForExpenseCategoriesDocument,
	C_ChargeForExpenseCategoryEditingDocument,
	C_ChargeForExpenseCategoryEditingQuery,
	C_ChargeSaveAndC_Charge_AcctSaveForExpenseCategoriesDocument,
	C_ChargeSaveForExpenseCategoriesDocument,
	C_ChargeSaveForExpenseCategoriesMutationVariables,
	C_ChargeTypeForExpenseCategoriesDocument,
	C_ElementValuesForExpenseCategoriesDocument,
	C_ValidCombinationGetOrCreateDocument,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import { ChargeTypeDB, chargeTypeDefaultNames, DBFilter, Paging } from '../../models';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import { pageUuid } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import { getAccountDisplay } from '../../utils/ModelUtils';
import BasicButton from '../ActionButtons/BasicButton';
import DynamicSelect from '../dynamic-select/DynamicSelect';
import { withFormModalSuspenseWrapper } from '../HOCs/withFormModalSuspenseWrapper';
import Layout from '../Layout/Layout';

type ExpenseCategoryFormProps = EntityFormProperties;

type ExpenseCategoryFormFields = {
	UU: string;
	AccountUU: string;
	Description: string | null;
	Name: string;
};

const expenseChargeTypeFilter = DBFilter<ChargeTypeDB>()
	.property('name')
	.equals(chargeTypeDefaultNames.EXPENSE_CATEGORY);
const getTitle = (uuid?: string) => (uuid ? uiText.expenseCategory.title.UPDATE : uiText.expenseCategory.title.NEW);
const convertToFormFields: (
	initialData?: C_ChargeForExpenseCategoryEditingQuery['C_Charge'],
) => ExpenseCategoryFormFields = (initialData) => {
	if (!initialData) {
		return {
			UU: v4(),
			AccountUU: '',
			Description: null,
			Name: '',
		};
	}
	return {
		UU: initialData.UU,
		AccountUU: initialData.C_Charge_AcctList?.[0]?.Ch_Expense_A.Account.UU || '',
		Description: initialData.Description || null,
		Name: initialData.Name,
	};
};

const ExpenseCategoryForm = ({ uuid, onFinish, renderAsModal }: ExpenseCategoryFormProps) => {
	const graphqlClient = useApolloClient();
	const { data } = useSuspenseAsync(uuid || 'add-charge', async () =>
		uuid
			? (
					await graphqlClient.query({
						query: C_ChargeForExpenseCategoryEditingDocument,
						variables: { UU: uuid },
						fetchPolicy: 'network-only',
					})
				).data.C_Charge
			: undefined,
	);

	const { t } = useTranslation();
	const [dataToUse] = useState(data);
	const title = getTitle(dataToUse ? data?.UU : undefined);
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors, isDirty },
	} = useForm<ExpenseCategoryFormFields>({
		defaultValues: convertToFormFields(data),
	});
	const { disableWrite } = useActionPrivileges(pageUuid.EXPENSE_CATEGORIES);

	const { data: expenseChargeType, refetch: fetchExpenseChargeType } = useQuery(
		C_ChargeTypeForExpenseCategoriesDocument,
		{
			variables: { Filter: expenseChargeTypeFilter.toString() },
			fetchPolicy: 'cache-first',
		},
	);

	useConfirmRefresh(isDirty);

	const { data: accounts, loading: areLoadingAccounts } = useQuery(C_ElementValuesForExpenseCategoriesDocument, {
		variables: {
			Page: Paging.ALL.page,
			Size: Paging.ALL.size,
			Sort: JSON.stringify([
				['value', 'asc'],
				['name', 'asc'],
			]),
		},
		fetchPolicy: 'cache-first',
	});

	// Update the dropdown once the accounts load
	const accountLength = accounts?.C_ElementValueGet.Results.length || 0;
	useEffect(() => {
		setValue('AccountUU', getValues('AccountUU'));
	}, [accountLength, setValue, getValues]);

	const [, onSubmit] = useCustomAsyncFn<SubmitHandler<ExpenseCategoryFormFields>>(
		async (formData) => {
			let expenseChargeTypeToUse = expenseChargeType;
			if (!expenseChargeType) {
				try {
					expenseChargeTypeToUse = (
						await graphqlClient.query({
							query: C_ChargeTypeForExpenseCategoriesDocument,
							variables: { Filter: expenseChargeTypeFilter.toString() },
						})
					).data;
					fetchExpenseChargeType();
				} catch (error) {
					exception({ description: 'Could not load expense charge type: ' + error });
					toast.error(t(uiText.error.PLEASE_TRY_AGAIN));
					return;
				}
			}
			const charge: C_ChargeSaveForExpenseCategoriesMutationVariables['C_Charge'] = {
				UU: formData.UU,
				C_ChargeType: expenseChargeTypeToUse ? { UU: expenseChargeTypeToUse.C_ChargeTypeGet.Results[0].UU } : undefined,
				Description: formData.Description,
				Name: formData.Name,
			};

			try {
				// If this is an existing charge, we'll just update everything together
				if (dataToUse && dataToUse.C_Charge_AcctList?.length) {
					await graphqlClient.mutate({
						mutation: C_ChargeSaveAndC_Charge_AcctSaveForExpenseCategoriesDocument,
						variables: {
							C_Charge: charge,
							C_Charge_Acct: {
								UU: dataToUse.C_Charge_AcctList[0].UU,
								Ch_Expense_A: {
									UU: (
										await graphqlClient.mutate({
											mutation: C_ValidCombinationGetOrCreateDocument,
											variables: {
												Account_UU: formData.AccountUU,
												C_AcctSchema_UU: dataToUse.C_Charge_AcctList[0].C_AcctSchema.UU,
											},
										})
									).data!.C_ValidCombinationGetOrCreate.UU,
								},
							},
						},
					});
				} else {
					// Save the chrge to get the charge account created for it, then save the valid combination for the account on the charge account
					const savedCharge = await graphqlClient.mutate({
						mutation: C_ChargeSaveForExpenseCategoriesDocument,
						variables: { C_Charge: charge },
					});
					if (!savedCharge.data?.C_ChargeSave.UU) {
						throw savedCharge.errors;
					}
					// Now save the account correctly
					await graphqlClient.mutate({
						mutation: C_Charge_AcctSaveForExpenseCategoriesDocument,
						variables: {
							C_Charge_Acct: {
								UU: savedCharge.data?.C_ChargeSave.C_Charge_AcctList?.[0].UU,
								Ch_Expense_A: {
									UU: (
										await graphqlClient.mutate({
											mutation: C_ValidCombinationGetOrCreateDocument,
											variables: {
												Account_UU: formData.AccountUU,
												C_AcctSchema_UU: savedCharge.data?.C_ChargeSave.C_Charge_AcctList?.[0].C_AcctSchema.UU,
											},
										})
									).data!.C_ValidCombinationGetOrCreate.UU,
								},
							},
						},
					});
				}
				toast.success(t(uiText.expenseCategory.success.UPDATE));
				onFinish(true, formData.UU);
			} catch (error) {
				exception({ description: `Expense Category save error: ${error}` });
				toast.error(t(uiText.expenseCategory.error.COULD_NOT_SAVE, { error }));
			}
		},
		[expenseChargeType, fetchExpenseChargeType, graphqlClient, dataToUse],
	);

	const inputs = (
		<Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
			<input type="hidden" {...register('UU')} />
			<fieldset disabled={disableWrite || !!dataToUse?.BH_Locked}>
				<Form.Group as={Row} controlId={'Name'} className={'mb-3'}>
					<Form.Label column xs={2}>
						{t(uiText.expenseCategory.name.LABEL)}
					</Form.Label>
					<Col xs={7}>
						<Form.Control
							type="text"
							{...register('Name', { required: true })}
							placeholder={t(uiText.expenseCategory.label.ENTER_NAME)}
						/>
						{errors.Name && (
							<span className="text-danger">{t(uiText.expenseCategory.validationMessages.REQUIRE_NAME)}</span>
						)}
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId={'Description'} className={'mb-3'}>
					<Form.Label column xs={2}>
						{t(uiText.expenseCategory.description.LABEL)}
					</Form.Label>
					<Col xs={7}>
						<Form.Control
							as={'textarea'}
							rows={3}
							{...register('Description')}
							placeholder={t(uiText.expenseCategory.description.ENTER)}
						></Form.Control>
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId={'AcountUU'} className={'mb-3'}>
					<Form.Label column xs={2}>
						{t(uiText.expenseCategory.account.LABEL)}
					</Form.Label>
					<Col xs={7}>
						<DynamicSelect
							{...register('AccountUU', { required: true, pattern: /[0-9]{1,5}[A-Za-z]/ })}
							isLoading={areLoadingAccounts}
							placeholder={t(uiText.expenseCategory.account.LOADING)}
						>
							<option>{t(uiText.expenseCategory.account.SELECT)}</option>
							{accounts?.C_ElementValueGet.Results?.map((account) => (
								<option key={account.UU} value={account.UU}>
									{getAccountDisplay(account)}
								</option>
							))}
						</DynamicSelect>
						{errors?.AccountUU && (
							<span className="text-danger">{t(uiText.expenseCategory.validationMessages.REQUIRE_CATEGORY)}</span>
						)}
					</Col>
				</Form.Group>
			</fieldset>
		</Form>
	);

	const buttons = (
		<Row className={'d-flex justify-content-between'} xs={7}>
			{disableWrite ? (
				<Col xs="auto" className="me-auto">
					<BasicButton
						name={uiText.supplier.button.BACK}
						text={t(uiText.supplier.button.BACK)}
						classes="btn btn-danger"
						icon="arrow-left"
						active={true}
						onClick={() => onFinish(false)}
					/>
				</Col>
			) : (
				<>
					<Col xs="auto">
						<Button type="button" name="cancel" variant="danger" onClick={() => onFinish(false)}>
							{dataToUse?.BH_Locked ? t(uiText.expenseCategory.button.BACK) : t(uiText.expenseCategory.button.CANCEL)}
						</Button>
					</Col>
					{dataToUse?.BH_Locked ? null : (
						<Col xs="auto">
							<Button type="submit" name="confirm" variant="success" onClick={() => handleSubmit(onSubmit)()}>
								{t(uiText.expenseCategory.button.SAVE)}
							</Button>
						</Col>
					)}
				</>
			)}
		</Row>
	);

	return renderAsModal ? (
		<>
			<Modal.Header closeButton>
				<Modal.Title>{t(title)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{inputs}</Modal.Body>
			<Modal.Footer>
				<div className="w-100">{buttons}</div>
			</Modal.Footer>
		</>
	) : (
		<>
			<Layout.Header>
				<Layout.Title title={t(title)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<div className="ps-2_5 pb-0_5 bg-white">
					<Card className="bh-card">
						<Card.Body>
							{inputs}
							{buttons}
						</Card.Body>
					</Card>
				</div>
			</Layout.Body>
		</>
	);
};

export default withFormModalSuspenseWrapper<ExpenseCategoryFormProps>({
	loadingLabel: uiText.expenseCategory.LOADING,
	getTitle,
})(ExpenseCategoryForm);
