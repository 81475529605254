import { useApolloClient } from '@apollo/client';
import { Ad_ProcessGetForRunningReportsDocument } from '../graphql/__generated__/graphql';
import useSuspenseAsync from './useSuspenseAsync';

function useSuspenseProcessAccess(processUU: string): boolean;
function useSuspenseProcessAccess(processUUs: string[]): boolean[];
function useSuspenseProcessAccess(processUUOrProcessUUs: string | string[]) {
	const graphqlClient = useApolloClient();
	const { data: processes } = useSuspenseAsync(
		'get-process',
		async () =>
			(await graphqlClient.query({ query: Ad_ProcessGetForRunningReportsDocument, fetchPolicy: 'cache-first' })).data
				.AD_ProcessGet.Results,
	);
	return Array.isArray(processUUOrProcessUUs)
		? processUUOrProcessUUs.map((processUU) => !!processes?.some((process) => process.UU === processUU))
		: !!processes?.some((process) => process.UU === processUUOrProcessUUs);
}

export default useSuspenseProcessAccess;
