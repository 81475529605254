import { createContext } from 'react';
import { Ad_ClientGetQuery, Ad_RoleWithWindowAccessQuery, SignInMutation } from '../graphql/__generated__/graphql';

export type UpdateData = Omit<Partial<UserContextInterface>, 'update' | 'clear'>;

export interface UserContextInterface {
	client: Ad_ClientGetQuery['AD_ClientGet']['Results'][0];
	organization: Ad_ClientGetQuery['AD_ClientGet']['Results'][0]['AD_Orgs'][0];
	role: NonNullable<Ad_RoleWithWindowAccessQuery['AD_Role']>;
	warehouse: NonNullable<Ad_ClientGetQuery['AD_ClientGet']['Results'][0]['AD_Orgs'][0]['M_Warehouses']>[0];
	user: NonNullable<SignInMutation['SignIn']['AD_User']>;
	availableClients: Ad_ClientGetQuery['AD_ClientGet']['Results'];
	update: (newData: UpdateData) => void;
	clear: () => void;
}

const UserContext = createContext<UserContextInterface>({} as UserContextInterface);

export default UserContext;
