import { useApolloClient, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	Bh_ConceptDiagnosisDisplayForVisitsFragmentDoc,
	Bh_ConceptDiagnosisGetForVisitsDocument,
} from '../../graphql/__generated__/graphql';
import { Paging } from '../../models';
import { getCodedDiagnosisDefaultSearchFilter } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import { getCodedDiagnosisName } from '../../utils/ModelUtils';
import EntitySuggestion from '../entity-suggestion/EntitySuggestion';
import { DiagnosisTableFormValues } from './DiagnosisTable';

type DiagnosisTableRowProps = {
	field?: DiagnosisTableFormValues['encounterDiagnoses'][0];
	fieldPrefix: string;
	id: string;
	remove?: (index: number) => void;
	isAddRow?: boolean;
	index?: number;
	readOnly?: boolean;
};

const DiagnosisTableRow = ({
	field,
	fieldPrefix,
	id,
	remove,
	isAddRow = false,
	index = 0,
	readOnly,
}: DiagnosisTableRowProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { register } = useFormContext<DiagnosisTableFormValues>();

	const [onSearchCodedDiagnosis, { data: codedDiagnosisOptions, loading: areLoadingCodedDiagnosis }] = useLazyQuery(
		Bh_ConceptDiagnosisGetForVisitsDocument,
		{ fetchPolicy: 'cache-first' },
	);

	return (
		<tr>
			<td className="align-middle text-center">
				{!isAddRow && <input type="hidden" {...register(`${fieldPrefix}.UU` as 'encounterDiagnoses.0.UU')} />}

				{!isAddRow && (
					<Form.Check
						value={index}
						type="radio"
						{...register('encounterDiagnosesPrimary')}
						defaultChecked={index === 0}
					/>
				)}
			</td>
			<td>
				<EntitySuggestion<
					DiagnosisTableFormValues,
					'encounterDiagnoses.0.BH_Concept',
					'encounterDiagnoses.0.BH_Uncoded_Diagnosis'
				>
					name={`${fieldPrefix}.BH_Concept` as 'encounterDiagnoses.0.BH_Concept'}
					id={id}
					isLoading={areLoadingCodedDiagnosis}
					emptyLabel={t(uiText.codedDiagnosis.NOT_FOUND)}
					labelKey={(data) =>
						getCodedDiagnosisName(
							graphqlClient.readFragment({ id: data.UU, fragment: Bh_ConceptDiagnosisDisplayForVisitsFragmentDoc }),
						)
					}
					placeholder={readOnly ? '' : t(uiText.visit.form.patient.SEARCH_CODED_DIAGNOSIS)}
					promptText={t(uiText.visit.form.patient.SEARCHING)}
					searchText={t(uiText.visit.form.patient.SEARCHING)}
					options={codedDiagnosisOptions?.BH_ConceptGet.Results || []}
					onSearch={(query) =>
						onSearchCodedDiagnosis({
							variables: {
								Page: Paging.ALL.page,
								Size: Paging.ALL.size,
								Filter: getCodedDiagnosisDefaultSearchFilter(query).toString(),
							},
						})
					}
					disabled={readOnly}
					newFieldName={`${fieldPrefix}.BH_Uncoded_Diagnosis` as 'encounterDiagnoses.0.BH_Uncoded_Diagnosis'}
					newSelectionPrefix={t(uiText.visit.form.NON_CODED_DIAGNOSIS_SELECTION_PREFIX)}
					modifyNewSelection={(value) => value + t(uiText.visit.form.NON_CODED_DIAGNOSIS_SELECTION_SUFFIX)}
					unmodifyNewSelection={(value) => value.replace(t(uiText.visit.form.NON_CODED_DIAGNOSIS_SELECTION_SUFFIX), '')}
					defaultValue={(field?.BH_Uncoded_Diagnosis || field?.BH_Concept) as any}
				/>
			</td>
			{!readOnly && (
				<td className="print__d-none align-middle text-center">
					{!isAddRow && (
						<button
							type="button"
							aria-label={t(uiText.visit.form.patient.DELETE_DIAGNOSIS)}
							className="btn p-0 w-100"
							tabIndex={-1}
							id={`${fieldPrefix}.delete`}
							onClick={() => remove && remove(index)}
						>
							<FontAwesomeIcon icon="trash" />
						</button>
					)}
				</td>
			)}
		</tr>
	);
};

export default DiagnosisTableRow;
