import { isApolloError } from '@apollo/client';

export const getErrorMessage = (
	error: { response?: { data?: { message?: string } | string }; message?: string } | string = '',
): string => {
	// check if the error object is a string and return
	if (typeof error === 'string') {
		return error;
	}

	// parse the object
	if (error.response) {
		if (error.response?.data) {
			// check the data object
			if (typeof error.response.data === 'string') {
				return error.response.data.trim() || error.response.data || '';
			} else {
				return error.response.data.message?.split(': ')[1] || '';
			}
		}

		if (error?.message) {
			return error.message || '';
		}
	}

	return '';
};

/**
 * Try to pull the non-Apollo-prefixed data from an error object
 * @param error Any object that we should try and parse
 * @returns An array of prased strings if it can, or an empty string if it can't
 */
export function getGraphQLErrorMessages(error: unknown): string[] {
	if (error instanceof Error || (error instanceof Object && 'message' in error)) {
		let messages: string[] = [];
		if (isApolloError(error)) {
			messages = error.graphQLErrors.map((graphQLError) => graphQLError.message);
		} else {
			messages = [error.message];
		}
		return messages.map((message) => {
			let indexOfApolloSeparator = -1;
			return (indexOfApolloSeparator = message.indexOf(' : ')) > -1
				? message.substring(indexOfApolloSeparator)
				: message;
		});
	}
	return [typeof error === 'string' ? error : ''];
}
