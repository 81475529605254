import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { endOfDay } from 'date-fns';
import { Fragment, useContext, useRef, useState } from 'react';
import { Card, Col, Dropdown, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';
import { v4 } from 'uuid';
import FormModalContext from '../../contexts/FormModalContext';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import UserContext from '../../contexts/UserContext';
import {
	Ad_Ref_ListBusinessPartnerNeedsDocument,
	Ad_Ref_ListGendersForPatientsDocument,
	Ad_SequenceGetDocumentNumberDocument,
	Bh_Bp_General_Payer_InfoDeleteDocument,
	Bh_Bp_General_Payer_InfoSaveManyDocument,
	Bh_Bp_Payer_InfoDeleteDocument,
	Bh_Bp_Payer_InfoSaveManyDocument,
	C_Bp_GroupForPatientsDocument,
	C_BPartnerForPatientEditingDocument,
	C_BPartnerForPatientEditingQuery,
	C_BPartnerPatientSaveDocument,
	C_LocationForOrganizationDocument,
	C_LocationInput,
	C_PaymentTermForBusinessPartnersDocument,
	M_PriceListsForBusinessPartnersDocument,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import {
	BaseEntityDB,
	BusinessPartnerGroupDB,
	businessPartnerGroupName,
	DBFilter,
	LocationDB,
	PriceListDB,
	ReferenceListDB,
	referenceUuids,
} from '../../models';
import EntityFormProperties from '../../types/EntityFormProperties';
import { pageUuid, VISITS_PAGE } from '../../utils/Constants';
import { formatDateAndTime, middleOfToday } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import { getDifference } from '../../utils/ModelUtils';
import BasicButton from '../ActionButtons/BasicButton';
import BHDropdownButton from '../ActionButtons/BHDropdownButton';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { withFormModalSuspenseWrapper } from '../HOCs/withFormModalSuspenseWrapper';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import { setVisitStateForStartingNewVisitWithPatientSelected } from '../Visit/Visits';
import AdditionalInformationForVisit, {
	AdditionalInformationForVisitFormValues,
	constructAdditionalInformationForVisitFormData,
	convertToAdditionalInformationForVisitFormFields,
	primeAdditionalInformationForVisitData,
} from './AdditionalInformationForVisit';

export type PatientFormProps = EntityFormProperties;

export type PatientFormValues = {
	UU: string;
	BH_IsApproximateDateOfBirth: 'true' | 'false';
	BH_Local_PatientID: string | null;
	BH_Birthday: Date | null;
	bh_gender: {
		UU: string;
	};
	bh_occupation: string | null;
	BH_Phone: string | null;
	C_BPartner_Locations: {
		UU: string;
		C_Location: {
			UU: string;
			Address1: string | null;
		};
	};
	Contact: { UU: string };
	IsActive: boolean;
	Name: string;
	NationalID: string | null;
	NextOfKin_Contact: string | null;
	NextOfKin_Name: string | null;
	approxMonths: number | null;
	approxYears: number | null;
	calculatedIsDateOfBirth: 'true' | 'false' | '';
	submitEvent: 'saveAndNewVisit' | 'saveAndNew' | '';
} & AdditionalInformationForVisitFormValues;

const patientBusinessPartnerGroupFilter = () =>
	DBFilter<BusinessPartnerGroupDB>().property('name').equals(businessPartnerGroupName.PATIENTS);
const getTitle = (uuid?: string) => (uuid ? uiText.patient.title.UPDATE : uiText.patient.title.NEW);
const convertToFormFields: (initialData?: C_BPartnerForPatientEditingQuery['C_BPartner']) => PatientFormValues = (
	initialData,
) => {
	if (!initialData) {
		return {
			UU: v4(),
			BH_Local_PatientID: null,
			BH_IsApproximateDateOfBirth: 'false',
			BH_Birthday: null,
			bh_gender: {
				UU: '',
			},
			bh_occupation: null,
			BH_Phone: null,
			C_BPartner_Locations: {
				UU: v4(),
				C_Location: {
					UU: v4(),
					Address1: null,
				},
			},
			Contact: { UU: v4() },
			IsActive: true,
			Name: '',
			NationalID: null,
			NextOfKin_Contact: null,
			NextOfKin_Name: null,
			approxMonths: null,
			approxYears: null,
			calculatedIsDateOfBirth: '',
			submitEvent: '',
			...convertToAdditionalInformationForVisitFormFields(initialData),
		};
	}
	let approximateAge: number[] = [];
	if (initialData?.BH_Birthday && initialData.BH_IsApproximateDateOfBirth) {
		const birthday = new Date(initialData.BH_Birthday);
		approximateAge[0] = (middleOfToday().getMonth() - birthday.getMonth() + 12) % 12;
		approximateAge[1] =
			middleOfToday().getMonth() < birthday.getMonth()
				? middleOfToday().getFullYear() - birthday.getFullYear() - 1
				: middleOfToday().getFullYear() - birthday.getFullYear();
	}
	return {
		UU: initialData.UU,
		BH_Local_PatientID: initialData.BH_Local_PatientID || null,
		BH_IsApproximateDateOfBirth: initialData.BH_IsApproximateDateOfBirth ? 'true' : 'false',
		BH_Birthday: initialData.BH_Birthday ? new Date(initialData.BH_Birthday) : null,
		bh_gender: {
			UU: initialData.bh_gender?.UU || '',
		},
		bh_occupation: initialData.bh_occupation || null,
		BH_Phone: initialData.BH_Phone || null,
		C_BPartner_Locations: {
			UU: initialData.C_BPartner_Locations?.[0].UU || v4(),
			C_Location: {
				UU: initialData.C_BPartner_Locations?.[0].C_Location.UU || v4(),
				Address1: initialData.C_BPartner_Locations?.[0].C_Location.Address1 || null,
			},
		},
		Contact: { UU: initialData.Contacts?.[0]?.UU || v4() },
		IsActive: initialData.IsActive,
		Name: initialData.Name,
		NationalID: initialData.NationalID || null,
		NextOfKin_Contact: initialData.NextOfKin_Contact || null,
		NextOfKin_Name: initialData.NextOfKin_Name || null,
		approxMonths: approximateAge[0],
		approxYears: approximateAge[1],
		calculatedIsDateOfBirth: initialData.BH_IsApproximateDateOfBirth ? 'true' : initialData.BH_Birthday ? 'false' : '',
		submitEvent: '',
		...convertToAdditionalInformationForVisitFormFields(initialData),
	};
};

const PatientForm = ({ uuid, onFinish, renderAsModal, canSaveMany }: PatientFormProps) => {
	const graphqlClient = useApolloClient();
	const { organization } = useContext(UserContext);
	const {
		data: [
			data,
			patientBusinessPartnerGroup,
			genders,
			locationForOrganization,
			businessPartnerReferenceLists,
			paymentTerms,
			priceLists,
		] = [],
	} = useSuspenseAsync(uuid || 'add-business-partner', async () =>
		Promise.all([
			uuid
				? graphqlClient
						.query({
							query: C_BPartnerForPatientEditingDocument,
							variables: { UU: uuid },
							fetchPolicy: 'network-only',
						})
						.then((response) => response.data.C_BPartner)
				: undefined,
			graphqlClient
				.query({
					query: C_Bp_GroupForPatientsDocument,
					variables: { Filter: patientBusinessPartnerGroupFilter().toString() },
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.C_BP_GroupGet.Results[0]),
			graphqlClient
				.query({
					query: Ad_Ref_ListGendersForPatientsDocument,
					variables: {
						Filter: DBFilter<ReferenceListDB>()
							.nested('ad_reference')
							.property('ad_reference_uu')
							.equals(referenceUuids.GENDER)
							.up()
							.toString(),
					},
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.AD_Ref_ListGet.Results),
			graphqlClient
				.query({
					query: C_LocationForOrganizationDocument,
					variables: {
						Filter: DBFilter<LocationDB>()
							.nested('ad_orginfo')
							.nested('ad_org')
							.property('ad_org_uu')
							.equals(organization.UU)
							.up()
							.up()
							.toString(),
					},
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.C_LocationGet.Results),
			graphqlClient
				.query({
					query: Ad_Ref_ListBusinessPartnerNeedsDocument,
					variables: {
						Filter: DBFilter<ReferenceListDB>()
							.nested('ad_reference')
							.property('ad_reference_uu')
							.isIn([
								referenceUuids.GENDER,
								referenceUuids.BUSINESS_PARTNER_SALES_ORDER_CREDIT_STATUS,
								referenceUuids.ORDER_INVOICE_RULES,
								referenceUuids.PAYMENT_TYPES,
								referenceUuids.USER_NOTIFICATION_TYPES,
							])
							.up()
							.toString(),
					},
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.AD_Ref_ListGet.Results),
			graphqlClient
				.query({
					query: C_PaymentTermForBusinessPartnersDocument,
					variables: { Size: 1, Filter: DBFilter<BaseEntityDB>().property('name').equals('Immediate').toString() },
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.C_PaymentTermGet.Results),
			graphqlClient
				.query({
					query: M_PriceListsForBusinessPartnersDocument,
					variables: {
						Sort: JSON.stringify([['created', 'desc']]),
						Filter: DBFilter<PriceListDB>()
							.property('isdefault')
							.equals(true)
							.property('isactive')
							.equals(true)
							.toString(),
					},
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.M_PriceListGet.Results),
			primeAdditionalInformationForVisitData(graphqlClient),
		]),
	);

	const { t } = useTranslation();
	const { disableWrite, disableDeactivate } = useActionPrivileges(pageUuid.PATIENTS);
	const { toggleNavBlocking } = useContext(NavBlockingContext);
	const history = useHistory();

	const canCreateVisit = useActionPrivileges(pageUuid.VISITS).canWrite;

	const [dataToUse, setDataToUse] = useState(data);
	const title = getTitle(dataToUse ? dataToUse.UU : undefined);

	const formMethods = useForm<PatientFormValues>({
		defaultValues: convertToFormFields(dataToUse),
	});

	const [isDateOfBirthDisabled, setIsDateOfBirthDisabled] = useState(
		formMethods.getValues('calculatedIsDateOfBirth') === 'true',
	);
	const [isApproximateAgeDisabled, setIsApproximateAgeDisabled] = useState(
		formMethods.getValues('calculatedIsDateOfBirth') === 'false',
	);

	const isUserInteractingWithApproximateYearsField = useRef(
		formMethods.getValues('calculatedIsDateOfBirth') === 'true',
	);
	const isUserInteractingWithApproximateMonthsField = useRef(
		formMethods.getValues('calculatedIsDateOfBirth') === 'true',
	);
	const isUserInteractingWithDateOfBirthField = useRef(formMethods.getValues('calculatedIsDateOfBirth') === 'false');

	const approximateYears = formMethods.watch('approxYears');
	const approximateMonths = formMethods.watch('approxMonths');
	const dateOfBirth = formMethods.watch('BH_Birthday');

	const { setValue } = formMethods;

	const dateOfBirthTime = dateOfBirth?.getTime();

	const { onChange: onApproxYearsChange, ...restOfApproximateYearsFields } = formMethods.register('approxYears', {
		valueAsNumber: true,
	});
	const { onChange: onApproxMonthsChange, ...restOfApproximateMonthsFields } = formMethods.register('approxMonths', {
		valueAsNumber: true,
	});

	useUpdateEffect(() => {
		if (
			isUserInteractingWithDateOfBirthField.current &&
			!isUserInteractingWithApproximateYearsField.current &&
			!isUserInteractingWithApproximateMonthsField.current
		) {
			setValue('BH_IsApproximateDateOfBirth', 'false');
			setIsApproximateAgeDisabled(true);
			setIsDateOfBirthDisabled(false);
			setValue('BH_Birthday', dateOfBirthTime ? new Date(dateOfBirthTime) : middleOfToday());
		} else if (
			!isUserInteractingWithDateOfBirthField.current &&
			(isUserInteractingWithApproximateYearsField.current || isUserInteractingWithApproximateMonthsField.current) &&
			(dateOfBirthTime || approximateMonths || approximateYears)
		) {
			setValue('BH_IsApproximateDateOfBirth', 'true');
			setIsDateOfBirthDisabled(true);
			setIsApproximateAgeDisabled(false);

			const yearsToMonths = (approximateYears || 0) * 12;
			const ageInMonths = yearsToMonths + (approximateMonths || 0);
			const dateNow = middleOfToday();
			dateNow.setDate(1);
			const dob = new Date(dateNow.setMonth(dateNow.getMonth() - ageInMonths));
			setValue('BH_Birthday', dob);
		} else {
			setIsDateOfBirthDisabled(false);
			setIsApproximateAgeDisabled(false);
			setValue('BH_IsApproximateDateOfBirth', 'false');
			setValue('BH_Birthday', null);
		}
	}, [setValue, dateOfBirthTime, approximateMonths, approximateYears]);

	useConfirmRefresh(formMethods?.formState?.isDirty === true);
	const { dataWasSaved, savedData, wasDataSaved } = useContext(FormModalContext);

	const [{ loading: areSavingPatient }, onSubmit] = useCustomAsyncFn<SubmitHandler<PatientFormValues>>(
		async (formData) => {
			const submitName = formData.submitEvent;

			let immediateOrderInvoiceRule = businessPartnerReferenceLists?.find(
				(referenceList) =>
					referenceList.AD_Reference.UU === referenceUuids.ORDER_INVOICE_RULES && referenceList.Value === 'I', // Immediate
			);
			let directDepositPaymentRule = businessPartnerReferenceLists?.find(
				(referenceList) =>
					referenceList.AD_Reference.UU === referenceUuids.PAYMENT_TYPES && referenceList.Value === 'T', // Direct Deposit
			);
			let onCreditPaymentRule = businessPartnerReferenceLists?.find(
				(referenceList) =>
					referenceList.AD_Reference.UU === referenceUuids.PAYMENT_TYPES && referenceList.Value === 'P', // On Credit
			);
			let salesOrderNoCreditCheck = businessPartnerReferenceLists?.find(
				(referenceList) =>
					referenceList.AD_Reference.UU === referenceUuids.BUSINESS_PARTNER_SALES_ORDER_CREDIT_STATUS &&
					referenceList.Value === 'X', // No Credit Check
			);
			let noNotification = businessPartnerReferenceLists?.find(
				(referenceList) =>
					referenceList.AD_Reference.UU === referenceUuids.USER_NOTIFICATION_TYPES && referenceList.Value === 'X', // No Notification
			);
			let purchasePriceListUU =
				patientBusinessPartnerGroup?.PO_PriceList?.UU || priceLists?.find((priceList) => !priceList.IsSOPriceList)?.UU;
			let salesPriceListUU =
				patientBusinessPartnerGroup?.M_PriceList?.UU || priceLists?.find((priceList) => priceList.IsSOPriceList)?.UU;

			// Get the right location to use
			let organizationLocation = locationForOrganization?.[0];
			let locationFilter = DBFilter<LocationDB>();
			if (organizationLocation?.C_Country.UU) {
				locationFilter = locationFilter
					.nested('c_country')
					.property('c_country_uu')
					.equals(organizationLocation.C_Country.UU)
					.up();
			}
			if (organizationLocation?.C_Region?.UU) {
				locationFilter = locationFilter
					.nested('c_region')
					.property('c_region_uu')
					.equals(organizationLocation.C_Region.UU)
					.up();
			}
			if (formData.C_BPartner_Locations.C_Location.Address1) {
				locationFilter.property('address1').equals(formData.C_BPartner_Locations.C_Location.Address1);
			} else {
				locationFilter.property('address1').isNull();
			}
			let location: C_LocationInput = {
				UU: (
					await graphqlClient.query({
						query: C_LocationForOrganizationDocument,
						variables: { Size: 1, Filter: locationFilter.toString() },
						fetchPolicy: 'network-only',
					})
				).data.C_LocationGet.Results[0]?.UU,
			};
			// If we don't have anything, make a new one
			if (!location.UU) {
				location.UU = v4();
				location.C_Country = organizationLocation?.C_Country.UU ? { UU: organizationLocation.C_Country.UU } : undefined;
				location.C_Region = organizationLocation?.C_Region?.UU ? { UU: organizationLocation.C_Region.UU } : undefined;
				location.Address1 = formData.C_BPartner_Locations.C_Location.Address1;
			}

			try {
				await graphqlClient.mutate({
					mutation: C_BPartnerPatientSaveDocument,
					variables: {
						C_BPartner: {
							UU: formData.UU,
							BH_Birthday: formData.BH_Birthday?.getTime(),
							BH_IsApproximateDateOfBirth: formData.BH_IsApproximateDateOfBirth === 'true',
							BH_Local_PatientID: formData.BH_Local_PatientID,
							BH_NeedAdditionalVisitInfo: false,
							bh_occupation: formData.bh_occupation,
							BH_PatientID: dataToUse
								? dataToUse.BH_PatientID
								: (
										await graphqlClient.mutate({
											mutation: Ad_SequenceGetDocumentNumberDocument,
											variables: { TableName: 'BH_PatientID_C_BPartner' },
										})
									).data?.AD_SequenceGetDocumentNumber,
							BH_Phone: formData.BH_Phone,
							bh_gender: formData.bh_gender.UU ? { UU: formData.bh_gender.UU } : undefined,
							C_BP_Group: patientBusinessPartnerGroup?.UU ? { UU: patientBusinessPartnerGroup.UU } : undefined,
							C_PaymentTerm: paymentTerms?.[0]?.UU ? { UU: paymentTerms[0].UU } : undefined,
							InvoiceRule: immediateOrderInvoiceRule ? { UU: immediateOrderInvoiceRule.UU } : undefined,
							IsActive: formData.IsActive,
							IsCustomer: true,
							IsVendor: false,
							M_PriceList: salesPriceListUU ? { UU: salesPriceListUU } : undefined,
							Name: formData.Name,
							NationalID: formData.NationalID,
							NextOfKin_Contact: formData.NextOfKin_Contact,
							NextOfKin_Name: formData.NextOfKin_Name,
							PaymentRule: onCreditPaymentRule ? { UU: onCreditPaymentRule.UU } : undefined,
							PaymentRulePO: directDepositPaymentRule ? { UU: directDepositPaymentRule.UU } : undefined,
							PO_PaymentTerm: paymentTerms?.[0]?.UU ? { UU: paymentTerms[0].UU } : undefined,
							PO_PriceList: purchasePriceListUU ? { UU: purchasePriceListUU } : undefined,
							SOCreditStatus: salesOrderNoCreditCheck ? { UU: salesOrderNoCreditCheck.UU } : undefined,
						},
						C_Location: location,
						C_BPartner_Location: {
							UU: dataToUse?.C_BPartner_Locations?.[0]?.UU || formData.C_BPartner_Locations.UU,
							C_BPartner: { UU: formData.UU },
							C_Location: { UU: location.UU! },
							// Only update the name if it's a new one
							Name: dataToUse?.C_BPartner_Locations?.[0]?.UU ? undefined : formData.Name + ' Location',
						},
						AD_User: {
							UU: formData.Contact.UU,
							C_BPartner: { UU: formData.UU },
							IsFullBPAccess: false,
							IsActive: true,
							Name: formData.Name,
							NotificationType: noNotification?.UU ? { UU: noNotification.UU } : undefined,
						},
					},
				});

				const [payerInformationListToSave, generalPayerInformationListToSave] =
					constructAdditionalInformationForVisitFormData(formData);
				const [newPayerInformation, removedPayerInformation, updatedPayerInformation] = getDifference(
					dataToUse?.BH_BP_Payer_Info_BPList || [],
					payerInformationListToSave,
					(generalPayerInformationA, generalPayerInformationB) =>
						generalPayerInformationA.UU === generalPayerInformationB.UU,
					(generalPayerInformationA, generalPayerInformationB) =>
						generalPayerInformationA.BH_Payer.UU === generalPayerInformationB.BH_Payer?.UU,
				);
				const [newGeneralPayerInformation, removedGeneralPayerInformation, updatedGeneralPayerInformation] =
					getDifference(
						dataToUse?.BH_BP_Payer_Info_BPList?.flatMap(
							(payerInformation) => payerInformation.BH_BP_General_Payer_InfoList || [],
						).filter((payerInformation) => !!payerInformation) || [],
						generalPayerInformationListToSave,
						(generalPayerInformationA, generalPayerInformationB) =>
							generalPayerInformationA.UU === generalPayerInformationB.UU,
						(generalPayerInformationA, generalPayerInformationB) =>
							generalPayerInformationA.BH_Payer_Info_Fld.UU === generalPayerInformationB.BH_Payer_Info_Fld?.UU &&
							generalPayerInformationA.BH_BP_Payer_Info.UU === generalPayerInformationB.BH_BP_Payer_Info?.UU &&
							generalPayerInformationA.Name === generalPayerInformationB.Name,
					);
				// To ensure proper update order and assignment, do the following:
				// 1. Add new & update existing payer information
				// 2. Add new & update existing general payer information
				// 3. Removed deleted general payer information
				// 4. Removed deleted payer information
				let payerInformationMutationPromises: Promise<unknown>[] = [];
				if (newPayerInformation.length || updatedPayerInformation.length) {
					payerInformationMutationPromises.push(
						graphqlClient.mutate({
							mutation: Bh_Bp_Payer_InfoSaveManyDocument,
							variables: {
								BH_BP_Payer_InfoList: [...newPayerInformation, ...updatedPayerInformation].filter(
									(payerInformation) => !!payerInformation,
								),
							},
						}),
					);
				}
				if (newGeneralPayerInformation.length || updatedGeneralPayerInformation.length) {
					payerInformationMutationPromises.push(
						graphqlClient.mutate({
							mutation: Bh_Bp_General_Payer_InfoSaveManyDocument,
							variables: {
								BH_BP_General_Payer_InfoList: [...newGeneralPayerInformation, ...updatedGeneralPayerInformation].filter(
									(generalPayerInformation) => !!generalPayerInformation,
								),
							},
						}),
					);
				}
				if (removedGeneralPayerInformation.length) {
					payerInformationMutationPromises.push(
						graphqlClient.mutate({
							mutation: Bh_Bp_General_Payer_InfoDeleteDocument,
							variables: {
								BH_BP_General_Payer_InfoUUs: removedGeneralPayerInformation.map(
									(generalPayerInformation) => generalPayerInformation.UU,
								),
							},
						}),
					);
				}
				if (removedPayerInformation.length) {
					payerInformationMutationPromises.push(
						graphqlClient.mutate({
							mutation: Bh_Bp_Payer_InfoDeleteDocument,
							variables: {
								BH_BP_Payer_InfoUUs: removedPayerInformation.map((payerInformation) => payerInformation.UU),
							},
						}),
					);
				}
				await Promise.all(payerInformationMutationPromises);

				toggleNavBlocking(false);

				const isNewPatient = !dataToUse;

				switch (submitName) {
					case 'saveAndNew':
						setDataToUse(undefined);
						formMethods.reset(convertToFormFields());
						isUserInteractingWithApproximateYearsField.current = false;
						isUserInteractingWithApproximateMonthsField.current = false;
						isUserInteractingWithDateOfBirthField.current = false;
						dataWasSaved(formData.UU);
						if (isNewPatient) {
							toast.success(t(uiText.patient.success.REGISTERED));
						}
						break;
					case 'saveAndNewVisit':
						// refetch the patient for the cache on the visits page
						await graphqlClient.query({
							query: C_BPartnerForPatientEditingDocument,
							variables: { UU: formData.UU },
							fetchPolicy: 'network-only',
						});
						history.push({
							pathname: VISITS_PAGE,
							state: setVisitStateForStartingNewVisitWithPatientSelected(formData.UU),
						});
						break;
					default:
						// refetch the patient for the cache on the visits page
						await graphqlClient.query({
							query: C_BPartnerForPatientEditingDocument,
							variables: { UU: formData.UU },
							fetchPolicy: 'network-only',
						});
						onFinish(true, canSaveMany === false ? formData.UU : undefined);
						break;
				}
			} catch (error) {}
		},
		[
			patientBusinessPartnerGroup,
			canSaveMany,
			dataWasSaved,
			graphqlClient,
			dataToUse,
			businessPartnerReferenceLists,
			paymentTerms,
			priceLists,
		],
	);

	const inputs = (
		<FormProvider {...formMethods}>
			<Form onSubmit={formMethods.handleSubmit(onSubmit)} className="px-0" autoComplete="off">
				<Card className="bh-card mx-n2_5 my-0 rounded-0 border-0 px-4">
					<Card.Body hidden={!dataToUse}>
						<Row className="gy-3">
							<input type="hidden" {...formMethods.register('UU')} />
							<input type="hidden" {...formMethods.register('Contact.UU')} />
							<Form.Group as={Fragment} controlId="patientNumber">
								<Col xs={1} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.patient.patientNumber.BANDA)}</Form.Label>
								</Col>
								<Col xs={5} className="d-flex align-items-center">
									<Form.Control defaultValue={dataToUse?.BH_PatientID || ''} readOnly={true} />
								</Col>
							</Form.Group>
							<Form.Group as={Fragment} controlId="totalOpenBalance">
								<Col xs={1} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.patient.OPEN_BALANCE)}</Form.Label>
								</Col>
								<Col xs={5} className="d-flex align-items-center">
									<FormatNumberInput className="text-start" readOnly={true} value={dataToUse?.TotalOpenBalance || 0} />
								</Col>
							</Form.Group>
							<Form.Group as={Fragment} controlId="visitsCount">
								<Col xs={1} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.patient.visit.PREVIOUS)}</Form.Label>
								</Col>
								<Col xs={5} className="d-flex align-items-center">
									<Form.Control readOnly={true} defaultValue={dataToUse?.TotalVisits || 0} />
								</Col>
							</Form.Group>
							<Form.Group as={Fragment} controlId="lastVisitDate">
								<Col xs={1} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.patient.visit.LAST)}</Form.Label>
								</Col>
								<Col xs={5} className="d-flex align-items-center">
									<Form.Control
										readOnly={true}
										defaultValue={dataToUse?.LastVisitDate ? formatDateAndTime(new Date(dataToUse.LastVisitDate)) : ''}
									/>
								</Col>
							</Form.Group>
						</Row>
					</Card.Body>
				</Card>
				<Tabs defaultActiveKey="generalInfo" className={`pt-2 ${renderAsModal ? 'mx-n3' : 'mx-n2_5'}`}>
					<Tab eventKey="generalInfo" title={t(uiText.patient.tabs.GENERAL_INFO)} className="px-2_5">
						<fieldset disabled={disableWrite}>
							<Card className="bh-card">
								<Card.Body>
									<Row className="gy-3">
										<Form.Group as={Fragment} controlId="name">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.fullName.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.fullName.PLACEHOLDER)}
													{...formMethods.register('Name', { required: true })}
												/>
												{formMethods.formState.errors.Name && (
													<span className="text-danger">{t(uiText.patient.fullName.VALIDATION)}</span>
												)}
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="gender">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.gender.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Select {...formMethods.register('bh_gender.UU')}>
													<option></option>
													{genders?.map((gender) => (
														<option value={gender.UU} key={gender.UU}>
															{gender.Name}
														</option>
													))}
												</Form.Select>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="dateOfBirth">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.age.DATE_OF_BIRTH)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Controller<PatientFormValues, 'BH_Birthday'>
													name="BH_Birthday"
													render={({ field }) => (
														<BandaDatePicker
															disabled={isDateOfBirthDisabled}
															maxDate={endOfDay(new Date())}
															{...field}
															value={undefined}
															onChange={(selectedDate) => {
																isUserInteractingWithDateOfBirthField.current =
																	selectedDate !== null && !isDateOfBirthDisabled;
																field.onChange(selectedDate);
															}}
															selected={field.value}
														/>
													)}
												/>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="patientAge">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.patientAge.YEARS_OR_MONTHS)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													aria-label={t(uiText.patient.patientAge.YEARS)}
													type="number"
													disabled={isApproximateAgeDisabled}
													{...restOfApproximateYearsFields}
													onChange={(event) => {
														isUserInteractingWithApproximateYearsField.current = event.target.value !== '';
														onApproxYearsChange(event);
													}}
												/>
												<label className="mx-1">/</label>
												<Form.Control
													aria-label={t(uiText.patient.patientAge.MONTHS)}
													type="number"
													disabled={isApproximateAgeDisabled}
													{...restOfApproximateMonthsFields}
													onChange={(event) => {
														isUserInteractingWithApproximateMonthsField.current = event.target.value !== '';
														onApproxMonthsChange(event);
													}}
												/>
												<input type="hidden" {...formMethods.register('BH_IsApproximateDateOfBirth')} />
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="phone">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.phone.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.phone.PLACEHOLDER)}
													{...formMethods.register('BH_Phone')}
												/>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="address">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.address.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.address.PLACEHOLDER)}
													{...formMethods.register('C_BPartner_Locations.C_Location.Address1')}
												/>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="isActive">
											<Col xs={{ span: 5, offset: 1 }} className="d-flex align-items-center">
												<Form.Check
													{...formMethods.register('IsActive')}
													disabled={disableDeactivate}
													label={t(uiText.patient.button.ACTIVE)}
												/>
											</Col>
										</Form.Group>
									</Row>
								</Card.Body>
							</Card>
							<Card className="bh-card">
								<Card.Header className="fw-bold h5">{t(uiText.patient.title.ADDITIONAL_INFO)}</Card.Header>
								<Card.Body>
									<Row className="gy-3">
										<Form.Group as={Fragment} controlId="localPatientNumber">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.patientNumber.LOCAL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.patientNumber.ENTER_LOCAL)}
													{...formMethods.register('BH_Local_PatientID')}
												/>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="nationalId">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.nationalId.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.nationalId.PLACEHOLDER)}
													{...formMethods.register('NationalID')}
												/>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="occupation">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.occupation.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.occupation.PLACEHOLDER)}
													{...formMethods.register('bh_occupation')}
												/>
											</Col>
										</Form.Group>
										<Col xs={6} />
										<Form.Group as={Fragment} controlId="nextOfKinName">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.nextOfKin.name.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.nextOfKin.name.PLACEHOLDER)}
													{...formMethods.register('NextOfKin_Name')}
												/>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="nextOfKinContact">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.nextOfKin.contact.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.nextOfKin.contact.PLACEHOLDER)}
													{...formMethods.register('NextOfKin_Contact')}
												/>
											</Col>
										</Form.Group>
									</Row>
								</Card.Body>
							</Card>
						</fieldset>
					</Tab>
					<Tab
						eventKey="insurancePaymentInfo"
						title={t(uiText.patient.tabs.INSURANCE_AND_PAYMENT_INFO)}
						className="px-2_5"
					>
						<AdditionalInformationForVisit />
					</Tab>
				</Tabs>
			</Form>
		</FormProvider>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'm-4 ms-3'}`}>
			{disableWrite ? (
				<Col xs="auto">
					<BasicButton
						name={uiText.supplier.button.BACK}
						text={t(uiText.supplier.button.BACK)}
						variant="danger"
						icon="arrow-left"
						active={true}
						onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
					/>
				</Col>
			) : (
				<>
					<Col xs="auto">
						<BasicButton
							name={uiText.patient.button.CANCEL}
							text={t(uiText.patient.button.CANCEL)}
							variant="danger"
							icon="times"
							active={true}
							onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
						/>
					</Col>
					<Col xs="auto" className="ms-auto">
						<input type="hidden" {...formMethods.register('submitEvent')} defaultValue={''} />
						<BHDropdownButton title={t(uiText.patient.button.SAVE)} variant="success" icon="check">
							<Dropdown.Item onClick={formMethods.handleSubmit(onSubmit)}>
								<FontAwesomeIcon icon="save" className="me-2 fa-fw" />
								{t(uiText.patient.button.SAVE_AND_CLOSE)}
							</Dropdown.Item>
							{canSaveMany !== false ? (
								<Dropdown.Item
									onClick={(e) => {
										formMethods.setValue('submitEvent', 'saveAndNew');
										formMethods.handleSubmit(onSubmit)(e);
									}}
								>
									<FontAwesomeIcon icon="paste" className="me-2 fa-fw" />
									{t(uiText.patient.button.SAVE_AND_NEW_PATIENT)}
								</Dropdown.Item>
							) : null}
							{!renderAsModal && canCreateVisit ? (
								<Dropdown.Item
									onClick={(e) => {
										formMethods.setValue('submitEvent', 'saveAndNewVisit');
										formMethods.handleSubmit(onSubmit)(e);
									}}
								>
									<FontAwesomeIcon icon="file" className="me-2 fa-fw" />
									{t(uiText.patient.button.SAVE_AND_NEW_VISIT)}
								</Dropdown.Item>
							) : null}
						</BHDropdownButton>
					</Col>
				</>
			)}
		</Row>
	);

	const shouldShowProcessingIndicator = areSavingPatient;

	return renderAsModal ? (
		<>
			<Modal.Header closeButton>
				<Modal.Title>{t(title)}</Modal.Title>
			</Modal.Header>
			{shouldShowProcessingIndicator && <LoadSpinner inline title={t(uiText.patient.PROCESSING)} />}
			<Modal.Body hidden={shouldShowProcessingIndicator} className={`${shouldShowProcessingIndicator ? '' : 'pt-0'}`}>
				{inputs}
			</Modal.Body>
			{!shouldShowProcessingIndicator ? (
				<Modal.Footer>
					<div className="w-100">{buttons}</div>
				</Modal.Footer>
			) : null}
		</>
	) : (
		<>
			<Layout.Header>
				<Layout.Title title={t(title)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				{shouldShowProcessingIndicator && <LoadSpinner title={t(uiText.patient.PROCESSING)} />}
				<div hidden={shouldShowProcessingIndicator} className="bg-white pb-0_5 me-n2_5">
					{inputs}
					{buttons}
				</div>
			</Layout.Body>
		</>
	);
};

export default withFormModalSuspenseWrapper<PatientFormProps>({ loadingLabel: uiText.patient.LOADING, getTitle })(
	PatientForm,
);
