import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { BaseEntityDB } from './BaseEntity';
import { TreeNodeMMDB } from './TreeNodeMM';

export const menuUuid = {
	REPORTS_DROPDOWN: '35ce7d6a-cf7d-4962-a748-75e27d0121bf',
	GREENLIGHT_MAIN_MENU: 'bb0670c5-0dc1-468a-8b85-a91b15407368',
} as const;

export interface MenuDB extends BaseEntityDB {
	ad_menu_uu: string;
	'ad_treenodemm::ad_menu_id->node_id': TreeNodeMMDB;
}

export const getMenuClassName = (menu?: { IconClassName?: string | null } | null): IconName | undefined => {
	return (menu?.IconClassName?.split(' ')[1].replace(/fa-/g, '') as IconName) || undefined;
};

export const getMenuBrand = (menu?: { IconClassName?: string | null } | null): IconPrefix => {
	return menu?.IconClassName?.split(' ')[0].replace('r', '') as IconPrefix;
};
