import { BaseMetadataDB } from './BaseMetadata';
import { LocatorDB } from './Locator';
import { ProductDB } from './Product';

export enum TransactionType {
	DRAFTED = 'Drafted',
	INITIAL_INVENTORY = 'Initial Inventory',
	MANUAL_INVENTORY_ADJUSTMENT = 'Manual Inventory Adjustment',
	OTC_SALE = 'OTC Sale',
	PATIENT_RETURNS = 'Customer Returns',
	PATIENT_SALE = 'Patient Sale',
	PRODUCT_RECEIVED = 'Product Received',
	REACTIVATE_PATIENT_SALE = 'Reactivate Patient Sale',
	REACTIVATED_PRODUCT_RECEIPT = 'Reactivated Product Receipt',
	SUPPLIER_RETURNS = 'Vendor Returns',
	TRANSFER_IN = 'Transfer In',
	TRANSFER_OUT = 'Transfer Out',
	UNKNOWN_STATUS = 'Unknown Status',
	VOID_PATIENT_SALE = 'Void Patient Sale',
	VOID_PRODUCT_RECEIPT = 'Void Product Receipt',
}

export interface InventoryTransactionDB extends BaseMetadataDB {
	m_product: ProductDB;
	m_locator: LocatorDB;
}
