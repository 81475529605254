import { useQuery } from '@apollo/client';
import { Badge } from 'react-bootstrap';
import {
	Ad_Ref_List,
	Ad_Ref_ListDocumentStatusesDocument,
	Ad_Ref_ListDocumentStatusesQuery,
} from '../../graphql/__generated__/graphql';
import { DBFilter, ReferenceListDB, referenceUuids } from '../../models';
import DocumentStatus, { DocumentStatusValue } from '../../models/DocumentStatus';
import { isStatusACompletedStatus, isStatusADraftedStatus, isStatusAVoidedStatus } from '../../utils/StatusUtil';

type StatusBadgeProps = { documentStatus?: Pick<Ad_Ref_List, 'Name' | 'Value'> };

const StatusBadge = (props?: StatusBadgeProps | null) => {
	const { data } = useQuery(Ad_Ref_ListDocumentStatusesDocument, {
		variables: {
			Filter: DBFilter<ReferenceListDB>()
				.nested('ad_reference')
				.property('ad_reference_uu')
				.equals(referenceUuids.DOCUMENT_STATUSES)
				.up()
				.toString(),
		},
		fetchPolicy: 'cache-first',
	});

	let unknownDocumentStatus = data?.AD_Ref_ListGet.Results.find(
		(documentStatus) => documentStatus.Value === DocumentStatus.UNKNOWN,
	);
	let mappedDocumentStatus: Ad_Ref_ListDocumentStatusesQuery['AD_Ref_ListGet']['Results'][0] | undefined;
	if (data && props?.documentStatus) {
		if (isStatusADraftedStatus(props.documentStatus.Value as DocumentStatusValue)) {
			mappedDocumentStatus = data.AD_Ref_ListGet.Results.find(
				(documentStatus) => documentStatus.Value === DocumentStatus.DRAFTED,
			);
		} else if (isStatusACompletedStatus(props.documentStatus.Value as DocumentStatusValue)) {
			mappedDocumentStatus = data.AD_Ref_ListGet.Results.find(
				(documentStatus) => documentStatus.Value === DocumentStatus.COMPLETED,
			);
		} else if (isStatusAVoidedStatus(props.documentStatus.Value as DocumentStatusValue)) {
			mappedDocumentStatus = data.AD_Ref_ListGet.Results.find(
				(documentStatus) => documentStatus.Value === DocumentStatus.VOIDED,
			);
		}
	}

	let documentStatusToUse = mappedDocumentStatus || unknownDocumentStatus;
	let badgeState = '';
	switch (documentStatusToUse?.Value) {
		case DocumentStatus.COMPLETED:
			badgeState = 'success';
			break;
		case DocumentStatus.DRAFTED:
			badgeState = 'primary';
			break;
		case DocumentStatus.IN_PROGRESS:
			badgeState = 'dark';
			break;
		case DocumentStatus.VOIDED:
			badgeState = 'secondary';
			break;
		default:
			badgeState = 'info';
			break;
	}

	return <Badge bg={badgeState}>{documentStatusToUse?.Name || ''}</Badge>;
};

export default StatusBadge;
