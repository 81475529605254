import { useApolloClient } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import { Ad_UserSaveOnListOrFormDocument } from '../../graphql/__generated__/graphql';
import { uiText } from '../../utils/Language';
import ToSContent from './ToSContent';

const TermsOfService = () => {
	const { t } = useTranslation(['translation', 'termsOfService']);
	const { user } = useContext(UserContext);
	const graphqlClient = useApolloClient();
	const [initialRender, setInitialRender] = useState(true);
	const [haveAcceptedTermsOfUse, setHaveAcceptedTermsOfUse] = useState(false);
	const [additionalButtonClass, setAdditionalButtonClass] = useState('dark');
	const tosDiv = React.createRef<HTMLDivElement>();
	const marginForScrolling = 100;

	const activateButtonsAfterCompleteToSRead = () => {
		if (
			tosDiv.current &&
			tosDiv.current.scrollTop + tosDiv.current.clientHeight >= tosDiv.current.scrollHeight - marginForScrolling
		) {
			setAdditionalButtonClass('info');
		}
	};

	const getOnSubmit = (didAcceptTerms: boolean) => async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		// We won't wait on this and will assume it was successful (if not, they'll do it again next time)
		graphqlClient.mutate({
			mutation: Ad_UserSaveOnListOrFormDocument,
			variables: { AD_User: { UU: user.UU, BH_HasAcceptedTermsOfUse: didAcceptTerms } },
		});
		setHaveAcceptedTermsOfUse(didAcceptTerms);
		setInitialRender(false);
	};

	return initialRender ? (
		<div className="mt-3">
			<h5>Banda Health</h5>
			<Card
				className="vw-75 vh-75 my-2 mx-auto overflow-auto mt-3"
				onScroll={activateButtonsAfterCompleteToSRead}
				ref={tosDiv}
			>
				<Card.Header>BandaGo v1.0.0</Card.Header>
				<Card.Body>
					<h3>{t(uiText.termsOfService.TITLE)}</h3>
					<div>
						<ToSContent />
					</div>
				</Card.Body>
			</Card>
			<Row className="my-4 mx-auto vw-75 justify-content-end">
				<Col xs="auto">
					<Button variant={additionalButtonClass} onClick={getOnSubmit(false)}>
						{t(uiText.termsOfService.REJECT)}
					</Button>
				</Col>
				<Col xs="auto">
					<Button variant={additionalButtonClass} onClick={getOnSubmit(true)}>
						{t(uiText.termsOfService.ACCEPT)}
					</Button>
				</Col>
			</Row>
		</div>
	) : haveAcceptedTermsOfUse ? (
		<Redirect to="/patients" />
	) : (
		<Redirect to="/login" />
	);
};

export default TermsOfService;
