import { BaseEntityDB } from './BaseEntity';
import { StorageOnHandDB } from './StorageOnHand';

export const ProductType = {
	ITEM: 'I',
	SERVICE: 'S',
};

export interface ProductDB extends BaseEntityDB {
	m_product_uu: string;
	isActive: boolean;
	'm_product_category.name': string;
	'product_costs.purchase_price': number;
	BH_SellPrice: number;
	producttype: string;
	m_storageonhand: StorageOnHandDB;
}
