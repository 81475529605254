import { ApolloClient } from '@apollo/client';
import { sortBy } from 'lodash';
import { Card, Row, Spinner } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import {
	Bh_EncounterInput,
	Bh_Encounter_DiagnosticInput,
	Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
	Bh_ObservationInput,
	Bh_VisitForEditingQuery,
} from '../../graphql/__generated__/graphql';
import { EncounterTypeUU, EncounterTypeWindowUU } from '../../models';
import { uiText } from '../../utils/Language';
import LabDiagnosticsTable, {
	constructLabDiagnosticsTableFormDataToSave,
	convertToLabDiagnosticsTableFormValues,
	LabDiagnosticsTableFormValues,
	primeLabDiagnosticsTableData,
} from './LabDiagnosticsTable';
import ObservationFormGroup from './ObservationFormGroup';
import { VisitFormValues } from './VisitForm';

export type LabDiagnosticsDetailsFormValues = {
	labDiagnosticsEncounter: {
		UU: string;
		BH_Encounter_Date: Date;
		BH_Encounter_Type: { UU: string };
		BH_Observations: Array<{ UU: string; AD_Field: { UU: string }; BH_Value: string | null }>;
	};
} & LabDiagnosticsTableFormValues;

export const convertToLabDiagnosticsDetailsFormValues: (
	graphqlClient: ApolloClient<object>,
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
) => LabDiagnosticsDetailsFormValues = (graphqlClient, initialData) => {
	const labDiagnosticsEncounterTypeWindow = graphqlClient.readFragment({
		id: EncounterTypeWindowUU.LabDiagnosticDetails,
		fragment: Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
		fragmentName: 'BH_Encounter_Type_WindowDataForVisits',
	});
	// If the user doesn't have access to this window (and the encounter type hasn't loaded), just return
	if (!labDiagnosticsEncounterTypeWindow) {
		return {
			labDiagnosticsEncounter: {
				UU: '',
				BH_Encounter_Date: new Date(),
				BH_Encounter_Type: { UU: '' },
				BH_Observations: [],
			},
			...convertToLabDiagnosticsTableFormValues(graphqlClient, initialData),
		};
	}
	const sortedFields = sortBy(labDiagnosticsEncounterTypeWindow.AD_Window.AD_Tabs?.[0].AD_Fields || [], 'SeqNo');
	const emptyLabDiagnosticsEncounter: LabDiagnosticsDetailsFormValues['labDiagnosticsEncounter'] = {
		UU: v4(),
		BH_Encounter_Date: new Date(),
		BH_Encounter_Type: { UU: labDiagnosticsEncounterTypeWindow.BH_Encounter_Type.UU },
		BH_Observations:
			sortedFields.map((field) => ({
				UU: v4(),
				AD_Field: { UU: field.UU },
				BH_Value: null,
			})) || [],
	};
	if (!initialData) {
		return {
			labDiagnosticsEncounter: emptyLabDiagnosticsEncounter,
			...convertToLabDiagnosticsTableFormValues(graphqlClient, initialData),
		};
	}
	const labDiagnosticsEncounter = initialData.BH_Encounters?.filter(
		(encounter) => encounter.BH_Encounter_Type.UU === labDiagnosticsEncounterTypeWindow.BH_Encounter_Type.UU,
	)[0];
	return {
		labDiagnosticsEncounter: labDiagnosticsEncounter
			? {
					UU: labDiagnosticsEncounter.UU,
					BH_Encounter_Date: new Date(labDiagnosticsEncounter.BH_Encounter_Date),
					BH_Encounter_Type: { UU: labDiagnosticsEncounterTypeWindow.BH_Encounter_Type.UU },
					BH_Observations:
						sortedFields.map((field) => {
							const observationToUse = labDiagnosticsEncounter.BH_Observations?.find(
								(observation) => observation.AD_Field.UU === field.UU,
							);
							return {
								UU: observationToUse?.UU || v4(),
								AD_Field: { UU: field.UU },
								BH_Value: observationToUse?.BH_Value || null,
							};
						}) || [],
				}
			: emptyLabDiagnosticsEncounter,
		...convertToLabDiagnosticsTableFormValues(graphqlClient, initialData),
	};
};

export const constructLabDiagnosticsDetailsFormDataToSave = (
	data: VisitFormValues,
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
): [
	{ save: Bh_EncounterInput[]; ignore: string[] },
	{ save: Bh_ObservationInput[]; ignore: string[] },
	{ save: Bh_Encounter_DiagnosticInput[]; ignore: string[] },
] => {
	// If we didn't have access to the lab diagnostics encounters, just return nulls
	if (data.labDiagnosticsEncounter.UU === '') {
		// Get the encounter we're dealing with in this "window" so we can ignore those
		let encountersToIgnore =
			initialData?.BH_Encounters?.filter(
				(encounter) => encounter.BH_Encounter_Type.UU === EncounterTypeUU.LabDiagnosticDetails,
			) || [];
		return [
			{ save: [], ignore: encountersToIgnore.map((encounter) => encounter.UU) },
			{
				save: [],
				ignore: encountersToIgnore.flatMap((encounter) =>
					encounter.BH_Observations?.length ? encounter.BH_Observations.map((observation) => observation.UU) : [],
				),
			},
			{
				save: [],
				ignore: encountersToIgnore.flatMap((encounter) =>
					encounter.BH_Encounter_DiagnosticList?.length
						? encounter.BH_Encounter_DiagnosticList.map((encounterDiagnostic) => encounterDiagnostic.UU)
						: [],
				),
			},
		];
	}
	const labDiagnosticsEncounter: Bh_EncounterInput = {
		UU: data.labDiagnosticsEncounter.UU,
		BH_Encounter_Date: data.labDiagnosticsEncounter.BH_Encounter_Date.getTime(),
		BH_Encounter_Type: data.labDiagnosticsEncounter.BH_Encounter_Type,
		BH_Visit: { UU: data.UU },
		IsActive: true,
	};
	// RHF version 7.12.2 doesn't always reset arrays, so make sure the optional operator is used
	// (7.13.0+ does, but they cause other errors that haven't been fixed up to 7.53.0)
	const labDiagnosticsEncounterObservations: Bh_ObservationInput[] =
		data.labDiagnosticsEncounter.BH_Observations?.filter((observation) => !!observation.BH_Value).map(
			(observation) => ({
				UU: observation.UU,
				AD_Field: { UU: observation.AD_Field.UU },
				BH_Encounter: { UU: data.labDiagnosticsEncounter.UU },
				BH_Value: observation.BH_Value,
				IsActive: true,
			}),
		) || [];
	let encounterDiagnostics = constructLabDiagnosticsTableFormDataToSave(data);
	return [
		{
			// If we're not saving anything on this encounter, make sure we return nothing to save (so it gets deleted)
			save: labDiagnosticsEncounterObservations.length || encounterDiagnostics.length ? [labDiagnosticsEncounter] : [],
			ignore: [],
		},
		{ save: labDiagnosticsEncounterObservations, ignore: [] },
		{ save: encounterDiagnostics, ignore: [] },
	];
};

export const primeLabDiagnosticsDetailsData = (graphqlClient: ApolloClient<object>) =>
	Promise.all([primeLabDiagnosticsTableData(graphqlClient)]);

type LabDiagnosticsDetailsProps = {
	isDataReadOnly?: boolean;
	loading?: boolean;
};

const LabDiagnosticsDetails = ({ isDataReadOnly, loading = false }: LabDiagnosticsDetailsProps) => {
	const { t } = useTranslation();
	const { getValues } = useFormContext<VisitFormValues>();

	const visitUuid = getValues('UU');
	const fieldPrefix = 'labDiagnosticsEncounter';

	return (
		<Card className="bh-card">
			<Card.Header className="d-flex">
				<div className="fw-bold h5 mb-0">{t(uiText.visit.form.diagnostic.LAB_DIAGNOSTICS_DETAILS)}</div>
				{loading && (
					<div className="ms-auto fw-light">
						<small>{t(uiText.visit.LOADING_MORE_DETAILS)}</small>{' '}
						<Spinner animation="border" size="sm" className="align-middle" role="status" />
					</div>
				)}
			</Card.Header>
			<Card.Body className="p-3">
				<Row className="gy-3">
					<LabDiagnosticsTable readOnly={isDataReadOnly} />

					<ObservationFormGroup visitUuid={visitUuid} fieldPrefix={fieldPrefix} />
				</Row>
			</Card.Body>
		</Card>
	);
};

export default LabDiagnosticsDetails;
