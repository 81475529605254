export const OK = 'OK';
export const SAVE_REMEMBER_ME = 'saveRememberMe';
export const SAVE_USERNAME = 'saveUsername';
export const SAVE_PASSWORD = 'savePassword';

// uri
export const LOGIN_URI = '/login';
export const PROCESS_URI = '/process';
export const VISIT_QUEUE_URI = '/queue';
export const OPEN_DRAFTS_URI = '/open-drafts';
export const OPEN_DRAFTS_COUNT_URI = '/open-drafts/count';
export const MOVEMENTS_URI = 'movements';
export const CHARGE_TYPES_URI = 'charge-types';

// page paths
export const ROOT_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const HOME_PAGE = '/home';
export const VISITS_PAGE = '/visits';
export const EXPENSE_CATEGORIES_PAGE = '/expense-categories';
export const PATIENTS_PAGE = '/patients';
export const PRODUCTS_PAGE = '/products';
export const RECEIVE_PRODUCTS_PAGE = '/receive-products';
export const SERVICES_PAGE = '/services';
export const EXPENSES_PAGE = '/expenses';
export const TRACK_INCOME_PAGE = '/track-income';
export const ORGANIZATION_PAGE = '/organization';
export const OTC_PAGE = '/otc';
export const SUPPLIERS_PAGE = '/suppliers';
export const TOS_PAGE = '/tos';
export const STOCKTAKE_PAGE = '/stock-take';
export const SESSION_TIMEOUT_PAGE = '/session-timeout';
export const REPORTS_PAGE = '/reports';
export const SERVICE_DEBT_PAGE = '/service-debt';
export const CODED_DIAGNOSES_PAGE = '/coded-diagnoses';
export const REFRESH_PAGE = '/refresh';
export const INSURERS_AND_DONORS = '/insurers-and-donors';
export const MANAGE_USERS_PAGE = '/manage-users';
export const TRANSFER_INVENTORY = '/transfer-inventory';
export const DIAGNOSTICS_PAGE = '/diagnostics';
export const BANDA_HEALTH_TERMS_OF_SERVICE_PAGE = 'https://www.bandahealth.org/bandahealthsolutions-termsofservice/';
export const BANDA_HEALTH_PRIVACY_POLICY_PAGE = 'https://www.bandahealth.org/bandahealthsolutions-privacypolicy/';
export const BANDA_HEALTH_HOME_PAGE = 'https://www.bandahealth.org/';
export const BANDA_HEALTH_CONTACT_PAGE = 'https://www.bandahealth.org/contact/';
export const BANDA_HEALTH_HOW_TO_VIDEOS_PAGE = 'https://www.youtube.com/channel/UCIfk08Ojb9gi3fAKKxHTlbg/videos/';
// properties
export const PATIENT_TYPES = 'patientTypes';
export const PRODUCT_CATEGORY_TYPES = 'productCategoryTypes';
export const PRODUCT_CATEGORIES = 'productCategories';
export const REFERRALS = 'referrals';
export const PROCESS_STAGE_LIST = 'processStageList';

export const SEARCH = {
	INPUT_DELAY: 300,
	DEFAULT_PAGE_NUMBER: 0,
	DEFAULT_PAGE_SIZE: 15,
} as const;

export const DEFAULT_PAGE_SIZE = 15;

// for: eslint jsx - a11y / anchor - is - valid;
export const ANCHOR_CURRENT_PAGE = '#';

export const DEFAULT_DEPLOYMENT_CHECK_DELAY = 300000; // 5 minutes in milliseconds

export const debounceTimeouts = {
	SUBMIT_HANDLER: 300, // milliseconds
} as const;

export type PageUuid = (typeof pageUuid)[keyof typeof pageUuid];

export const pageUuid = {
	CHIEF_COMPLAINT: 'ee3189d3-9bf5-4528-b5c8-26f2cabde1ed',
	CLINICAL_DETAILS: '2e37e97b-aeb5-47d7-add3-0d602233c2aa',
	CODED_DIAGNOSIS: '1f29f7ab-bc9a-427c-b35b-87589e4612b5',
	DASHBOARD: 'd91768c8-5c5b-4d7c-9a6f-15b06d45908b',
	DIAGNOSTICS: '55f080e3-17f4-4ffa-b03b-6dda34884bcc',
	EXPENSE_CATEGORIES: '5731bc45-3b78-475a-a347-4ca899f19e32',
	EXPENSES: '37df7931-7d07-4812-b9d4-dec7a53bb70f',
	INSURERS_AND_DONORS: 'ab23d5c5-19ce-4c46-a17a-5ae2c37dd89d',
	INVENTORY: '8f744d1c-427a-4b85-ab98-38e50258e86d',
	LAB_DIAGNOSTICS_DETAILS: '3084592a-531b-4fbd-a412-5c14c2b15288',
	ORGANIZATION: '66df8b28-5a44-40a0-b63e-d51695bdfc92',
	OTC: '3a4ac3cd-9e1b-4a2c-82d3-78f698ec9e1f',
	PATIENTS: 'ba697729-5ec8-44f7-b534-446310bb5782',
	PAYMENTS: '4497b5f7-758d-4e82-8e2b-01c4364ce609',
	PRODUCTS: 'c63b9972-1b23-4140-8bbb-0ea2b0b81024',
	RECEIVE_PRODUCTS: '78dd6f39-84f9-4e19-b08e-7a3441af15e5',
	REPORTS: '584a4f57-33c6-460e-9916-9ad0347cac5b',
	SERVICES: 'fd93da00-871d-4996-a3f7-4528bed8b758',
	SUPPLIERS: '565af89e-8f10-4469-84f5-6cca8d7fae27',
	TRACK_INCOME: '44c02ddc-ef83-4020-8e4c-709d8cbeadc2',
	TRANSFER_INVENTORY: 'd3c84cad-7306-464d-85da-7e629846f8c0',
	TRIAGE_DETAILS: '53b4d743-c311-40e5-aa8e-c0880c42c1b1',
	USERS: '6b934ec2-7f45-4104-ba10-08e3ce54de7e',
	VISITS: 'a1f3e45c-4a6f-4c05-af26-517b8e9cbb77',
} as const;
