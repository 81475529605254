import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import { endOfDay } from 'date-fns';
import { Fragment } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Ad_Process_ParaForReportsPageFragmentDoc } from '../../graphql/__generated__/graphql';
import { DATE, DATETIME, REFERENCE_UUID_TABLE_DIRECT } from '../../models/Reference';
import { getCodedDiagnosisDefaultSearchFilter, getDefaultSearchFilter } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import { getCodedDiagnosisName } from '../../utils/ModelUtils';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import EntityLookup from '../entity-lookup/EntityLookup';
import { ReportFormValues } from './Report';

type ReportFieldProps = {
	field: ReportFormValues['AD_Process_ParaList'][0];
	index: number;
};

const lookupOverrides = [
	{
		ColumnName: 'BH_Concept',
		Display: (data: any) => getCodedDiagnosisName(data),
		Fields: `BH_Display_Name ToBH_Concept_Mappings { BH_To_Concept_Code BH_To_Source_Name }`,
		Filter: getCodedDiagnosisDefaultSearchFilter,
		Sort: JSON.stringify([['BH_Display_Name', 'asc']]),
	},
] as const;

const ReportField = ({ field, index }: ReportFieldProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const {
		formState: { errors },
		getValues,
		register,
		setValue,
	} = useFormContext<ReportFormValues>();

	const parameter = graphqlClient.readFragment({ id: field.UU, fragment: Ad_Process_ParaForReportsPageFragmentDoc });
	const sanitizedColumnName = parameter?.ColumnName?.replace(/ /g, '')?.replace(/_UU/g, '') || 'Broke';
	const lookupOverride = lookupOverrides.find((override) => override.ColumnName === sanitizedColumnName);
	// Define a custom, non-typed query to get the data we need
	const [fetchData, { data: entities, loading }] = useLazyQuery(
		gql`
		query ${sanitizedColumnName}Get($Sort: String, $Filter: String!) {
			${sanitizedColumnName}Get(Sort: $Sort, Filter: $Filter, Size: 10) {
				Results {
					UU
					${lookupOverride?.Fields || 'Name'}
				}
			}
		}
	`,
		{ fetchPolicy: 'network-only' },
	);

	return (
		parameter && (
			<>
				<Form.Group as={Fragment} controlId={parameter.UU}>
					<Col xs={2} className="d-flex align-items-center">
						<Form.Label column>{parameter.Name}</Form.Label>
					</Col>
					<Col xs={7} className="d-flex align-items-center">
						<input
							type="hidden"
							{...register(`AD_Process_ParaList.${index}.UU` as 'AD_Process_ParaList.0.UU')}
							defaultValue={field.UU}
						/>
						<input
							type="hidden"
							{...register(`AD_Process_ParaList.${index}.Name` as 'AD_Process_ParaList.0.Name')}
							defaultValue={field.Name}
						/>
						{parameter.AD_Reference.UU === DATE ? (
							<Controller<ReportFormValues, 'AD_Process_ParaList.0.Parameter'>
								name={`AD_Process_ParaList.${index}.Parameter` as 'AD_Process_ParaList.0.Parameter'}
								defaultValue={field.Parameter}
								render={({ field: controllerField }) => (
									<BandaDatePicker
										{...controllerField}
										value={undefined}
										onChange={(date: Date | null) =>
											controllerField.onChange(date && date instanceof Date ? date.getTime() : null)
										}
										selected={(field.Parameter && new Date(field.Parameter)) || undefined}
									/>
								)}
							/>
						) : parameter.AD_Reference.UU === DATETIME ? (
							<Controller<ReportFormValues, 'AD_Process_ParaList.0.Parameter'>
								name={`AD_Process_ParaList.${index}.Parameter` as 'AD_Process_ParaList.0.Parameter'}
								rules={{ required: true }}
								defaultValue={field.Parameter}
								render={({ field: controllerField }) => (
									<BandaDatePicker
										{...controllerField}
										value={undefined}
										dateFormat="yyyy-MM-dd HH:mm"
										timeInputLabel={`${t(uiText.visit.form.TIME)}:`}
										showTimeInput
										onChange={(date: Date | null) => {
											if (
												!getValues(`AD_Process_ParaList.${index}.Parameter` as 'AD_Process_ParaList.0.Parameter') &&
												date
											) {
												if (parameter.Name.includes('End Date')) {
													date.setHours(23);
													date.setMinutes(59);
												} else if (parameter.Name.includes('Begin Date')) {
													// See if there is an end date parameter
													const processParameters = getValues('AD_Process_ParaList');
													const endDateParameterIndex = processParameters.findIndex((parameter) =>
														parameter.Name.includes('End Date'),
													);
													if (endDateParameterIndex > -1 && !processParameters[endDateParameterIndex].Parameter) {
														const endDate = new Date(date);
														endDate.setHours(23);
														endDate.setMinutes(59);
														setValue(
															`AD_Process_ParaList.${endDateParameterIndex}.Parameter` as 'AD_Process_ParaList.0.Parameter',
															endDate.getTime(),
														);
													}
												}
											}
											controllerField.onChange(date && date instanceof Date ? date.getTime() : null);
										}}
										selected={(field.Parameter && new Date(field.Parameter)) || undefined}
										maxDate={endOfDay(new Date())}
									/>
								)}
							/>
						) : parameter.AD_Reference.UU === REFERENCE_UUID_TABLE_DIRECT ? (
							<EntityLookup<ReportFormValues, 'AD_Process_ParaList.0.entity'>
								name={`AD_Process_ParaList.${index}.entity` as 'AD_Process_ParaList.0.entity'}
								id={parameter.UU}
								inputProps={{ id: parameter.UU }}
								emptyLabel={t(uiText.report.NOTHING_FOUND)}
								labelKey={(entity) => {
									const data = graphqlClient.readFragment({
										id: entity.UU,
										fragment: gql`
											fragment ${sanitizedColumnName}ForReportFields on ${sanitizedColumnName} {
												UU
												${lookupOverride?.Fields || 'Name'}
											}`,
									});
									if (!data) {
										return '';
									}
									if (lookupOverride?.Display) {
										return lookupOverride.Display(data) || '';
									}
									return data.Name || '';
								}}
								placeholder={t(uiText.report.PLACEHOLDER)}
								promptText={t(uiText.report.SEARCHING)}
								searchText={t(uiText.report.SEARCHING)}
								options={entities?.[`${sanitizedColumnName}Get`]?.Results}
								onSearch={(query) =>
									fetchData({
										variables: {
											Filter: (lookupOverride?.Filter || getDefaultSearchFilter)(query).toString(),
											Sort: lookupOverride?.Sort,
										},
									})
								}
								isLoading={loading}
								className="w-100"
								defaultValue={{ UU: field.entity?.UU || '' }}
							/>
						) : !!parameter.AD_Reference_Value?.AD_Ref_ListList?.length ? (
							<Form.Select
								{...register(`AD_Process_ParaList.${index}.Parameter` as 'AD_Process_ParaList.0.Parameter')}
								defaultValue={field.Parameter}
							>
								<option value={''}>{t(uiText.report.ALL)}</option>
								{parameter.AD_Reference_Value.AD_Ref_ListList.map((referenceValue) => (
									<option key={referenceValue.UU} value={referenceValue.Value}>
										{referenceValue.Name}
									</option>
								))}
							</Form.Select>
						) : (
							<Form.Control
								{...register(`AD_Process_ParaList.${index}.Parameter` as 'AD_Process_ParaList.0.Parameter')}
								defaultValue={field.Parameter}
							/>
						)}
						{errors?.AD_Process_ParaList?.[index]?.Parameter ? (
							<p className="text-danger">{t(uiText.error.FIELD_REQUIRED)}</p>
						) : (
							''
						)}
					</Col>
				</Form.Group>
				<Col xs={3} />
			</>
		)
	);
};

export default ReportField;
