import { useQuery } from '@apollo/client';
import { Button, Form, Modal } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { Bh_Voided_ReasonsGeneralDocument } from '../../graphql/__generated__/graphql';
import { DBFilter, VoidedReasonDB } from '../../models';
import { PageUuid, SEARCH } from '../../utils/Constants';
import { uiText } from '../../utils/Language';

type VoidedReasonModalFormFields = { BH_Voided_Reason: { UU: string } };

type VoidedReasonModalProps = {
	windowUuid: PageUuid;
	onHandleClose: () => void;
	onVoidSubmit: SubmitHandler<VoidedReasonModalFormFields>;
};

const VoidedReasonModal = ({ windowUuid, onHandleClose, onVoidSubmit }: VoidedReasonModalProps) => {
	const { t } = useTranslation();
	const formMethods = useForm<VoidedReasonModalFormFields>({
		defaultValues: {
			BH_Voided_Reason: {
				UU: '',
			},
		},
	});

	const { data: voidedReasons, refetch } = useQuery(Bh_Voided_ReasonsGeneralDocument, {
		variables: {
			Page: SEARCH.DEFAULT_PAGE_NUMBER,
			Size: SEARCH.DEFAULT_PAGE_SIZE,
			Sort: JSON.stringify([['lineno', 'asc']]),
			Filter: DBFilter<VoidedReasonDB>().property('ad_window.ad_window_uu').equals(windowUuid).toString(),
		},
		fetchPolicy: 'cache-first',
	});

	useUpdateEffect(() => {
		refetch({
			Filter: DBFilter<VoidedReasonDB>().property('ad_window.ad_window_uu').equals(windowUuid).toString(),
		});
	}, [windowUuid, refetch]);

	return (
		<Modal show={true}>
			<Form onSubmit={formMethods.handleSubmit(onVoidSubmit)} className="order-form">
				<Modal.Header>{t(uiText.visit.prompt.VOIDED_REASON_MESSAGE)}</Modal.Header>
				<Modal.Body>
					<Form.Group controlId="voidedReason">
						<Form.Select
							required
							aria-label={t(uiText.voidedReason.VOIDED_REASON_TYPE)}
							{...formMethods.register('BH_Voided_Reason.UU')}
						>
							<option value=""></option>
							{voidedReasons?.BH_Voided_ReasonGet.Results.map((voidedReason) => (
								<option key={voidedReason.UU} value={voidedReason.UU}>
									{voidedReason.Name}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button
						type="button"
						variant="danger"
						onClick={() => {
							onHandleClose();
						}}
					>
						{t(uiText.modal.button.CANCEL)}
					</Button>
					<Button type="submit" variant="success" className="ms-auto">
						{t(uiText.modal.button.VOID)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default VoidedReasonModal;
