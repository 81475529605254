import { isApolloError, useApolloClient } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';
import { chain } from 'lodash';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, DropdownButton, Form, Modal, Row } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import FormModalContext from '../../contexts/FormModalContext';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import UserContext from '../../contexts/UserContext';
import {
	C_DocTypeForFormsDocument,
	C_OrderAndC_OrderLineSaveAndProcessForReceiveProductsDocument,
	C_OrderAndC_OrderLineSaveForReceiveProductsDocument,
	C_OrderDeleteForReceiveProductDocument,
	C_OrderForReceiveProductEditingDocument,
	C_OrderForReceiveProductEditingQuery,
	C_OrderInput,
	C_OrderProcessForReceiveProductsDocument,
	C_OrderSaveForReceiveProductsDocument,
	DocumentStatusActionMapDocument,
	M_AttributeSetInstanceSaveManyForReceiveProductsDocument,
	M_PriceList_VersionGetForProductsDocument,
	M_ProductDataForReceiveProductsFragmentDoc,
	M_ProductsSaveManyForReceiveProductsDocument,
	M_SerNoCtlCreateSerNoDocument,
	M_WarehousesForReceiveProductsPageDocument,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import useIsDocumentActionAvailable from '../../hooks/useIsDocumentActionAvailable';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import useSuspenseDocumentActionInformation from '../../hooks/useSuspenseDocumentActionInformation';
import { DBFilter, DocAction, PriceListVersionDB } from '../../models';
import { documentBaseType } from '../../models/DocumentType';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import ConfirmAction from '../../utils/ConfirmAction';
import { pageUuid } from '../../utils/Constants';
import { getGraphQLErrorMessages } from '../../utils/ErrorUtil';
import { getWarehouseByOrg } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import { getDocumentBaseTypeFilter } from '../../utils/ModelUtils';
import { precisionSubtract } from '../../utils/NumberUtil';
import { isEntityCompleted, isEntityDrafted, isEntityReactivated, isEntityVoided } from '../../utils/StatusUtil';
import BasicButton from '../ActionButtons/BasicButton';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import { withFormModalSuspenseWrapper } from '../HOCs/withFormModalSuspenseWrapper';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import VisitCompleteConfirmationModal from '../Modal/VisitCompleteConfirmationModal';
import VoidedReasonModal from '../Modal/VoidedReasonModal';
import CurrentEntityInfo from './CurrentEntityInfo';
import PriceUpdateModal, { PriceUpdateFormValues } from './PriceUpdateModal';
import ProductLineItemTable, {
	constructReceiveProductLinesFormData,
	convertToReceiveProductLinesFormFields,
	ReceiveProductLinesFormValues,
} from './ProductLineItemTable';
import SupplierInput, {
	convertToSupplierInputFormValues,
	RecieveProductSupplierInputFormValues,
} from './SupplierInput';

export type ReceiveProductFormProps = EntityFormProperties;

export type ReceiveProductFormValues = {
	UU: string;
	BH_Voided_ReasonUU: string | null;
	DateOrdered: Date;
	M_WarehouseUU: string;
	submitEvent: '' | 'save' | 'complete';
} & RecieveProductSupplierInputFormValues &
	ReceiveProductLinesFormValues;

export type SerialNumberControlValues = {
	[serialNumberControlUuid: string]: string;
};

export const NOT_YET_CALCULATED_SERIAL_NUMBER = 'NOT CALCULATED YET';

const fetchPurchaseOrderDocumentTypeArguments = [
	documentBaseType.PurchaseOrder,
	null,
	null,
	false,
	false,
	false,
] as const;
const getTitle = (uuid?: string) => uiText.receiveProduct.title.ADD;
const convertToFormFields: (
	initialData?: C_OrderForReceiveProductEditingQuery['C_Order'],
) => ReceiveProductFormValues = (initialData) => {
	if (!initialData) {
		return {
			UU: v4(),
			BH_Voided_ReasonUU: null,
			DateOrdered: new Date(),
			M_WarehouseUU: '',
			submitEvent: '',
			...convertToSupplierInputFormValues(initialData),
			...convertToReceiveProductLinesFormFields(initialData),
		};
	}
	return {
		UU: initialData.UU,
		BH_Voided_ReasonUU: initialData.BH_Voided_Reason?.UU || null,
		DateOrdered: new Date(initialData.DateOrdered),
		M_WarehouseUU: initialData.M_Warehouse.UU,
		submitEvent: '',
		...convertToSupplierInputFormValues(initialData),
		...convertToReceiveProductLinesFormFields(initialData),
	};
};

const handleProcessingError = (error: unknown): string[] | undefined => {
	if (error instanceof Error && isApolloError(error)) {
		return getGraphQLErrorMessages(error);
	}
	exception({ description: `Product receipt save error: ${error}` });
};

function ReceiveProductForm({ onFinish, uuid, renderAsModal, canSaveMany }: ReceiveProductFormProps) {
	const graphqlClient = useApolloClient();
	const { organization } = useContext(UserContext);
	const { data: [data, purchaseOrderDocumentType, warehouses, , salesPriceListVersion] = [] } = useSuspenseAsync(
		uuid || 'add-order',
		async () =>
			Promise.all([
				uuid
					? graphqlClient.query({
							query: C_OrderForReceiveProductEditingDocument,
							variables: { UU: uuid },
							fetchPolicy: 'network-only',
						})
					: undefined,
				graphqlClient.query({
					query: C_DocTypeForFormsDocument,
					variables: { Filter: getDocumentBaseTypeFilter(...fetchPurchaseOrderDocumentTypeArguments).toString() },
					fetchPolicy: 'cache-first',
				}),
				graphqlClient.query({
					query: M_WarehousesForReceiveProductsPageDocument,
					variables: { Filter: getWarehouseByOrg(organization.UU).toString() },
					fetchPolicy: 'cache-first',
				}),
				graphqlClient.query({ query: DocumentStatusActionMapDocument, fetchPolicy: 'cache-first' }),
				graphqlClient
					.query({
						query: M_PriceList_VersionGetForProductsDocument,
						variables: {
							Size: 1,
							Sort: JSON.stringify([['validfrom', 'desc']]),
							Filter: DBFilter<PriceListVersionDB>()
								.property('validfrom')
								.isLessThanOrEqualTo(startOfDay(new Date()))
								.nested('m_pricelist')
								.property('issopricelist')
								.equals(true)
								.property('isdefault')
								.equals(true)
								.property('isactive')
								.equals(true)
								.up()
								.toString(),
						},
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.M_PriceList_VersionGet.Results[0]),
			]),
	);

	const { t } = useTranslation();
	const [dataToUse, setDataToUse] = useState(data?.data.C_Order);
	const { disableWrite } = useActionPrivileges(pageUuid.RECEIVE_PRODUCTS);
	const title = getTitle(dataToUse ? dataToUse.UU : undefined);

	const formMethods = useForm<ReceiveProductFormValues>({ defaultValues: convertToFormFields(dataToUse) });
	const isDataReadOnly = isEntityCompleted(dataToUse) || isEntityVoided(dataToUse);
	const [viewVoidModal, setViewVoidModal] = useState(false);
	const [displayPriceUpdateModal, setDisplayPriceUpdateModal] = useState(false);
	const [orderLinesWithDifferentBuyPrices, setOrderLinesWithDifferentBuyPrices] = useState<
		PriceUpdateFormValues['products']
	>([]);
	const [showCompleteModal, setShowCompleteModal] = useState(false);
	const [processingErrorMessage, setProcessingErrorMessage] = useState<string[] | undefined>();

	const { toggleNavBlocking } = useContext(NavBlockingContext);
	useConfirmRefresh(formMethods.formState?.isDirty);
	const { canVoidDocument, voidDocumentAction } = useSuspenseDocumentActionInformation(documentBaseType.PurchaseOrder);
	const { value: canReactivateDocument = false } = useIsDocumentActionAvailable(
		documentBaseType.PurchaseOrder,
		dataToUse?.DocStatus.Value,
		DocAction.REACTIVATE,
	);

	const defaultWarehouseUuid =
		warehouses?.data.M_WarehouseGet.Results.find((warehouse) => warehouse.BH_DefaultWarehouse)?.UU || '';
	const isWarehouseNew = !dataToUse;
	const setValue = formMethods.setValue;
	useEffect(() => {
		if (defaultWarehouseUuid && isWarehouseNew) {
			setValue('M_WarehouseUU', defaultWarehouseUuid);
		}
	}, [defaultWarehouseUuid, isWarehouseNew, setValue]);

	const entityUuid = dataToUse?.UU;
	const [{ loading: isDeleteProcessing }, onDelete] = useCustomAsyncFn(async () => {
		if (entityUuid) {
			try {
				await graphqlClient.mutate({
					mutation: C_OrderDeleteForReceiveProductDocument,
					variables: { UUs: [entityUuid] },
				});
				toggleNavBlocking(false);
				onFinish(true);
			} catch (error) {
				console.error(`Could not delete order!: ${error}`);
			}
		}
	}, [graphqlClient, entityUuid, toggleNavBlocking]);

	// Get order lines where the price was different than the product buying price in the system
	const getOrderLinesWithDifferentBuyPrices: () => PriceUpdateFormValues['products'] = () => {
		// Group the order lines by product, sort them by buy price descending, and get the most expensive one
		// With the most expensive one, only add it if it's by price is different
		return chain(formMethods.getValues('C_OrderLines'))
			.filter(
				(orderLine) =>
					orderLine.PriceEntered !==
					graphqlClient.readFragment({
						id: orderLine.M_Product.UU,
						fragment: M_ProductDataForReceiveProductsFragmentDoc,
					})?.LastPurchasePrice,
			)
			.sortBy([(orderLine) => -1 * (orderLine.PriceEntered || 0)]) // sort by buy price descending
			.groupBy((orderLine) => orderLine.M_Product.UU) // group by product
			.values() // get the lists of order lines by product
			.value() // exit out of the chain with an array of arrays
			.map((orderLinesByProduct) => {
				const product = graphqlClient.readFragment({
					id: orderLinesByProduct[0].M_Product.UU,
					fragment: M_ProductDataForReceiveProductsFragmentDoc,
				});
				return {
					UU: orderLinesByProduct[0].M_Product.UU,
					newBuyPrice: orderLinesByProduct[0].PriceEntered || 0,
					newSellPrice: product?.BH_SellPrice || null,
					newPriceMargin: precisionSubtract(product?.BH_SellPrice, orderLinesByProduct[0].PriceEntered),
					wasSellPriceChanged: false,
				};
			});
	};

	const { dataWasSaved, savedData, wasDataSaved } = useContext(FormModalContext);

	// Submit method
	const reset = formMethods.reset;
	const [{ loading: isSubmitProcessing }, onSubmit] = useCustomAsyncFn(
		async (
			productsWithUpdatedSellPricesToSave: PriceUpdateFormValues['products'],
			formData: ReceiveProductFormValues,
		) => {
			const formAction = formData.submitEvent;

			let purchaseOrderDocumentTypeToUse = purchaseOrderDocumentType?.data.C_DocTypeGet.Results[0];
			if (!purchaseOrderDocumentTypeToUse) {
				try {
					purchaseOrderDocumentTypeToUse = (
						await graphqlClient.query({
							query: C_DocTypeForFormsDocument,
							variables: { Filter: getDocumentBaseTypeFilter(...fetchPurchaseOrderDocumentTypeArguments).toString() },
							fetchPolicy: 'cache-first',
						})
					).data.C_DocTypeGet.Results[0];
				} catch (error) {
					exception({ description: 'Could not load purchase order document type: ' + error });
					toast.error(t(uiText.error.PLEASE_TRY_AGAIN));
					return;
				}
			}

			const order: C_OrderInput = {
				UU: formData.UU,
				C_BPartner: formData.C_BPartner.UU ? { UU: formData.C_BPartner.UU } : undefined,
				C_DocTypeTarget: { UU: purchaseOrderDocumentTypeToUse.UU },
				DateOrdered: formData.DateOrdered.getTime(),
				IsSOTrx: purchaseOrderDocumentTypeToUse.IsSOTrx,
				M_Warehouse: formData.M_WarehouseUU ? { UU: formData.M_WarehouseUU } : undefined,
			};

			const [attributeSetInstances, orderLines] = constructReceiveProductLinesFormData(
				dataToUse,
				formData,
				graphqlClient,
			);

			try {
				// Get the serial numbers for the ASIs, so first get the serial number control UUs
				let serialNumberControlUUsNeedingSerialNumbers = [
					...new Set(
						orderLines
							.map(
								(orderLine) =>
									graphqlClient.readFragment({
										id: orderLine.M_Product!.UU,
										fragment: M_ProductDataForReceiveProductsFragmentDoc,
									})?.M_AttributeSet?.M_SerNoCtl?.UU,
							)
							.filter((serialNumberControlUU) => !!serialNumberControlUU) as string[],
					),
				];
				const serialNumbersBySerialNumberControlUU: { [serialNumberControlUU: string]: string } = {};
				// If any order line already has a batch # for a given serial number control, use it instead
				orderLines.forEach((orderLine) => {
					const serialNumberControlUUForSelectedProductAttributeSet = graphqlClient.readFragment({
						id: orderLine.M_Product!.UU,
						fragment: M_ProductDataForReceiveProductsFragmentDoc,
					})?.M_AttributeSet?.M_SerNoCtl?.UU;
					const attributeSetInstanceSerialNumber = attributeSetInstances.find(
						(attributeSetInstance) => attributeSetInstance.UU === orderLine.M_AttributeSetInstance?.UU,
					)?.SerNo;
					if (serialNumberControlUUForSelectedProductAttributeSet && attributeSetInstanceSerialNumber) {
						serialNumbersBySerialNumberControlUU[serialNumberControlUUForSelectedProductAttributeSet] =
							attributeSetInstanceSerialNumber;
						serialNumberControlUUsNeedingSerialNumbers = serialNumberControlUUsNeedingSerialNumbers.filter(
							(serialNumberControlUU) => serialNumberControlUU !== serialNumberControlUUForSelectedProductAttributeSet,
						);
					}
				});
				for (let serialNumberControlUU of serialNumberControlUUsNeedingSerialNumbers) {
					serialNumbersBySerialNumberControlUU[serialNumberControlUU] = (
						await graphqlClient.mutate({
							mutation: M_SerNoCtlCreateSerNoDocument,
							variables: { UU: serialNumberControlUU },
						})
					).data?.M_SerNoCtlCreateSerNo!;
				}

				// Save all ASIs
				const attributeSetInstanceSave = graphqlClient.mutate({
					mutation: M_AttributeSetInstanceSaveManyForReceiveProductsDocument,
					variables: {
						M_AttributeSetInstances: attributeSetInstances.map((attributeSetInstance) => {
							// Whichever ASIs don't have serial numbers, get them
							if (!attributeSetInstance.SerNo) {
								// Get the order line using this ASI
								let orderLineMatchingThisAttributeSetInstance = orderLines.find(
									(orderLine) => orderLine.M_AttributeSetInstance?.UU === attributeSetInstance.UU,
								);
								// Get a serial number control UU from the cache, if any
								let serialNumberControlUUForThisAttributeSetInstance = graphqlClient.readFragment({
									id: orderLineMatchingThisAttributeSetInstance?.M_Product?.UU || '',
									fragment: M_ProductDataForReceiveProductsFragmentDoc,
								})?.M_AttributeSet?.M_SerNoCtl?.UU;
								// If we found one, get the generated serial number
								if (serialNumberControlUUForThisAttributeSetInstance) {
									attributeSetInstance.SerNo =
										serialNumbersBySerialNumberControlUU[serialNumberControlUUForThisAttributeSetInstance];
								}
							}
							return attributeSetInstance;
						}),
					},
				});

				const productsToSave = productsWithUpdatedSellPricesToSave.filter(
					(productData) => productData.wasSellPriceChanged,
				);
				let productSavePromise: Promise<unknown> | undefined;
				if (productsToSave.length) {
					productSavePromise = graphqlClient.mutate({
						mutation: M_ProductsSaveManyForReceiveProductsDocument,
						variables: {
							M_Products: productsToSave.map((product) => ({ UU: product.UU, BH_SellPrice: product.newSellPrice })),
							M_ProductPrices: productsToSave.flatMap((product) => {
								const cachedProduct = graphqlClient.readFragment({
									id: product.UU,
									fragment: M_ProductDataForReceiveProductsFragmentDoc,
								});
								const salesProductPrice = cachedProduct?.M_ProductPrices?.find(
									(productPrice) => productPrice.M_PriceList_Version.UU === salesPriceListVersion?.UU,
								);
								return salesProductPrice
									? {
											UU: salesProductPrice.UU,
											PriceLimit: product.newSellPrice,
											PriceList: product.newSellPrice,
											PriceStd: product.newSellPrice,
										}
									: [];
							}),
						},
					});
				}

				await attributeSetInstanceSave;
				// Now save any products and the order
				if (formAction === 'complete') {
					await Promise.all([
						graphqlClient.mutate({
							mutation: C_OrderAndC_OrderLineSaveAndProcessForReceiveProductsDocument,
							variables: {
								C_Order: order,
								C_OrderLines: orderLines,
								C_Order_UU: order.UU!,
								DocumentAction: DocAction.COMPLETE,
							},
						}),
						productSavePromise,
					]);
				} else {
					await Promise.all([
						graphqlClient.mutate({
							mutation: C_OrderAndC_OrderLineSaveForReceiveProductsDocument,
							variables: { C_Order: order, C_OrderLines: orderLines },
						}),
						productSavePromise,
					]);
				}
				const newOrder = (
					await graphqlClient.query({
						query: C_OrderForReceiveProductEditingDocument,
						variables: { UU: order.UU! },
						fetchPolicy: 'network-only',
					})
				).data.C_Order!;
				setDataToUse(newOrder);
				dataWasSaved(newOrder.UU);
				reset({}, { keepValues: true });
				toast.success(t(uiText.receiveProduct.SAVED));
			} catch (error) {
				setProcessingErrorMessage(handleProcessingError(error));
			}
		},
		[voidDocumentAction, reset, purchaseOrderDocumentType, dataWasSaved, t, salesPriceListVersion, dataToUse],
	);

	const [{ loading: areReactivating }, reactivateOrder] = useCustomAsyncFn(
		async () =>
			dataToUse?.UU
				? graphqlClient
						.mutate({
							mutation: C_OrderProcessForReceiveProductsDocument,
							variables: { UU: dataToUse.UU, DocumentAction: DocAction.REACTIVATE },
						})
						.then(() =>
							graphqlClient
								.query({
									query: C_OrderForReceiveProductEditingDocument,
									variables: { UU: dataToUse.UU! },
									fetchPolicy: 'network-only',
								})
								.then((response) => {
									const newOrder = response.data.C_Order!;
									setDataToUse(newOrder);
									dataWasSaved(newOrder.UU);
									reset({}, { keepValues: true });
									toast.success(t(uiText.receiveProduct.SAVED));
								}),
						)
						.catch((error) => {
							setProcessingErrorMessage(handleProcessingError(error));
						})
				: undefined,
		[graphqlClient, dataToUse],
	);

	const [{ loading: areVoiding }, voidOrder] = useCustomAsyncFn(
		async (voidingReasonUU: string) =>
			dataToUse?.UU
				? graphqlClient
						.mutate({
							mutation: C_OrderProcessForReceiveProductsDocument,
							variables: { UU: dataToUse.UU, DocumentAction: voidDocumentAction as string },
						})
						.then(() =>
							graphqlClient
								.mutate({
									mutation: C_OrderSaveForReceiveProductsDocument,
									variables: { C_Order: { UU: dataToUse.UU, BH_Voided_Reason: { UU: voidingReasonUU } } },
								})
								.then(() =>
									graphqlClient
										.query({
											query: C_OrderForReceiveProductEditingDocument,
											variables: { UU: dataToUse.UU! },
											fetchPolicy: 'network-only',
										})
										.then((response) => {
											const newOrder = response.data.C_Order!;
											setDataToUse(newOrder);
											dataWasSaved(newOrder.UU);
											reset({}, { keepValues: true });
											toast.success(t(uiText.receiveProduct.SAVED));
										}),
								),
						)
						.catch((error) => {
							setProcessingErrorMessage(handleProcessingError(error));
						})
				: undefined,
		[graphqlClient, dataToUse, voidDocumentAction],
	);

	const inputs = (
		<FormProvider {...formMethods}>
			<Form onSubmit={formMethods.handleSubmit((formData) => onSubmit([], formData))} className="px-0">
				<fieldset disabled={disableWrite || isDataReadOnly}>
					<input type="hidden" {...formMethods.register('UU')} />
					<input type="hidden" {...formMethods.register('BH_Voided_ReasonUU')} />
					<CurrentEntityInfo />
					<SupplierInput isDataReadOnly={isDataReadOnly} />

					<Card className="bh-card">
						<Card.Header className="fw-bold h5">{t(uiText.receiveProduct.ORDER_INFORMATION)}</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group as={Fragment} controlId="dateOrdered">
									<Col xs={1} className="d-flex align-items-center">
										<Form.Label column>{t(uiText.receiveProduct.DATE_RECEIVED)}</Form.Label>
									</Col>
									<Col xs={5} className="d-flex align-items-center">
										<Controller<ReceiveProductFormValues, 'DateOrdered'>
											name="DateOrdered"
											render={({ field }) => (
												<BandaDatePicker
													maxDate={endOfDay(new Date())}
													{...field}
													value={undefined}
													selected={field.value}
												/>
											)}
										/>
									</Col>
								</Form.Group>
								<Form.Group as={Fragment} controlId="warehouse">
									<Col xs={1} className="d-flex align-items-center">
										<Form.Label column>{t(uiText.changeAccess.STOREROOM)}</Form.Label>
									</Col>
									<Col xs={5} className="d-flex align-items-center">
										<Form.Select {...formMethods.register('M_WarehouseUU', { required: true })}>
											{warehouses?.data.M_WarehouseGet.Results.map((warehouse) => (
												<option key={warehouse.UU} value={warehouse.UU}>
													{warehouse.Name}
												</option>
											))}
										</Form.Select>
									</Col>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>

					<Card className="bh-card">
						<Card.Header className="fw-bold h5">{t(uiText.receiveProduct.PRODUCTS)}</Card.Header>
						<Card.Body>
							<ProductLineItemTable readOnly={isDataReadOnly} />
						</Card.Body>
					</Card>
				</fieldset>
				<input type="hidden" {...formMethods.register('submitEvent')} defaultValue={''} />
			</Form>
		</FormProvider>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'm-4 ms-3'}`}>
			{disableWrite ? (
				<Col xs="auto">
					<BasicButton
						name={uiText.product.button.BACK}
						text={t(uiText.product.button.BACK)}
						variant="danger"
						icon="arrow-left"
						active={true}
						onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
					/>
				</Col>
			) : (
				<>
					<Col xs="auto" className="me-auto">
						<Button
							type="button"
							variant="danger"
							onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
						>
							{isDataReadOnly ? t(uiText.receiveProduct.button.BACK) : t(uiText.receiveProduct.button.CANCEL)}
						</Button>
					</Col>

					{isEntityDrafted(dataToUse) ? (
						<Col xs="auto">
							<Button
								key="deleteButton"
								variant="danger"
								onClick={() =>
									ConfirmAction(t(uiText.receiveProduct.DELETE_DRAFT_PROMPT), () => {
										return onDelete();
									})
								}
							>
								{t(uiText.receiveProduct.button.DELETE)}
							</Button>
						</Col>
					) : null}
					{!dataToUse || isEntityDrafted(dataToUse) ? (
						<>
							<Col xs="auto">
								<Button
									key="saveButton"
									type="button"
									variant="primary"
									onClick={() => {
										formMethods.setValue('submitEvent', 'save');
										formMethods.handleSubmit((formData) => onSubmit([], formData))();
									}}
								>
									{t(uiText.receiveProduct.button.SAVE)}
								</Button>
							</Col>
							<Col xs="auto">
								<Button
									key="completeButton"
									type="button"
									variant="success"
									onClick={async () => {
										formMethods.setValue('submitEvent', 'complete');
										if (!(await formMethods.trigger())) {
											return;
										}
										setShowCompleteModal(true);
									}}
								>
									{t(uiText.receiveProduct.button.COMPLETE)}
								</Button>
							</Col>
						</>
					) : null}
					{isEntityCompleted(dataToUse) ? (
						<>
							{!disableWrite && (canVoidDocument || canReactivateDocument) ? (
								<Col xs="auto">
									<DropdownButton title={t(uiText.receiveProduct.button.ACTION)} variant="danger">
										{canReactivateDocument && (
											<Dropdown.Item
												as="button"
												onClick={() => {
													reactivateOrder();
												}}
											>
												{t(uiText.receiveProduct.button.REACTIVATE)}
											</Dropdown.Item>
										)}
										{canVoidDocument && (
											<Dropdown.Item
												type="button"
												variant="danger"
												onClick={() => {
													setViewVoidModal(true);
												}}
											>
												{t(uiText.receiveProduct.button.VOID_RECEIVE_PRODUCT)}
											</Dropdown.Item>
										)}
									</DropdownButton>
								</Col>
							) : null}
						</>
					) : null}
				</>
			)}
		</Row>
	);

	const shouldShowProcessingIndicator = isSubmitProcessing || isDeleteProcessing || areReactivating || areVoiding;

	return (
		<>
			<Modal show={(processingErrorMessage?.length || 0) > 0}>
				<Modal.Header>
					<Modal.Title>{t(uiText.document.COULD_NOT_PROCESS_TRY_AGAIN)}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t(uiText.error.FOLLOWING_ERROR_OCCURRED)}
					<em className="text-gray-500">
						{processingErrorMessage?.map((message, index) => (
							<Fragment key={index}>
								<br />
								{message}
							</Fragment>
						))}
					</em>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						onClick={() => {
							setProcessingErrorMessage(undefined);
						}}
					>
						{t(uiText.modal.OK)}
					</Button>
				</Modal.Footer>
			</Modal>
			{viewVoidModal && (
				<VoidedReasonModal
					windowUuid={pageUuid.RECEIVE_PRODUCTS}
					onHandleClose={() => {
						setViewVoidModal(false);
					}}
					onVoidSubmit={({ BH_Voided_Reason }) => {
						voidOrder(BH_Voided_Reason.UU);
						setViewVoidModal(false);
					}}
				/>
			)}
			{showCompleteModal && (
				<VisitCompleteConfirmationModal
					cancel={() => {
						setShowCompleteModal(false);
					}}
					confirm={() => {
						setShowCompleteModal(false);
						const orderLinesWithDifferentBuyPrices = getOrderLinesWithDifferentBuyPrices();
						if (!orderLinesWithDifferentBuyPrices.length || isEntityReactivated(dataToUse)) {
							formMethods.handleSubmit((formData) => onSubmit([], formData))();
						} else {
							setOrderLinesWithDifferentBuyPrices(orderLinesWithDifferentBuyPrices);
							setDisplayPriceUpdateModal(true);
						}
					}}
				/>
			)}
			{displayPriceUpdateModal && (
				<PriceUpdateModal
					data={orderLinesWithDifferentBuyPrices}
					onSubmit={({ products }) => {
						// Reset the state
						setOrderLinesWithDifferentBuyPrices([]);
						setDisplayPriceUpdateModal(false);

						// Submit the form with data
						formMethods.handleSubmit((formData) => onSubmit(products, formData))();
					}}
					cancel={() => {
						// Reset the state
						setOrderLinesWithDifferentBuyPrices([]);
						setDisplayPriceUpdateModal(false);
					}}
				/>
			)}
			{renderAsModal ? (
				<>
					<Modal.Header closeButton>
						<Modal.Title>{t(title)}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{shouldShowProcessingIndicator ? (
							<LoadSpinner inline title={t(uiText.receiveProduct.loading.IN_PROCESS)} />
						) : (
							inputs
						)}
					</Modal.Body>
					{!shouldShowProcessingIndicator ? (
						<Modal.Footer>
							<div className="w-100">{buttons}</div>
						</Modal.Footer>
					) : null}
				</>
			) : (
				<>
					<Layout.Header>
						<Layout.Title title={t(title)} />
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						{shouldShowProcessingIndicator && <LoadSpinner title={t(uiText.receiveProduct.loading.IN_PROCESS)} />}
						<div hidden={shouldShowProcessingIndicator} className="bg-white pb-0_5 me-n2_5">
							{inputs}
							{buttons}
						</div>
					</Layout.Body>
				</>
			)}
		</>
	);
}

export default withFormModalSuspenseWrapper<ReceiveProductFormProps>({
	loadingLabel: uiText.receiveProduct.loading.LOADING,
	getTitle,
})(ReceiveProductForm);
