import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { C_PaymentForDebtDocument, C_PaymentForDebtQuery } from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useListPageFunctionality from '../../hooks/useListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useStateWithReset from '../../hooks/useStateWithReset';
import { businessPartnerGroupName, DBFilter, DocumentStatus, PaymentDB } from '../../models';
import ListPageState from '../../models/ListPageState';
import { exception } from '../../utils/analytics';
import { pageUuid } from '../../utils/Constants';
import { formatDate } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import { formatNumber } from '../../utils/NumberUtil';
import BHGraphQLTable from '../BHTable/BHGraphQLTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import StatusBadge from '../StatusBadge/StatusBadge';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import PaymentForm from './PaymentForm';

const availableFilters = {
	[uiText.payment.filter.ONLY_OPEN_BALANCES]: DBFilter<PaymentDB>()
		.property('C_BPartner.totalopenbalance')
		.isGreaterThan(0),
} as const;

/**
 * Main Payments page. Shows a list of active Payments
 */
const Payments = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();

	const { disableWrite } = useActionPrivileges(pageUuid.PAYMENTS);
	const [searchText, setSearchText] = useState('');
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useListPageFunctionality<C_PaymentForDebtQuery['C_PaymentGet']['Results'][0]>({
		fetch: useCallback(
			async (variables) =>
				(
					await graphqlClient.query({
						query: C_PaymentForDebtDocument,
						variables,
						fetchPolicy: 'network-only',
					})
				).data.C_PaymentGet,
			[graphqlClient],
		),
		onError: useCallback(
			(error) => {
				if (error.response) {
					toast.error(t(uiText.payment.loading.FAIL));
				}
				exception({ description: `Payment fetch error: ${error}` });
			},
			[t],
		),
		refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
	});

	// Filter state
	const [onlyOpenBalancesFilter, setOnlyOpenBalancesFilter, { reset: resetOnlyOpenBalancesFilter }] =
		useStateWithReset(false);

	// Handle searching and filtering
	useEffect(() => {
		let defaultFilter = DBFilter<PaymentDB>()
			.property('isReceipt')
			.equals(true)
			.property('bh_visit_id')
			.isNull()
			.property('docStatus')
			.isNotIn([DocumentStatus.REVERSED, DocumentStatus.VOIDED])
			.nested('c_bpartner')
			.nested('c_bp_group')
			.property('name')
			.equals(businessPartnerGroupName.PATIENTS)
			.up()
			.up();

		if (onlyOpenBalancesFilter) {
			defaultFilter.and(availableFilters[uiText.payment.filter.ONLY_OPEN_BALANCES]);
		}
		if (searchText) {
			defaultFilter.and(DBFilter<PaymentDB>().property('C_BPartner.name').contains(searchText));
		}

		onFilterUpdate(defaultFilter.toString());
	}, [searchText, onFilterUpdate, onlyOpenBalancesFilter]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		resetOnlyOpenBalancesFilter();
		setSearchText('');
		reset();
	});

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.payment.title.LIST)}
							showRefreshIcon
							onRefresh={() => {
								refresh({ resetPage: true });
							}}
							areRefreshing={areRefreshing}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search initialText={searchText} onSearch={setSearchText} />
							<WorkspaceMenu.Filters>
								<Form.Group className="mt-2" controlId="onlyOpenBalances">
									<Form.Check
										checked={onlyOpenBalancesFilter}
										onChange={(e) => setOnlyOpenBalancesFilter(e.target.checked)}
										label={t(uiText.payment.filter.ONLY_OPEN_BALANCES)}
									/>
								</Form.Group>
							</WorkspaceMenu.Filters>
							{!disableWrite && <WorkspaceMenu.NewButton onClick={() => setViewState(ListPageState.ADD_EDIT)} />}
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							<BHGraphQLTable<C_PaymentForDebtQuery['C_PaymentGet']['Results'][0]>
								data={data}
								columns={[
									{
										id: 'dateTrx',
										Header: () => <div className={'React-table-header'}>{t(uiText.payment.TRANSACTION_DATE)}</div>,
										accessor: (d) => formatDate(new Date(d.DateTrx)),
									},
									{
										id: 'C_BPartner.name',
										Header: () => <div className={'React-table-header'}>{t(uiText.payment.PATIENT_NAME)}</div>,
										accessor: (d) => d.C_BPartner.Name,
									},
									{
										id: 'payAmt',
										Header: () => <div className={'React-table-header'}>{t(uiText.payment.PAYMENT_AMOUNT)}</div>,
										accessor: (d) => <div className="d-flex justify-content-center">{formatNumber(d.PayAmt)}</div>,
										disableSortBy: true,
									},
									{
										id: 'patient.totalOpenBalance',
										Header: () => <div className={'React-table-header'}>{t(uiText.patient.OPEN_BALANCE)}</div>,
										accessor: (d) => (
											<div className="d-flex justify-content-center">
												{formatNumber(d.C_BPartner.TotalOpenBalance!)}
											</div>
										),
										disableSortBy: true,
									},
									{
										id: 'docStatus',
										Header: () => <div className={'React-table-header'}>{t(uiText.payment.PAYMENT_STATUS)}</div>,
										accessor: (d) => <StatusBadge documentStatus={d.DocStatus} />,
										disableSortBy: true,
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => {
									return <LoadSpinner show={isLoading} title={t(uiText.payment.loading.LOADING)} />;
								}}
								onFetchData={onTableUpdate}
								getTrGroupProps={rowProperties}
								sortBy={sorted}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : (
				<PaymentForm
					uuid={selectedUuid}
					onFinish={() => {
						setViewState(ListPageState.LIST);
						refresh();
					}}
				/>
			)}
		</Layout>
	);
};

export default Payments;
