import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import {
	Ad_Ref_ListDiagnosticStatusesQuery,
	Bh_ConceptDiagnosticDataForVisitsFragmentDoc,
	Bh_ConceptDiagnosticGetForVisitsDocument,
	Bh_ConceptDiagnosticGetForVisitsQuery,
} from '../../graphql/__generated__/graphql';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import { ConceptClass, ConceptDataType, EncounterDiagnosticStatusCodeUU } from '../../models';
import { getTestQAndAFilter } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import {
	getConceptMaximumValue,
	getConceptMinmumValue,
	getConceptName,
	getConceptSearchTerms,
	getLabTestResultReferenceRange,
	isLabTestResultOutsideReferenceRange,
} from '../../utils/ModelUtils';
import DynamicSelect from '../dynamic-select/DynamicSelect';
import EntityLookup from '../entity-lookup/EntityLookup';
import { LabDiagnosticsTableFormValues } from './LabDiagnosticsTable';
import { VisitFormValues } from './VisitForm';

const dividerUU = 'c388db5d-723f-4b35-a9a2-f42e323ce0e9';

type LabDiagnosticsTableRowProps = {
	fieldPrefix: string;
	remove?: () => void;
	isAddRow?: boolean;
	readOnly?: boolean;
	conceptTestsAndPanels?: Bh_ConceptDiagnosticGetForVisitsQuery['BH_ConceptGet']['Results'];
	diagnosticStatuses?: Ad_Ref_ListDiagnosticStatusesQuery['AD_Ref_ListGet']['Results'];
};

const LabDiagnosticsTableRow = ({
	fieldPrefix,
	remove,
	isAddRow = false,
	readOnly,
	conceptTestsAndPanels = [],
	diagnosticStatuses = [],
}: LabDiagnosticsTableRowProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { register, setValue, getValues } = useFormContext<VisitFormValues>();

	const visitUuid = getValues('UU');
	const lastSelectedConceptUuid = useRef<string | undefined>(undefined);
	const selectedConceptUU = useWatch<LabDiagnosticsTableFormValues, 'encounterDiagnostics.0.BH_Concept.UU'>({
		name: `${fieldPrefix}.BH_Concept.UU` as 'encounterDiagnostics.0.BH_Concept.UU',
	});
	const resultValue = useWatch<LabDiagnosticsTableFormValues, 'encounterDiagnostics.0.BH_Value'>({
		name: `${fieldPrefix}.BH_Value` as 'encounterDiagnostics.0.BH_Value',
	});
	// The concept stored in the form may have had some extras and mappings removed to speed
	// up the form. So, retrieve the full original concept as well.
	const selectedConcept = graphqlClient.readFragment({
		id: selectedConceptUU,
		fragment: Bh_ConceptDiagnosticDataForVisitsFragmentDoc,
		fragmentName: 'BH_ConceptDiagnosticDataForVisits',
	});
	const refRange = selectedConcept ? getLabTestResultReferenceRange(selectedConcept, true) : '';
	const [referenceRange, setReferenceRange] = useState(refRange);

	const [testResults, onSearchTestResults] = useCustomAsyncFn(
		async (conceptUU: string) => {
			if (!conceptUU) {
				return [];
			}

			const response =
				(
					await graphqlClient.query({
						query: Bh_ConceptDiagnosticGetForVisitsDocument,
						variables: {
							Filter: getTestQAndAFilter(conceptUU).toString(),
						},
						fetchPolicy: 'cache-first',
					})
				)?.data.BH_ConceptGet.Results || [];

			const testResults = response?.flatMap((concept) => (concept.BH_Display_Name ? concept.BH_Display_Name : []));
			return testResults;
		},
		[graphqlClient],
	);

	// If new test results have loaded, make sure the correct drop-down item is selected
	useEffect(() => {
		if (testResults) {
			setValue(
				`${fieldPrefix}.BH_Value` as 'encounterDiagnostics.0.BH_Value',
				getValues(`${fieldPrefix}.BH_Value` as 'encounterDiagnostics.0.BH_Value'),
			);
		}
	}, [testResults, fieldPrefix, setValue, getValues]);

	// Handle several changes if the selected test was changed
	useEffect(() => {
		// If the data type of the new test is "CODED", get the list of possible test results to populate the "Results" drop-down list
		if (selectedConcept && selectedConcept.UU && selectedConcept.BH_Data_Type === ConceptDataType.CODED) {
			// Ensure we don't do unnecessary calls if the requests for this concept were already loaded
			if (lastSelectedConceptUuid.current !== selectedConcept.UU) {
				onSearchTestResults(selectedConcept.UU);
			}
		}

		// If there was a test selected, and it was switched to a new one, clear the result field as it should
		// no longer apply and update the reference range
		if (selectedConcept && lastSelectedConceptUuid.current && selectedConcept.UU !== lastSelectedConceptUuid.current) {
			setValue(`${fieldPrefix}.BH_Value` as 'encounterDiagnostics.0.BH_Value', null);
			setReferenceRange(getLabTestResultReferenceRange(selectedConcept, true));
		} else if (!selectedConcept) {
			// If the lab test was reset to blank, clear the reference range as well
			setReferenceRange('');
		}

		// Finally, ensure the lastSelectedConceptUuid is updated if necessary
		if (lastSelectedConceptUuid.current !== selectedConcept?.UU) {
			lastSelectedConceptUuid.current = selectedConcept?.UU;
		}
	}, [selectedConcept, onSearchTestResults, lastSelectedConceptUuid, setValue, fieldPrefix, graphqlClient]);

	// If a result value was entered/selected, mark the status as Complete
	useUpdateEffect(() => {
		if (
			resultValue &&
			getValues(`${fieldPrefix}.BH_Diagnostic_Status.UU` as 'encounterDiagnostics.0.BH_Diagnostic_Status.UU') !==
				EncounterDiagnosticStatusCodeUU.Complete
		) {
			setValue(
				`${fieldPrefix}.BH_Diagnostic_Status.UU` as 'encounterDiagnostics.0.BH_Diagnostic_Status.UU',
				EncounterDiagnosticStatusCodeUU.Complete,
			);
		}
	}, [resultValue, fieldPrefix, setValue, getValues]);

	const [{ value: filteredConceptOptions, loading: areLoadingConcepts }, onSearchConcepts] = useCustomAsyncFn(
		async (query: string) => {
			// Initially, before a query is entered, just show all the panels
			if (query === '') {
				return conceptTestsAndPanels.filter((concept) => concept.bh_concept_class === ConceptClass.LAB_SET);
			}

			// Build a filtered list using the available search terms
			const filteredList = conceptTestsAndPanels.filter((concept) =>
				getConceptSearchTerms(concept).some((term) => term.indexOf(query.toLowerCase()) >= 0),
			);

			// Push a dividing line between panels and tests (if both exist in the filtered list)
			let hasPanels = false;
			for (let i = 0; i < filteredList.length; i++) {
				if (filteredList[i].bh_concept_class === ConceptClass.LAB_SET) {
					hasPanels = true;
				} else if (filteredList[i].bh_concept_class === ConceptClass.TEST) {
					if (hasPanels) {
						filteredList.splice(i, 0, { UU: dividerUU, IsActive: true });
					}
					break;
				}
			}

			return filteredList;
		},
		[conceptTestsAndPanels],
	);

	// For some reason the visit history doesn't show the default value and won't event do it if we get it below,
	// so just set it here to be used below
	const defaultConcept = getValues(`${fieldPrefix}.BH_Concept` as 'encounterDiagnostics.0.BH_Concept');

	return (
		<tr>
			<td className="align-middle">
				{!isAddRow && <input type="hidden" {...register(`${fieldPrefix}.UU` as 'encounterDiagnostics.0.UU')} />}
				<EntityLookup<LabDiagnosticsTableFormValues, 'encounterDiagnostics.0.BH_Concept'>
					name={`${fieldPrefix}.BH_Concept` as 'encounterDiagnostics.0.BH_Concept'}
					rules={{ required: !isAddRow }}
					isLoading={areLoadingConcepts}
					id={`${fieldPrefix}.concept${visitUuid}`}
					delay={500}
					inputProps={{ 'aria-label': t(uiText.visit.form.diagnostic.TEST) }}
					emptyLabel={t(uiText.visit.form.diagnostic.NOT_FOUND)}
					labelKey={(data) => {
						if (data.UU === dividerUU) {
							return '-------';
						}
						return getConceptName(
							graphqlClient.readFragment({ id: data.UU, fragment: Bh_ConceptDiagnosticDataForVisitsFragmentDoc }),
						);
					}}
					minLength={0}
					placeholder={t(uiText.visit.form.diagnostic.SEARCH)}
					promptText={t(uiText.visit.form.diagnostic.SEARCHING)}
					searchText={t(uiText.visit.form.diagnostic.SEARCHING)}
					options={filteredConceptOptions || []}
					onSearch={onSearchConcepts}
					disabled={readOnly}
					onInputChange={(text) => {
						if (text === '') {
							onSearchConcepts('');
						}
					}}
					onFocus={(event) => {
						if ((event?.target as any)?.value === '') {
							onSearchConcepts('');
						}
					}}
					// For some reason the visit history doesn't show the default value, so just pass it in for safety
					defaultValue={defaultConcept}
				/>
			</td>
			<td className="align-middle">
				{selectedConcept?.BH_Data_Type === ConceptDataType.CODED ? (
					<DynamicSelect
						aria-label={t(uiText.visit.form.diagnostic.RESULT)}
						isLoading={testResults.loading}
						className="form-control"
						{...register(`${fieldPrefix}.BH_Value` as 'encounterDiagnostics.0.BH_Value', {
							validate: () => {
								return true;
							},
						})}
					>
						<option value=""></option>
						{testResults.value?.map((result) => (
							<option key={result} value={result}>
								{result}
							</option>
						))}
					</DynamicSelect>
				) : selectedConcept?.BH_Data_Type === ConceptDataType.NUMERIC ? (
					<Form.Control
						aria-label={t(uiText.visit.form.diagnostic.RESULT)}
						className={isLabTestResultOutsideReferenceRange(resultValue, selectedConcept) ? 'text-danger' : ''}
						placeholder={
							// Pick the appropriate placeholder, depending on whether minimum and/or maximum values are defined
							getConceptMinmumValue(selectedConcept) !== undefined &&
							getConceptMaximumValue(selectedConcept) !== undefined
								? t(uiText.visit.form.diagnostic.PLACEHOLDER_NUMERIC_BETWEEN, {
										minimumValue: getConceptMinmumValue(selectedConcept),
										maximumValue: getConceptMaximumValue(selectedConcept),
									})
								: getConceptMinmumValue(selectedConcept) !== undefined
									? t(uiText.visit.form.diagnostic.PLACEHOLDER_NUMERIC_GREATER, {
											minimumValue: getConceptMinmumValue(selectedConcept),
										})
									: getConceptMaximumValue(selectedConcept) !== undefined
										? t(uiText.visit.form.diagnostic.PLACEHOLDER_NUMERIC_LESS, {
												maximumValue: getConceptMaximumValue(selectedConcept),
											})
										: t(uiText.visit.form.diagnostic.PLACEHOLDER_NUMERIC)
						}
						{...register(`${fieldPrefix}.BH_Value` as 'encounterDiagnostics.0.BH_Value', {
							validate: (value) => {
								// If the field is blank, that is fine, but otherwise, check if it is a number, and within the right range
								if (!value) {
									return;
								}
								if (!/^-?\d+(\.\d+)?$/.test(value)) {
									return t(uiText.visit.error.TEST_RESULT_MUST_BE_NUMBER, {
										testName: getConceptName(selectedConcept),
									});
								}
								if (
									getConceptMinmumValue(selectedConcept) !== undefined &&
									Number(value) < getConceptMinmumValue(selectedConcept)!
								) {
									return t(uiText.visit.error.TEST_RESULT_MUST_BE_GREATER, {
										testName: getConceptName(selectedConcept),
										minimumValue: getConceptMinmumValue(selectedConcept),
									});
								}
								if (
									getConceptMaximumValue(selectedConcept) !== undefined &&
									Number(value) > getConceptMaximumValue(selectedConcept)!
								) {
									return t(uiText.visit.error.TEST_RESULT_MUST_BE_LESS, {
										testName: getConceptName(selectedConcept),
										maximumValue: getConceptMaximumValue(selectedConcept),
									});
								}
							},
						})}
					/>
				) : (
					<Form.Control
						aria-label={t(uiText.visit.form.diagnostic.RESULT)}
						placeholder={
							selectedConcept?.BH_Data_Type === ConceptDataType.TEXT
								? t(uiText.visit.form.diagnostic.PLACEHOLDER_TEXT)
								: ''
						}
						{...register(`${fieldPrefix}.BH_Value` as 'encounterDiagnostics.0.BH_Value', {
							validate: () => {
								return true;
							},
						})}
					/>
				)}
			</td>
			<td>
				<Form.Control
					aria-label={t(uiText.visit.form.diagnostic.REFERENCE_RANGE)}
					value={referenceRange}
					readOnly={true}
				/>
			</td>
			<td className="align-middle">
				<Form.Select
					aria-label={t(uiText.visit.form.diagnostic.STATUS)}
					className="form-control"
					{...register(`${fieldPrefix}.BH_Diagnostic_Status.UU` as 'encounterDiagnostics.0.BH_Diagnostic_Status.UU')}
				>
					{diagnosticStatuses?.map((status) => (
						<option key={status.UU} value={status.UU}>
							{status.Name}
						</option>
					))}
				</Form.Select>
			</td>
			{!readOnly && (
				<td className="print__d-none align-middle text-center">
					{!isAddRow && (
						<button
							type="button"
							aria-label={t(uiText.visit.form.diagnostic.DELETE_DIAGNOSTIC)}
							className="btn p-0 w-100"
							tabIndex={-1}
							id={`${fieldPrefix}.delete`}
							onClick={() => remove?.()}
						>
							<FontAwesomeIcon icon="trash" />
						</button>
					)}
				</td>
			)}
		</tr>
	);
};

export default LabDiagnosticsTableRow;
