import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import PasswordInput from '../password-input/PasswordInput';

type ChangePasswordProps = {
	areLoading: boolean;
	securityQuestions?: string[];
	onSubmit: (newPassword: string) => void;
	serverErrorMessage?: string;
};

export default function ChangePassword({
	areLoading,
	securityQuestions,
	onSubmit,
	serverErrorMessage,
}: ChangePasswordProps) {
	const [newPassword, setNewPassword] = useState('');
	const [retypedNewPassword, setRetypedNewPassword] = useState('');
	const [errorMessageToDisplay, setErrorMessageToDisplay] = useState(serverErrorMessage);
	const [hasFormBeenSubmitted, setHasFormBeenSubmitted] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		arePasswordsValid(hasFormBeenSubmitted);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasFormBeenSubmitted, newPassword, retypedNewPassword]);

	useEffect(() => {
		setErrorMessageToDisplay(serverErrorMessage);
	}, [serverErrorMessage]);

	const onSubmitClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setHasFormBeenSubmitted(true);
		if (!arePasswordsValid(true)) {
			return;
		}
		onSubmit(newPassword);
	};

	/**
	 * This function updates the desired state with the specific value passed in
	 * @param {function} stateUpdateFunction A function to update state for the specific value
	 */
	const updateInput =
		(stateUpdateFunction: (inputValue: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
			stateUpdateFunction(event.target.value);
		};

	const arePasswordsValid = (shouldDisplayErrors: boolean) => {
		if (!newPassword) {
			if (shouldDisplayErrors) {
				setErrorMessageToDisplay(t(uiText.changePassword.error.NO_NEW_PASSWORD_ENTERED));
			}
			return false;
		}
		if (newPassword !== retypedNewPassword) {
			if (shouldDisplayErrors) {
				setErrorMessageToDisplay(t(uiText.changePassword.error.PASSWORDS_DIFFER));
			}
			return false;
		}
		setErrorMessageToDisplay('');
		return true;
	};

	return (
		<>
			{errorMessageToDisplay ? (
				<Row>
					<Col xs={8} className="p-1 mb-4 m-auto bg-danger text-center rounded text-white">
						<span>{errorMessageToDisplay}</span>
					</Col>
				</Row>
			) : null}
			<Form.Group as={Row} className="my-2" controlId="newPassword">
				<Form.Label column className="d-flex align-items-center">
					{t(uiText.login.changePassword.NEW_PASSWORD)}
				</Form.Label>
				<Col xs={7} className="d-flex align-items-center">
					<PasswordInput
						placeholder={t(uiText.login.changePassword.NEW_PASSWORD)}
						value={newPassword}
						onChange={updateInput(setNewPassword)}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="my-2" controlId="retypedNewPassword">
				<Form.Label column className="d-flex align-items-center">
					{t(uiText.login.changePassword.RETYPE_PASSWORD)}
				</Form.Label>
				<Col xs={7} className="d-flex align-items-center">
					<PasswordInput
						placeholder={t(uiText.login.changePassword.RETYPE_PASSWORD)}
						value={retypedNewPassword}
						onChange={updateInput(setRetypedNewPassword)}
					/>
				</Col>
			</Form.Group>
			<Row>
				<Col className="text-end">
					<Button variant="info" type="submit" className="pull-right" onClick={onSubmitClick} disabled={areLoading}>
						<FontAwesomeIcon icon={faCheck} className="me-2" />
						<span>{t(uiText.login.changePassword.CHANGE)}</span>
					</Button>
				</Col>
			</Row>
		</>
	);
}
