import { ApolloClient } from '@apollo/client';
import { sortBy } from 'lodash';
import { Fragment } from 'react';
import { Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import {
	Bh_EncounterInput,
	Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
	Bh_ObservationInput,
	Bh_VisitForEditingQuery,
} from '../../graphql/__generated__/graphql';
import { EncounterTypeUU, EncounterTypeWindowUU } from '../../models';
import { uiText } from '../../utils/Language';
import PrintableTextArea from '../PrintableTextArea/PrintableTextArea';
import ObservationTable, {
	constructObservationTableFormDataToSave,
	convertToObservationTableFormFields,
	ObservationTableFormValues,
	primeObservationTableData,
} from './ObservationTable';
import { VisitFormValues } from './VisitForm';

export type TriageDetailsFormValues = {
	chiefComplaintEncounter: {
		UU: string;
		BH_Encounter_Date: Date;
		BH_Encounter_Type: { UU: string };
		BH_Observations: Array<{ UU: string; AD_Field: { UU: string }; BH_Value: string | null }>;
	};
} & ObservationTableFormValues;

export const convertToTriageDetailsFormFields: (
	graphqlClient: ApolloClient<object>,
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
) => TriageDetailsFormValues = (graphqlClient, initialData) => {
	const chiefComplaintEncounterTypeWindow = graphqlClient.readFragment({
		id: EncounterTypeWindowUU.ChiefComplaint,
		fragment: Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
		fragmentName: 'BH_Encounter_Type_WindowDataForVisits',
	});
	// If the user doesn't have access to this window (and the encounter type hasn't loaded), just return
	if (!chiefComplaintEncounterTypeWindow) {
		return {
			chiefComplaintEncounter: {
				UU: '',
				BH_Encounter_Date: new Date(),
				BH_Encounter_Type: { UU: '' },
				BH_Observations: [],
			},
			...convertToObservationTableFormFields(graphqlClient, initialData),
		};
	}
	const sortedFields = sortBy(chiefComplaintEncounterTypeWindow.AD_Window.AD_Tabs?.[0].AD_Fields || [], 'SeqNo');
	const emptyChiefComplaintEncounter: TriageDetailsFormValues['chiefComplaintEncounter'] = {
		UU: v4(),
		BH_Encounter_Date: new Date(),
		BH_Encounter_Type: { UU: chiefComplaintEncounterTypeWindow.BH_Encounter_Type.UU },
		BH_Observations:
			sortedFields.map((field) => ({
				UU: v4(),
				AD_Field: { UU: field.UU },
				BH_Value: null,
			})) || [],
	};

	if (!initialData) {
		return {
			chiefComplaintEncounter: emptyChiefComplaintEncounter,
			...convertToObservationTableFormFields(graphqlClient, initialData),
		};
	}
	const chiefComplaintEncounter = initialData.BH_Encounters?.filter(
		(encounter) => encounter.BH_Encounter_Type.UU === chiefComplaintEncounterTypeWindow.BH_Encounter_Type.UU,
	)[0];
	return {
		chiefComplaintEncounter: chiefComplaintEncounter
			? {
					UU: chiefComplaintEncounter.UU,
					BH_Encounter_Date: new Date(chiefComplaintEncounter.BH_Encounter_Date),
					BH_Encounter_Type: { UU: chiefComplaintEncounter.BH_Encounter_Type.UU },
					BH_Observations:
						sortedFields.map((field) => {
							const observationToUse = chiefComplaintEncounter.BH_Observations?.find(
								(observation) => observation.AD_Field.UU === field.UU,
							);
							return {
								UU: observationToUse?.UU || v4(),
								AD_Field: { UU: field.UU },
								BH_Value: observationToUse?.BH_Value || null,
							};
						}) || [],
				}
			: emptyChiefComplaintEncounter,
		...convertToObservationTableFormFields(graphqlClient, initialData),
	};
};

export const constructTriageDetailsFormDataToSave = (
	data: VisitFormValues,
	graphqlClient: ApolloClient<object>,
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
): [{ save: Bh_EncounterInput[]; ignore: string[] }, { save: Bh_ObservationInput[]; ignore: string[] }] => {
	const encountersToSave: Bh_EncounterInput[] = [];
	const encounterUUsToIgnore: string[] = [];
	const observationsToSave: Bh_ObservationInput[] = [];
	const observationUUsToIgnore: string[] = [];
	// If we didn't have access to the chief complaint diagnostics encounters, we need to set the correct things to ignore
	if (data.chiefComplaintEncounter.UU === '') {
		// Get the encounter we're dealing with in this "window" so we can ignore those
		let encountersToIgnore =
			initialData?.BH_Encounters?.filter(
				(encounter) => encounter.BH_Encounter_Type.UU === EncounterTypeUU.ChiefComplaint,
			) || [];
		encounterUUsToIgnore.push(...encountersToIgnore.map((encounter) => encounter.UU));
		observationUUsToIgnore.push(
			...encountersToIgnore.flatMap((encounter) =>
				encounter.BH_Observations?.length ? encounter.BH_Observations.map((observation) => observation.UU) : [],
			),
		);
	} else {
		const chiefComplaintEncounter: Bh_EncounterInput = {
			UU: data.chiefComplaintEncounter.UU,
			BH_Encounter_Date: data.chiefComplaintEncounter.BH_Encounter_Date.getTime(),
			BH_Encounter_Type: data.chiefComplaintEncounter.BH_Encounter_Type,
			BH_Visit: { UU: data.UU },
			IsActive: true,
		};
		// RHF version 7.12.2 doesn't always reset arrays, so make sure the optional operator is used
		// (7.13.0+ does, but they cause other errors that haven't been fixed up to 7.53.0)
		const chiefComplaintEncounterObservations: Bh_ObservationInput[] =
			data.chiefComplaintEncounter.BH_Observations?.filter((observation) => !!observation.BH_Value).map(
				(observation) => ({
					UU: observation.UU,
					AD_Field: { UU: observation.AD_Field.UU },
					BH_Encounter: { UU: data.chiefComplaintEncounter.UU },
					BH_Value: observation.BH_Value,
					IsActive: true,
				}),
			) || [];
		if (chiefComplaintEncounterObservations.length) {
			encountersToSave.push(chiefComplaintEncounter);
			observationsToSave.push(...chiefComplaintEncounterObservations);
		}
	}
	const [
		{ save: observationTableEncounters, ignore: observationTableEncounterUUsToIgnore },
		{ save: observationTableObservations, ignore: observationTableObservationUUsToIgnore },
	] = constructObservationTableFormDataToSave(data, graphqlClient, initialData);
	return [
		{
			// If there are no chief complaint observations, we don't need to save an encounter
			save: [...encountersToSave, ...observationTableEncounters],
			ignore: [...encounterUUsToIgnore, ...observationTableEncounterUUsToIgnore],
		},
		{
			save: [...observationsToSave, ...observationTableObservations],
			ignore: [...observationUUsToIgnore, ...observationTableObservationUUsToIgnore],
		},
	];
};

// Let the parent fetch the encounter type windows
export const primeTriageDetailsData = () => primeObservationTableData();

type TriageDetailsProps = {
	isDataReadOnly?: boolean;
	reviewHistory?: boolean;
	loading?: boolean;
};

const TriageDetails = ({ isDataReadOnly, reviewHistory = false, loading = false }: TriageDetailsProps) => {
	const { getValues } = useFormContext<VisitFormValues>();
	const visitUuid = getValues('UU');
	const { t } = useTranslation();

	return (
		<Card className="bh-card">
			<Card.Header className="d-flex">
				<div className="fw-bold h5 mb-0">{t(uiText.visit.form.VITALS)}</div>
				{loading && (
					<div className="ms-auto fw-light">
						<small>{t(uiText.visit.LOADING_MORE_DETAILS)}</small>{' '}
						<Spinner animation="border" size="sm" className="align-middle" role="status" />
					</div>
				)}
			</Card.Header>
			<Card.Body>
				<fieldset disabled={isDataReadOnly} className="w-100">
					<Row className="gy-3">
						<ObservationTable visitUuid={visitUuid} isDataReadOnly={isDataReadOnly || reviewHistory} />

						<Form.Group as={Fragment} controlId={`chiefComplaintEncounter${visitUuid}`}>
							<Col xs={1}>
								<Form.Label column>{t(uiText.visit.form.patient.CHIEF_COMPLAINT)}</Form.Label>
							</Col>
							<Col xs={11} className="d-flex align-items-center">
								<Controller<TriageDetailsFormValues, 'chiefComplaintEncounter.BH_Observations.0.BH_Value'>
									aria-label={t(uiText.visit.form.patient.CHIEF_COMPLAINT)}
									name={'chiefComplaintEncounter.BH_Observations.0.BH_Value'}
									render={({ field }) => <PrintableTextArea rows={6} {...field} value={field.value || ''} />}
								/>
							</Col>
						</Form.Group>
					</Row>
				</fieldset>
			</Card.Body>
		</Card>
	);
};

export default TriageDetails;
