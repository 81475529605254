import { useApolloClient } from '@apollo/client';
import { startOfDay } from 'date-fns';
import { Fragment, Suspense } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import {
	Ad_Ref_ListStockUpdateReasonsDocument,
	C_DocTypeForFormsDocument,
	M_AttributeSetInstanceSaveForStockUpdateDocument,
	M_InventoryForStockUpdateModalSaveWithInventoryLinesDocument,
	M_InventoryInput,
	M_InventoryLineInput,
	M_StorageOnHandForInventoryListQuery,
} from '../../graphql/__generated__/graphql';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import {
	AttributeSetInstanceUU,
	DBFilter,
	documentBaseType,
	DocumentStatus,
	documentSubTypeInventory,
	referenceUuids,
} from '../../models';
import DocAction from '../../models/DocAction';
import { ReferenceListDB } from '../../models/ReferenceList';
import { uiText } from '../../utils/Language';
import { getDocumentBaseTypeFilter } from '../../utils/ModelUtils';
import BasicButton from '../ActionButtons/BasicButton';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import LoadSpinner from '../LoadSpinner/LoadSpinner';

type StockUpdateModalFields = {
	QtyOnHand: number;
	M_AttributeSetInstance: { UU: string; GuaranteeDate: Date | null };
	bh_update_reason: { UU: string };
};

const fetchInventoryDocumentTypeArguments = [
	documentBaseType.MaterialPhysicalInventory,
	null,
	documentSubTypeInventory.PhysicalInventory,
	false,
	false,
	false,
] as const;

type StockUpdateModalProps = {
	selectedData: M_StorageOnHandForInventoryListQuery['M_StorageOnHandGet']['Results'][0];
	onFinish: (refresh: boolean) => void;
};

const StockUpdateModalInternal = ({ selectedData, onFinish }: StockUpdateModalProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();

	const { data: [stockUpdateReasons, inventoryListDocType] = [] } = useSuspenseAsync(
		'stock-update' + selectedData.UU,
		async () =>
			Promise.all([
				graphqlClient
					.query({
						query: Ad_Ref_ListStockUpdateReasonsDocument,
						variables: {
							Filter: DBFilter<ReferenceListDB>()
								.nested('ad_reference')
								.property('ad_reference_uu')
								.equals(referenceUuids.STOCK_UPDATE_REASONS)
								.up()
								.toString(),
						},
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.AD_Ref_ListGet.Results),
				graphqlClient
					.query({
						query: C_DocTypeForFormsDocument,
						variables: {
							Filter: getDocumentBaseTypeFilter(...fetchInventoryDocumentTypeArguments).toString(),
						},
						fetchPolicy: 'cache-first',
					})
					.then((results) => results.data.C_DocTypeGet.Results[0]),
			]),
	);

	const formMethods = useForm<StockUpdateModalFields>({
		defaultValues: {
			QtyOnHand: selectedData.QtyOnHand,
			M_AttributeSetInstance: {
				UU: selectedData.M_AttributeSetInstance.UU,
				GuaranteeDate: selectedData.M_Product.M_AttributeSet?.IsGuaranteeDate
					? new Date(selectedData.M_AttributeSetInstance.GuaranteeDate!)
					: null,
			},
			bh_update_reason: { UU: '' },
		},
	});

	// If this is a "new" ASI (i.e. it's ID is 0 in the DB), they can't make this go positive
	const getMaxQuantityAllowed = () =>
		selectedData.M_AttributeSetInstance.UU === AttributeSetInstanceUU.Empty ? 0 : Infinity;

	const [, handleUpdateSave] = useCustomAsyncFn<SubmitHandler<StockUpdateModalFields>>(
		async (formData) => {
			let updateSuccessful = true;
			// Send the inventory update, if one was made
			if (formData.QtyOnHand !== selectedData.QtyOnHand) {
				const inventoryUU = v4();
				const inventory: M_InventoryInput = {
					UU: inventoryUU!,
					M_Warehouse: { UU: selectedData.M_Locator.M_Warehouse.UU },
					bh_update_reason: { UU: formData.bh_update_reason.UU },
					MovementDate: new Date().getTime(),
					C_DocType: { UU: inventoryListDocType?.UU! },
				};
				const inventoryLine: M_InventoryLineInput = {
					M_Inventory: { UU: inventoryUU },
					QtyCount: formData.QtyOnHand,
					Line: 10,
					M_Product: { UU: selectedData.M_Product.UU! },
					M_Locator: { UU: selectedData.M_Locator.UU! },
					M_AttributeSetInstance: { UU: selectedData.M_AttributeSetInstance?.UU! },
				};

				try {
					const updatedInventory = (
						await graphqlClient.mutate({
							mutation: M_InventoryForStockUpdateModalSaveWithInventoryLinesDocument,
							variables: {
								M_Inventory: inventory,
								M_InventoryLine: inventoryLine,
								UU: inventoryUU,
								DocumentAction: DocAction.COMPLETE,
							},
						})
					).data;
					if (updatedInventory?.M_InventoryProcess?.DocStatus.Value !== DocumentStatus.COMPLETED) {
						updateSuccessful = false;
						// If the data to update has no expiration and/or, assume it was an error where the product needs an expiration, but
						// still has quantity that didn't have an expiration and the user was trying to update this quantity
						if (!selectedData.M_AttributeSetInstance.UU && !selectedData.M_AttributeSetInstance.GuaranteeDate) {
							toast.error(t(uiText.inventory.updateQuantityProcess.error.PRODUCT_MISCONFIGURED));
						} else {
							// TODO Update the document processing to return the actual error so we can give the user (or ourselves) more information
							toast.error(t(uiText.inventory.updateQuantityProcess.error.ERROR_UPDATING_QUANTITY));
						}
					}
				} catch (error) {
					updateSuccessful = false;
					if (error && error instanceof Error) {
						toast.error(error.toString());
					}
				}
			}

			if (
				formData.M_AttributeSetInstance.GuaranteeDate &&
				formData.M_AttributeSetInstance.GuaranteeDate.getTime() !== selectedData.M_AttributeSetInstance.GuaranteeDate
			) {
				await graphqlClient.mutate({
					mutation: M_AttributeSetInstanceSaveForStockUpdateDocument,
					variables: {
						M_AttributeSetInstance: {
							UU: formData.M_AttributeSetInstance.UU,
							GuaranteeDate: formData.M_AttributeSetInstance.GuaranteeDate.getTime(),
							bh_update_reason: { UU: formData.bh_update_reason.UU },
						},
					},
				});
			}
			if (updateSuccessful) {
				onFinish(true);
			}
		},
		[selectedData, graphqlClient, t],
	);

	return (
		<Modal show={true}>
			<FormProvider {...formMethods}>
				<Form className="p-0">
					<Modal.Header>
						{t(uiText.inventory.QUANTITY_UPDATE)} {selectedData.M_Product.Name}
					</Modal.Header>
					<Modal.Body>
						<Row className="gy-2">
							<Form.Group as={Fragment} controlId="quantityOnHand">
								<Col xs={4} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.inventory.label.QUANTITY)}</Form.Label>
								</Col>
								<Col xs={8} className="d-flex align-items-center">
									<Controller<StockUpdateModalFields, 'QtyOnHand'>
										name="QtyOnHand"
										rules={{ max: getMaxQuantityAllowed(), min: 0, required: true }}
										render={({ field }) => (
											<FormatNumberInput step="0.01" {...field} displayAndUseZeroIfEmpty={false} />
										)}
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Fragment} controlId="expirationDate">
								<Col xs={4} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.inventory.label.EXPIRATION_DATE)}</Form.Label>
								</Col>
								<Col xs={8} className="d-flex align-items-center">
									<Controller<StockUpdateModalFields, 'M_AttributeSetInstance.GuaranteeDate'>
										name="M_AttributeSetInstance.GuaranteeDate"
										rules={{
											required:
												selectedData.M_Product.M_AttributeSet?.IsGuaranteeDate &&
												!!selectedData.M_AttributeSetInstance.GuaranteeDate,
										}}
										render={({ field }) => (
											<BandaDatePicker
												minDate={startOfDay(new Date())}
												{...field}
												value={undefined}
												selected={field.value}
												disabled={
													selectedData.M_AttributeSetInstance.UU === AttributeSetInstanceUU.Empty ||
													!selectedData.M_Product.M_AttributeSet?.IsGuaranteeDate
												}
											/>
										)}
									/>
									{formMethods.formState.errors?.M_AttributeSetInstance?.GuaranteeDate && (
										<span className="text-danger">
											{t(uiText.inventory.updateQuantityProcess.error.MISSING_EXPIRATION_DATE)}
										</span>
									)}
								</Col>
							</Form.Group>
							<Form.Group as={Fragment} controlId="updateReason">
								<Col xs={4} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.inventory.label.UPDATE_REASON)}</Form.Label>
								</Col>
								<Col xs={8} className="d-flex align-items-center">
									<Form.Select
										{...formMethods.register('bh_update_reason.UU', { required: true })}
										aria-label={t(uiText.inventory.label.UPDATE_REASON)}
									>
										<option value="">{t(uiText.inventory.SELECT_REASON)}</option>
										{stockUpdateReasons &&
											stockUpdateReasons
												.filter((stockUpdateReason) => stockUpdateReason.IsActive)
												.map((stockUpdateReason) => {
													return (
														<option key={stockUpdateReason.UU} value={stockUpdateReason.UU}>
															{stockUpdateReason.Name}
														</option>
													);
												})}
									</Form.Select>
									{formMethods.formState.errors?.bh_update_reason?.UU && (
										<span className="text-danger">
											{t(uiText.inventory.updateQuantityProcess.error.MISSING_UPDATE_REASON)}
										</span>
									)}
									{formMethods.formState.errors?.QtyOnHand && (
										<span className="text-danger">
											{t(uiText.inventory.updateQuantityProcess.error.UPDATING_EXPIRED_PRODUCT_QUANTITY)}
										</span>
									)}
								</Col>
							</Form.Group>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Row>
							<Col xs="auto" className="ms-auto">
								<BasicButton
									name={uiText.inventory.button.CANCEL}
									text={t(uiText.inventory.button.CANCEL)}
									variant="danger"
									icon="times"
									active={true}
									onClick={() => {
										onFinish(false);
									}}
								/>
							</Col>
							<Col xs="auto">
								<BasicButton
									name={uiText.inventory.button.SAVE}
									text={t(uiText.inventory.button.SAVE)}
									variant="success"
									icon="check"
									active={true}
									onClick={formMethods.handleSubmit(handleUpdateSave)}
								/>
							</Col>
						</Row>
					</Modal.Footer>
				</Form>
			</FormProvider>
		</Modal>
	);
};

export default function StockUpdateModal(props: StockUpdateModalProps) {
	const { t } = useTranslation();
	return (
		<Suspense
			fallback={
				<Modal show={true}>
					<Modal.Header>
						<Modal.Title>
							{t(uiText.inventory.QUANTITY_UPDATE)} {props.selectedData.M_Product.Name}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<LoadSpinner inline title={t(uiText.inventory.loading.LOADING)} />
					</Modal.Body>
				</Modal>
			}
		>
			<StockUpdateModalInternal {...props} />
		</Suspense>
	);
}
