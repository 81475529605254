import { isApolloError, useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, Suspense, useContext } from 'react';
import { Button, Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import UserContext from '../../contexts/UserContext';
import {
	Bh_VisitInput,
	Bh_VisitProcessDocument,
	Bh_VisitSaveDocument,
	C_BPartnerForPharmacySalesDocument,
	C_BPartnerForPharmacySalesQuery,
	C_DocTypeForFormsDocument,
	C_OrderLineSaveManyForVisitsDocument,
	C_OrderSaveManyForVisitsDocument,
	C_PaymentSaveManyForVisitsDocument,
	ReportOutput,
} from '../../graphql/__generated__/graphql';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import {
	BusinessPartnerDB,
	businessPartnerGroupName,
	DBFilter,
	documentBaseType,
	documentSubTypeSalesOrder,
	referenceListUuids,
} from '../../models';
import DocAction from '../../models/DocAction';
import { PATIENT_RECEIPT } from '../../models/Report';
import { exception } from '../../utils/analytics';
import { VISITS_PAGE } from '../../utils/Constants';
import { getGraphQLErrorMessages } from '../../utils/ErrorUtil';
import { uiText } from '../../utils/Language';
import { getDocumentBaseTypeFilter } from '../../utils/ModelUtils';
import { generateReportWithGivenParameterValue } from '../../utils/ReportUtil';
import BHDropdownButton from '../ActionButtons/BHDropdownButton';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import ReceiptPrint from '../Reports/ReceiptPrint';
import ProductLineItemTable, {
	constructVisitProductLineItemTableFormDataToSave,
	convertToProductLineItemTableFormValues,
	primeVisitProductLineItemTableData,
	ProductLineItemTableFormValues,
} from '../Visit/ProductLineItemTable';
import PharmacySalesPaymentLineItemTable, {
	constructPharmacySalesPaymentLineItemTableDataToSave,
	convertToPharmacySalesPaymentLineItemTableFormValues,
	PharmacySalesPaymentLineItemTableFormValues,
	primePharmacySalesPaymentLineItemTableData,
} from './PharmacySalesPaymentLineItemTable';

export type PharmacySalesFormValues = {
	UU: string;
	BH_VisitDate: Date;
	Patient: { UU: string };
	formAction: '' | 'completeAndPrint' | 'completeAndClose' | 'completeAndNew';
} & ProductLineItemTableFormValues &
	PharmacySalesPaymentLineItemTableFormValues;

const convertToPharmacySalesFormFields = (
	overTheCounterPatient: C_BPartnerForPharmacySalesQuery['C_BPartnerGet']['Results'][0],
): PharmacySalesFormValues => {
	return {
		UU: v4(),
		BH_VisitDate: new Date(),
		Patient: { UU: overTheCounterPatient.UU },
		formAction: '',
		...convertToProductLineItemTableFormValues(),
		...convertToPharmacySalesPaymentLineItemTableFormValues(),
	};
};

const PharmacySalesFormInternal = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { organization, user, warehouse } = useContext(UserContext);

	const { data: [onCreditOrderDocumentType, overTheCounterPatient] = [] } = useSuspenseAsync(
		'pharmacy-sales',
		async () =>
			Promise.all([
				graphqlClient
					.query({
						query: C_DocTypeForFormsDocument,
						variables: {
							Filter: getDocumentBaseTypeFilter(
								documentBaseType.SalesOrder,
								documentSubTypeSalesOrder.OnCreditOrder,
								null,
								true,
								false,
								false,
							).toString(),
						},
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.C_DocTypeGet.Results[0]),
				graphqlClient
					.query({
						query: C_BPartnerForPharmacySalesDocument,
						variables: {
							Filter: DBFilter<BusinessPartnerDB>()
								.nested('c_bp_group')
								.property('name')
								.equals(businessPartnerGroupName.OTC)
								.up()
								.toString(),
						},
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.C_BPartnerGet.Results[0]),
				primeVisitProductLineItemTableData(graphqlClient),
				primePharmacySalesPaymentLineItemTableData(graphqlClient, organization),
			]),
	);

	const history = useHistory();
	const { toggleNavBlocking } = useContext(NavBlockingContext);
	const formMethods = useForm<PharmacySalesFormValues>({
		defaultValues: convertToPharmacySalesFormFields(overTheCounterPatient!),
	});

	const [{ value: printReceiptUrl }, onPrintReport] = useCustomAsyncFn(
		async (reportUuid: string, visitUU: string) =>
			reportUuid
				? URL.createObjectURL(
						await generateReportWithGivenParameterValue(graphqlClient, reportUuid, visitUU, ReportOutput.Pdf),
					)
				: undefined,
		[graphqlClient],
	);

	const reset = formMethods.reset;
	const [{ loading }, onSubmit] = useCustomAsyncFn<SubmitHandler<PharmacySalesFormValues>>(
		async (data) => {
			const visitFormAction = data.formAction;

			const visit: Bh_VisitInput = {
				UU: data.UU,
				BH_VisitDate: data.BH_VisitDate.getTime(),
				BH_PatientType: { UU: referenceListUuids.PHARMACY_SALES_TYPE },
				Patient: { UU: overTheCounterPatient?.UU! },
			};

			const [ordersToSave, { save: orderLinesToSave }] = await constructVisitProductLineItemTableFormDataToSave(
				data,
				undefined,
				graphqlClient,
				warehouse,
				user.UU,
			);

			// We need to update the document type for these orders
			ordersToSave.forEach((order) => {
				order.C_DocTypeTarget = { UU: onCreditOrderDocumentType!.UU };
				order.IsSOTrx = onCreditOrderDocumentType!.IsSOTrx;
			});

			const paymentsToSave = await constructPharmacySalesPaymentLineItemTableDataToSave(
				data,
				graphqlClient,
				organization,
			);

			try {
				const response = await Promise.all([
					graphqlClient.mutate({ mutation: Bh_VisitSaveDocument, variables: { BH_Visit: visit } }),
					graphqlClient.mutate({ mutation: C_OrderSaveManyForVisitsDocument, variables: { C_Orders: ordersToSave } }),
					graphqlClient.mutate({
						mutation: C_OrderLineSaveManyForVisitsDocument,
						variables: { C_OrderLines: orderLinesToSave },
					}),
					graphqlClient.mutate({
						mutation: C_PaymentSaveManyForVisitsDocument,
						variables: { C_Payments: paymentsToSave },
					}),
					graphqlClient.mutate({
						mutation: Bh_VisitProcessDocument,
						variables: { UU: data.UU, DocumentAction: DocAction.COMPLETE },
					}),
				]);
				if (response[4].errors) {
					exception({ description: `Visit ${data.UU} not processed` });
					toast.error(t(uiText.order.ERROR_SAVING_PLEASE_TRY_AGAIN));
					return;
				}

				toggleNavBlocking(false);

				if (visitFormAction === 'completeAndClose') {
					onCancel();
					return;
				}
				if (visitFormAction === 'completeAndPrint') {
					onPrintReport(PATIENT_RECEIPT, data.UU);
				} else {
					// reset printReceiptUrl
					onPrintReport('', '');
				}
				reset(convertToPharmacySalesFormFields(overTheCounterPatient!));
			} catch (error) {
				let stringError = '';
				if (error instanceof Error && isApolloError(error)) {
					stringError = getGraphQLErrorMessages(error).join(', ');
				}
				exception({ description: `Visit save error: ${stringError}` });
				toast.error(t(uiText.visit.error.UNABLE_TO_PROCESS, { error: stringError }));
			}
		},
		[warehouse, onCreditOrderDocumentType, organization, graphqlClient, reset, overTheCounterPatient, user],
	);

	const onCancel = () => {
		history.push(VISITS_PAGE);
	};

	useConfirmRefresh(formMethods.formState?.isDirty);

	return loading ? (
		<LoadSpinner title={t(uiText.visit.PROCESSING)} />
	) : (
		<Layout>
			<Layout.Header>
				<Layout.Title title={t(uiText.pharmacySales.title.NEW)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				{printReceiptUrl && <ReceiptPrint id={'receipt'} url={printReceiptUrl} />}

				<Row className="bg-white ms-0">
					<FormProvider {...formMethods}>
						<Form onSubmit={formMethods.handleSubmit(onSubmit)} className="px-0">
							<input type="hidden" {...formMethods.register('UU')} />
							<input type="hidden" {...formMethods.register('formAction')} />

							<Card className="bh-card mb-0">
								<Card.Body>
									<Row className="gy-3">
										<Form.Group as={Fragment} controlId="visitDate">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.visit.form.DATE)}</Form.Label>
											</Col>
											<Col xs={3} className="d-flex align-items-center">
												<fieldset className="w-100">
													<Controller<PharmacySalesFormValues, 'BH_VisitDate'>
														name="BH_VisitDate"
														render={({ field }) => (
															<BandaDatePicker
																dateFormat="yyyy-MM-dd h:mm aa"
																{...field}
																value={undefined}
																selected={field.value}
																timeInputLabel={`${t(uiText.visit.form.TIME)}:`}
																showTimeInput
																maxDate={new Date()}
															/>
														)}
													/>
												</fieldset>
											</Col>
										</Form.Group>
									</Row>
								</Card.Body>
							</Card>

							<Card className="bh-card">
								<Card.Header className="fw-bold h5">{t(uiText.visit.form.product.LABEL)}</Card.Header>
								<Card.Body>
									<ProductLineItemTable readOnly={false} />
								</Card.Body>
							</Card>

							<Card className="bh-card">
								<Card.Header className="fw-bold h5">{t(uiText.visit.form.payment.LABEL)}</Card.Header>
								<Card.Body>
									<PharmacySalesPaymentLineItemTable />
								</Card.Body>
							</Card>

							<Row className="m-4 ms-3">
								<Col xs="auto" className="me-auto">
									<Button type="button" variant="danger" name="cancel" className="me-auto" onClick={onCancel}>
										{t(uiText.visit.button.CANCEL)}
									</Button>
								</Col>

								<Col xs="auto">
									<BHDropdownButton title={t(uiText.visit.button.COMPLETE)} variant="success" icon="check">
										<Dropdown.Item
											onClick={(e) => {
												formMethods.setValue('formAction', 'completeAndClose');
												formMethods.handleSubmit(onSubmit)(e);
											}}
										>
											<FontAwesomeIcon icon="file" className="me-2 fa-fw" />
											{t(uiText.visit.button.COMPLETE_AND_CLOSE)}
										</Dropdown.Item>
										<Dropdown.Item
											onClick={(e) => {
												formMethods.setValue('formAction', 'completeAndNew');
												formMethods.handleSubmit(onSubmit)(e);
											}}
										>
											<FontAwesomeIcon icon="paste" className="me-2 fa-fw" />
											{t(uiText.visit.button.COMPLETE_AND_NEW_VISIT)}
										</Dropdown.Item>
										<Dropdown.Item
											onClick={(e) => {
												formMethods.setValue('formAction', 'completeAndPrint');
												formMethods.handleSubmit(onSubmit)(e);
											}}
										>
											<FontAwesomeIcon icon="print" className="me-2 fa-fw" />
											{t(uiText.pharmacySales.button.COMPLETE_AND_PRINT_RECEIPT)}
										</Dropdown.Item>
									</BHDropdownButton>
								</Col>
							</Row>
						</Form>
					</FormProvider>
				</Row>
			</Layout.Body>
		</Layout>
	);
};

export default function PharmacySalesForm() {
	const { t } = useTranslation();
	return (
		<Suspense fallback={<LoadSpinner title={t(uiText.visit.PROCESSING)} />}>
			<PharmacySalesFormInternal />
		</Suspense>
	);
}
