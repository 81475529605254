import { useApolloClient, useLazyQuery } from '@apollo/client';
import { Fragment, useContext, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import FormModalContext from '../../contexts/FormModalContext';
import {
	Ad_Ref_ListPaymentTypesDocument,
	C_BPartnerDisplayForExpensesFragmentDoc,
	C_BPartnerForExpensesDocument,
	C_ChargeForExpenseLinesDocument,
	C_DocTypeForFormsDocument,
	C_InvoiceAndC_InvoiceLinesSaveAndProcessOnExpensesFormDocument,
	C_InvoiceAndC_InvoiceLinesSaveOnExpensesFormDocument,
	C_InvoiceAndC_InvoiceLinesSaveOnExpensesFormMutationVariables,
	C_InvoiceDeleteForDraftedExpensesWithLinesDocument,
	C_InvoiceDeleteForDraftedExpensesWithNoLinesDocument,
	C_InvoiceForExpenseEditingDocument,
	C_InvoiceForExpenseEditingQuery,
	C_InvoiceProcessDocument,
	C_InvoiceSaveOnExpensesFormDocument,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import useSuspenseDocumentActionInformation from '../../hooks/useSuspenseDocumentActionInformation';
import {
	BusinessPartnerDB,
	chargeExpenseCategoryFilter,
	DBFilter,
	documentBaseType,
	Paging,
	ReferenceListDB,
	referenceUuids,
} from '../../models';
import DocAction from '../../models/DocAction';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import { pageUuid, SEARCH } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import { getDefaultSearchFilter, getDocumentBaseTypeFilter } from '../../utils/ModelUtils';
import { isEntityCompleted, isEntityDrafted, isEntityVoided } from '../../utils/StatusUtil';
import BasicButton from '../ActionButtons/BasicButton';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import EntityLookup from '../entity-lookup/EntityLookup';
import { withFormModalSuspenseWrapper } from '../HOCs/withFormModalSuspenseWrapper';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import VoidedReasonModal from '../Modal/VoidedReasonModal';
import SupplierForm from '../Supplier/SupplierForm';
import ExpenseLineItemTable, {
	constructExpenseFormLinesSubmissionObject,
	convertToExpenseFormLineFields,
	ExpenseFormLineFields,
} from './ExpenseLineItemTable';
import { chargeSortOrder } from './ExpenseLineItemTableRow';

type ExpenseFormProps = EntityFormProperties;

export type ExpenseFormFields = {
	UU: string;
	BH_Voided_Reason: { UU: string | null };
	C_BPartner: { UU: string };
	DateInvoiced: Date;
	PaymentRule: { UU: string };
	submitEvent: '' | 'save' | 'complete' | 'void';
} & ExpenseFormLineFields;

const fetchReceiptDocumentTypeArguments = [documentBaseType.APInvoice, null, null, false, false, false] as const;
const getTitle = (uuid?: string) => (uuid ? uiText.expense.pageDisplay.EDIT : uiText.expense.pageDisplay.ADD);
const convertToFormFields: (initialData?: C_InvoiceForExpenseEditingQuery['C_Invoice']) => ExpenseFormFields = (
	initialData,
) => {
	if (!initialData) {
		return {
			UU: v4(),
			BH_Voided_Reason: { UU: null },
			C_BPartner: { UU: '' },
			DateInvoiced: new Date(),
			PaymentRule: { UU: '' },
			submitEvent: '',
			...convertToExpenseFormLineFields(initialData),
		};
	}
	return {
		UU: initialData.UU,
		BH_Voided_Reason: { UU: initialData.BH_Voided_Reason?.UU || null },
		C_BPartner: { UU: initialData.C_BPartner.UU },
		DateInvoiced: new Date(initialData.DateInvoiced),
		PaymentRule: { UU: initialData.PaymentRule.UU },
		submitEvent: '',
		...convertToExpenseFormLineFields(initialData),
	};
};

function ExpenseForm({ uuid, onFinish, renderAsModal }: ExpenseFormProps) {
	const graphqlClient = useApolloClient();
	const { data: [, data, paymentTypes, vendorInvoiceDocumentType] = [] } = useSuspenseAsync(
		uuid || 'add-expense',
		async () =>
			Promise.all([
				// Prime the cache
				await graphqlClient.query({
					query: C_ChargeForExpenseLinesDocument,
					variables: {
						Page: Paging.ALL.page,
						Size: Paging.ALL.size,
						Sort: chargeSortOrder,
						Filter: chargeExpenseCategoryFilter().toString(),
					},
					fetchPolicy: 'network-only',
				}),
				uuid
					? graphqlClient
							.query({
								query: C_InvoiceForExpenseEditingDocument,
								variables: { UU: uuid },
								fetchPolicy: 'network-only',
							})
							.then((response) => response.data.C_Invoice)
					: undefined,
				graphqlClient
					.query({
						query: Ad_Ref_ListPaymentTypesDocument,
						variables: {
							Filter: DBFilter<ReferenceListDB>()
								.nested('ad_reference')
								.property('ad_reference_uu')
								.equals(referenceUuids.PAYMENT_TYPES)
								.up()
								.toString(),
						},
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.AD_Ref_ListGet.Results),
				graphqlClient
					.query({
						query: C_DocTypeForFormsDocument,
						variables: { Filter: getDocumentBaseTypeFilter(...fetchReceiptDocumentTypeArguments).toString() },
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.C_DocTypeGet.Results[0]),
			]),
	);

	const { t } = useTranslation();
	const [dataToUse, setDataToUse] = useState(data);
	const title = dataToUse
		? isEntityCompleted(dataToUse) || isEntityVoided(dataToUse)
			? uiText.expense.pageDisplay.VIEW
			: uiText.expense.pageDisplay.EDIT
		: uiText.expense.pageDisplay.ADD;

	const formMethods = useForm<ExpenseFormFields>({
		defaultValues: convertToFormFields(data),
	});
	const isDataReadOnly = isEntityCompleted(dataToUse) || isEntityVoided(dataToUse);
	const [viewVoidModal, setViewVoidModal] = useState(false);

	const { canVoidDocument, voidDocumentAction } = useSuspenseDocumentActionInformation(
		documentBaseType.APInvoice,
		dataToUse?.DocStatus.Value,
	);
	const { disableWrite } = useActionPrivileges(pageUuid.EXPENSES);
	const { canWrite: canCreateSuppliers } = useActionPrivileges(pageUuid.SUPPLIERS);
	const [showSupplierForm, setShowSupplierForm] = useState(false);
	const { dataWasSaved, savedData, wasDataSaved } = useContext(FormModalContext);

	const reset = formMethods.reset;
	const [{ loading }, onSubmit] = useCustomAsyncFn<SubmitHandler<ExpenseFormFields>>(
		async (data) => {
			const formAction = data.submitEvent;

			let vendorInvoiceDocumentTypeToUse = vendorInvoiceDocumentType;
			if (!vendorInvoiceDocumentTypeToUse) {
				try {
					vendorInvoiceDocumentTypeToUse = (
						await graphqlClient.query({
							query: C_DocTypeForFormsDocument,
							variables: { Filter: getDocumentBaseTypeFilter(...fetchReceiptDocumentTypeArguments).toString() },
							fetchPolicy: 'cache-first',
						})
					).data.C_DocTypeGet.Results[0];
				} catch (error) {
					exception({ description: 'Could not load A/P invoice document type: ' + error });
					toast.error(t(uiText.error.PLEASE_TRY_AGAIN));
					return;
				}
			}

			const vendorInvoice: C_InvoiceAndC_InvoiceLinesSaveOnExpensesFormMutationVariables['C_Invoice'] = {
				UU: data.UU,
				C_BPartner: { UU: data.C_BPartner.UU },
				C_DocTypeTarget: { UU: vendorInvoiceDocumentTypeToUse.UU },
				DateInvoiced: data.DateInvoiced.getTime(),
				IsSOTrx: vendorInvoiceDocumentTypeToUse.IsSOTrx,
			};
			const invoiceLines = constructExpenseFormLinesSubmissionObject(data, data.UU);

			let processType;
			if (formAction === 'complete') {
				processType = DocAction.COMPLETE;
			} else if (formAction === 'void') {
				processType = voidDocumentAction;
			}
			if (processType) {
				if (formAction === 'void') {
					vendorInvoice.BH_Voided_Reason = { UU: data.BH_Voided_Reason.UU! };
				}
				await graphqlClient.mutate({
					mutation: C_InvoiceAndC_InvoiceLinesSaveAndProcessOnExpensesFormDocument,
					variables: {
						C_Invoice: vendorInvoice,
						C_InvoiceLines: invoiceLines,
						C_InvoiceUU: vendorInvoice.UU!,
						DocumentAction: processType,
					},
				});
			} else {
				if (invoiceLines.length) {
					await graphqlClient.mutate({
						mutation: C_InvoiceAndC_InvoiceLinesSaveOnExpensesFormDocument,
						variables: {
							C_Invoice: vendorInvoice,
							C_InvoiceLines: invoiceLines,
						},
					});
				} else {
					await graphqlClient.mutate({
						mutation: C_InvoiceSaveOnExpensesFormDocument,
						variables: { C_Invoice: vendorInvoice },
					});
				}
			}

			const savedData = (
				await graphqlClient.query({
					query: C_InvoiceForExpenseEditingDocument,
					variables: { UU: vendorInvoice.UU! },
					fetchPolicy: 'network-only',
				})
			).data.C_Invoice!;
			dataWasSaved(savedData.UU);
			setDataToUse(savedData);
			reset(convertToFormFields(savedData));
			toast.success(uiText.expense.UPDATE_SUCCESSFUL);
		},
		[reset, voidDocumentAction, vendorInvoiceDocumentType, graphqlClient],
	);

	useConfirmRefresh(formMethods.formState?.isDirty);

	const [onSearchBusinessPartner, { data: businessPartnerOptions, loading: areLoadingBusinessPartners }] = useLazyQuery(
		C_BPartnerForExpensesDocument,
		{ fetchPolicy: 'cache-first' },
	);

	const onDelete = async () => {
		try {
			if (!dataToUse) {
				onFinish(false);
				return;
			}
			// If the entity is completed, we just reverse it
			if (isEntityCompleted(dataToUse)) {
				await graphqlClient.mutate({
					mutation: C_InvoiceProcessDocument,
					variables: { UU: dataToUse.UU, DocumentAction: DocAction.REVERSE_ACCRUAL },
				});
			} else {
				if (dataToUse.C_InvoiceLines?.length) {
					await graphqlClient.mutate({
						mutation: C_InvoiceDeleteForDraftedExpensesWithLinesDocument,
						variables: {
							C_InvoiceLineUUs: dataToUse.C_InvoiceLines.map((invoiceLine) => invoiceLine.UU),
							C_InvoiceUUs: [dataToUse.UU],
						},
					});
				} else {
					await graphqlClient.mutate({
						mutation: C_InvoiceDeleteForDraftedExpensesWithNoLinesDocument,
						variables: {
							UUs: [dataToUse.UU],
						},
					});
				}
			}
			onFinish(true);
		} catch (error) {
			exception({ description: `Expense delete error: ${error}` });
			toast.error(t(uiText.expense.error.COULD_NOT_DELETE));
		}
	};

	const inputs = (
		<FormProvider {...formMethods}>
			<Form onSubmit={formMethods.handleSubmit(onSubmit)} autoComplete="off" className="px-0">
				{showSupplierForm && (
					<SupplierForm
						renderAsModal={true}
						canSaveMany={false}
						onFinish={(refreshData?: boolean, savedEntity?: string) => {
							setShowSupplierForm(false);
							if (refreshData && savedEntity) {
								formMethods.setValue('C_BPartner.UU', savedEntity);
							}
						}}
					/>
				)}
				<fieldset disabled={disableWrite || isDataReadOnly}>
					<input type="hidden" {...formMethods.register('UU')} />
					<input type="hidden" {...formMethods.register('BH_Voided_Reason.UU')} />
					<Card className="bh-card">
						<Card.Header className="fw-bold h5">{t(uiText.expense.supplier.DETAILS)}</Card.Header>
						<Card.Body>
							<Form.Group controlId="C_BPartner" as={Row}>
								<Col xs={1} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.expense.supplier.SEARCH)}</Form.Label>
								</Col>
								<Col xs={8} className="d-flex align-items-center">
									<EntityLookup<ExpenseFormFields, 'C_BPartner'>
										className="w-100"
										name="C_BPartner"
										rules={{ required: true }}
										defaultValue={dataToUse?.C_BPartner}
										isLoading={areLoadingBusinessPartners}
										id="C_BPartner"
										emptyLabel={t(uiText.supplier.search.EMPTY)}
										labelKey={(data) =>
											graphqlClient.readFragment({ id: data.UU, fragment: C_BPartnerDisplayForExpensesFragmentDoc })
												?.Name || ''
										}
										placeholder={t(uiText.supplier.search.PLACEHOLDER)}
										promptText={t(uiText.supplier.search.SEARCHING)}
										searchText={t(uiText.supplier.search.SEARCHING)}
										options={businessPartnerOptions?.C_BPartnerGet.Results || []}
										onSearch={(query) =>
											onSearchBusinessPartner({
												variables: {
													Size: SEARCH.DEFAULT_PAGE_SIZE,
													Filter: getDefaultSearchFilter<BusinessPartnerDB>(query)
														.property('isVendor')
														.equals(true)
														.toString(),
												},
											})
										}
										createNew={
											canCreateSuppliers
												? {
														onCreateNew: () => setShowSupplierForm(true),
														newEntityName: t(uiText.supplier.LABEL),
													}
												: undefined
										}
									/>
									{formMethods.formState.errors?.C_BPartner?.UU && (
										<span className="text-danger">{t(uiText.expense.error.SUPPLIER)}</span>
									)}
								</Col>
							</Form.Group>
						</Card.Body>
					</Card>
					<Card className="bh-card">
						<Card.Header className="fw-bold h5">{t(uiText.expense.form.TITLE)}</Card.Header>
						<Card.Body>
							<Row className="mb-3">
								<Form.Group controlId="dateInvoiced" as={Fragment}>
									<Col xs={1} className="d-flex align-items-center">
										<Form.Label column>{t(uiText.expense.form.DATE)}</Form.Label>
									</Col>
									<Col xs={5} className="d-flex align-items-center">
										<Controller<ExpenseFormFields, 'DateInvoiced'>
											name="DateInvoiced"
											render={({ field }) => <BandaDatePicker {...field} value={undefined} selected={field.value} />}
										/>
									</Col>
								</Form.Group>
								<Form.Group controlId="PaymentRule" as={Fragment}>
									<Col xs={1} className="d-flex align-items-center">
										<Form.Label column>{t(uiText.expense.form.PAYMENT_METHOD)}</Form.Label>
									</Col>
									<Col xs={5} className="d-flex align-items-center">
										<Form.Select {...formMethods.register('PaymentRule.UU', { required: true })}>
											<option>{t(uiText.expense.form.PAYMENT_METHOD_OPTION)}</option>
											{paymentTypes
												?.filter((paymentType) => isEntityCompleted(dataToUse) || paymentType.IsActive)
												.map((paymentType) => (
													<option key={paymentType.UU} value={paymentType.UU}>
														{paymentType.Name}
													</option>
												))}
										</Form.Select>
										{formMethods.formState.errors?.PaymentRule?.UU && (
											<span className="text-danger">{t(uiText.expense.error.PAYMENT_METHOD)}</span>
										)}
									</Col>
								</Form.Group>
							</Row>

							<ExpenseLineItemTable readOnly={isDataReadOnly} />
						</Card.Body>
					</Card>
				</fieldset>
				<input type="hidden" {...formMethods.register('submitEvent')} defaultValue={''} />
			</Form>
		</FormProvider>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'mb-4 me-1'}`}>
			{disableWrite ? (
				<Col xs="auto">
					<BasicButton
						name={uiText.product.button.BACK}
						text={t(uiText.product.button.BACK)}
						variant="danger"
						icon="arrow-left"
						active={true}
						onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
					/>
				</Col>
			) : (
				<>
					<Col xs="auto" className="me-auto">
						<Button
							variant="danger"
							type="button"
							name="cancel"
							onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
						>
							{isDataReadOnly ? t(uiText.expense.button.BACK) : t(uiText.expense.button.CANCEL)}
						</Button>
					</Col>
					{!dataToUse || isEntityDrafted(dataToUse) ? (
						<>
							<Col xs="auto">
								<Button
									variant="primary"
									type="submit"
									onClick={() => {
										formMethods.setValue('submitEvent', 'save');
										formMethods.handleSubmit(onSubmit)();
									}}
								>
									{t(uiText.expense.button.SAVE)}
								</Button>
							</Col>
							<Col xs="auto">
								<Button
									variant="success"
									type="submit"
									onClick={() => {
										formMethods.setValue('submitEvent', 'complete');
										formMethods.handleSubmit(onSubmit)();
									}}
								>
									{t(uiText.expense.button.COMPLETE)}
								</Button>
							</Col>
						</>
					) : null}
					{isEntityCompleted(dataToUse) ? (
						<Col xs="auto">
							<Button
								variant="danger"
								name="delete"
								onClick={(e) => {
									e.preventDefault();
									onDelete();
								}}
							>
								{t(uiText.expense.button.DELETE)}
							</Button>
						</Col>
					) : null}
					{isEntityCompleted(dataToUse) && canVoidDocument ? (
						<Col xs="auto">
							<Button
								variant="danger"
								type="button"
								onClick={() => {
									formMethods.setValue('submitEvent', 'void');
									setViewVoidModal(true);
								}}
							>
								{t(uiText.expense.button.VOID_EXPENSE)}
							</Button>
						</Col>
					) : null}
				</>
			)}
		</Row>
	);

	return (
		<>
			{viewVoidModal && (
				<VoidedReasonModal
					windowUuid={pageUuid.EXPENSES}
					onHandleClose={() => {
						setViewVoidModal(false);
					}}
					onVoidSubmit={({ BH_Voided_Reason }) => {
						formMethods.setValue('BH_Voided_Reason.UU', BH_Voided_Reason.UU);
						formMethods.handleSubmit(onSubmit)();
						setViewVoidModal(false);
					}}
				/>
			)}
			{renderAsModal ? (
				<>
					<Modal.Header closeButton>
						<Modal.Title>{t(title)}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{loading ? <LoadSpinner inline title={t(uiText.expense.message.PROCESSING)} /> : inputs}
					</Modal.Body>
					{!loading ? (
						<Modal.Footer>
							<div className="w-100">{buttons}</div>
						</Modal.Footer>
					) : null}
				</>
			) : (
				<>
					<Layout.Header>
						<Layout.Title title={t(title)} />
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						{loading ? (
							<LoadSpinner title={t(uiText.expense.message.PROCESSING)} />
						) : (
							<div className="bg-white pb-0_5 me-n2_5">
								{inputs}
								{buttons}
							</div>
						)}
					</Layout.Body>
				</>
			)}
		</>
	);
}

export default withFormModalSuspenseWrapper<ExpenseFormProps>({ loadingLabel: uiText.expense.LOADING, getTitle })(
	ExpenseForm,
);
