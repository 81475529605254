import { BaseMetadataDB } from './BaseMetadata';

export enum ConceptExtraKey {
	HI_ABSOLUTE = 'hi_absolute',
	HI_NORMAL = 'hi_normal',
	INDEX_TERMS = 'index_terms',
	LOCAL_NAME = 'local_name',
	LOW_ABSOLUTE = 'low_absolute',
	LOW_NORMAL = 'low_normal',
	MOH_705A_LESSTHAN5 = 'MOH-705A-LESSTHAN5',
	MOH_705B_GREATERTHAN5 = 'MOH-705B-GREATERTHAN5',
	MOH_706 = 'MOH-706',
	UNITS = 'units',
}

export interface ConceptExtraDB extends BaseMetadataDB {
	bh_key: string;
	bh_value: string;
}
