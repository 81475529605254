import { UpdateData } from '../contexts/UserContext';
import { Ad_ClientGetQuery, Ad_RoleWithWindowAccessQuery, SignInMutation } from '../graphql/__generated__/graphql';

export function transformAuthenticationDataToUserContextData(
	user: NonNullable<SignInMutation['SignIn']['AD_User']>,
	clients: Ad_ClientGetQuery['AD_ClientGet']['Results'],
	authenticationData: { clientUU: string; organizationUU: string; warehouseUU: string },
	role: Ad_RoleWithWindowAccessQuery['AD_Role'],
): Required<UpdateData> {
	const client = clients.find((client) => client.UU === authenticationData.clientUU);
	const organization = client?.AD_Orgs.find((organization) => organization.UU === authenticationData.organizationUU);
	const warehouse = organization?.M_Warehouses?.find((warehouse) => warehouse.UU === authenticationData.warehouseUU);
	if (!client || !organization || !role || !warehouse) {
		throw Error('something is wrong with the authentication data');
	}
	return {
		client,
		organization,
		role,
		warehouse,
		user,
		availableClients: clients,
	};
}
