import { ApolloClient, useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { UserContextInterface } from '../../contexts/UserContext';
import {
	Ad_Ref_ListTenderTypesDocument,
	Ad_Ref_ListTenderTypesQueryVariables,
	C_AcctSchemaForCurrencyUseDocument,
	C_BankAccountForPaymentsDocument,
	C_DocTypeForFormsDocument,
	C_PaymentInput,
} from '../../graphql/__generated__/graphql';
import { BaseMetadataDB, DBFilter, documentBaseType, referenceUuids } from '../../models';
import { ReferenceListDB, sortPaymentTypesGraphQL } from '../../models/ReferenceList';
import { uiText } from '../../utils/Language';
import { getDocumentBaseTypeFilter } from '../../utils/ModelUtils';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { PharmacySalesFormValues } from './PharmacySalesForm';
import PharmacySalesPaymentTableFooter from './PharmacySalesPaymentTableFooter';
import PharmacySalesPaymentTableRow from './PharmacySalesPaymentTableRow';

export type PharmacySalesPaymentLineItemTableFormValues = {
	C_Payments: Array<{ UU: string; Description: string | null; PayAmt: number | null; TenderType: { UU: string } }>;
	addPaymentInformation: { Description: string | null; PayAmt: number | null; TenderType: { UU: string } };
	billBalance: number;
	paymentLineCount: number;
};

export const convertToPharmacySalesPaymentLineItemTableFormValues = (): PharmacySalesPaymentLineItemTableFormValues => {
	return {
		C_Payments: [],
		addPaymentInformation: { Description: null, PayAmt: null, TenderType: { UU: '' } },
		billBalance: 0,
		paymentLineCount: 0,
	};
};

export const constructPharmacySalesPaymentLineItemTableDataToSave = async (
	data: PharmacySalesFormValues,
	graphqlClient: ApolloClient<object>,
	organization: UserContextInterface['organization'],
): Promise<C_PaymentInput[]> => {
	let receiptDocumentType = (
		await graphqlClient.query({
			query: C_DocTypeForFormsDocument,
			variables: { Filter: getDocumentBaseTypeFilter(...fetchReceiptDocumentTypeArguments).toString() },
			fetchPolicy: 'cache-first',
		})
	).data.C_DocTypeGet.Results[0];
	let bankAccounts = (
		await graphqlClient.query({
			query: C_BankAccountForPaymentsDocument,
			variables: {
				Sort: JSON.stringify([['created', 'asc']]),
				Filter: getBankAccountFilterArguments(organization),
			},
			fetchPolicy: 'cache-first',
		})
	).data.C_BankAccountGet.Results;
	let accountSchema = (
		await graphqlClient.query({ query: C_AcctSchemaForCurrencyUseDocument, fetchPolicy: 'cache-first' })
	).data.C_AcctSchemaGet.Results[0];

	// Get the default or first bank account to use
	let bankAccountUU = bankAccounts.filter((bankAccount) => bankAccount.IsDefault)[0]?.UU;
	if (!bankAccountUU) {
		bankAccountUU = bankAccounts[0]?.UU;
	}

	let runningTotal = data.C_Orders.flatMap((corder) => corder.C_OrderLines).reduce(
		(runningTotal, orderline) => runningTotal + (orderline.PriceEntered || 0) * (orderline.QtyEntered || 0),
		0,
	);
	return (
		// RHF version 7.12.2 doesn't always reset arrays, so make sure the optional operator is used
		// (7.13.0+ does, but they cause other errors that haven't been fixed up to 7.53.0)
		(data.C_Payments?.map((payment) => {
			let priceToUse = payment.PayAmt || 0;
			// If the running total is smaller than the entered price, we'll take the remaining total
			if (runningTotal - priceToUse < 0) {
				priceToUse = runningTotal;
			}
			// If there's no price to use, we'll not save the amount
			if (priceToUse === 0) {
				return undefined;
			}
			runningTotal -= priceToUse;
			return {
				UU: payment.UU,
				BH_tender_amount: payment.PayAmt,
				BH_Visit: { UU: data.UU },
				C_BPartner: { UU: data.Patient.UU },
				C_BankAccount: bankAccountUU ? { UU: bankAccountUU } : undefined,
				C_Currency: { UU: accountSchema.C_Currency.UU },
				C_DocType: { UU: receiptDocumentType.UU },
				DateAcct: data.BH_VisitDate.getTime(),
				DateTrx: data.BH_VisitDate.getTime(),
				Description: payment.Description || null,
				IsReceipt: receiptDocumentType.IsSOTrx,
				PayAmt: priceToUse,
				TenderType: { UU: payment.TenderType.UU },
			};
		}).filter((payment) => !!payment) as C_PaymentInput[]) || []
	);
};

const tenderTypesVariables: Ad_Ref_ListTenderTypesQueryVariables = {
	Filter: DBFilter<ReferenceListDB>()
		.nested('ad_reference')
		.property('ad_reference_uu')
		.equals(referenceUuids.TENDER_TYPES)
		.up()
		.toString(),
};
const fetchReceiptDocumentTypeArguments = [documentBaseType.ARReceipt, null, null, true, false, false] as const;
const getBankAccountFilterArguments = (organization: UserContextInterface['organization']) =>
	DBFilter<BaseMetadataDB>().nested('ad_org').property('ad_org_uu').equals(organization.UU).up().toString();
export const primePharmacySalesPaymentLineItemTableData = (
	graphqlClient: ApolloClient<object>,
	organization: UserContextInterface['organization'],
) =>
	Promise.all([
		graphqlClient.query({
			query: Ad_Ref_ListTenderTypesDocument,
			variables: tenderTypesVariables,
			fetchPolicy: 'cache-first',
		}),
		graphqlClient
			.query({
				query: C_DocTypeForFormsDocument,
				variables: { Filter: getDocumentBaseTypeFilter(...fetchReceiptDocumentTypeArguments).toString() },
				fetchPolicy: 'cache-first',
			})
			.then((response) => response.data.C_DocTypeGet.Results[0]),
		graphqlClient.query({
			query: C_BankAccountForPaymentsDocument,
			variables: {
				Sort: JSON.stringify([['created', 'asc']]),
				Filter: getBankAccountFilterArguments(organization),
			},
			fetchPolicy: 'cache-first',
		}),
		graphqlClient.query({ query: C_AcctSchemaForCurrencyUseDocument, fetchPolicy: 'cache-first' }),
	]);

const PharmacySalesPaymentLineItemTable = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const {
		register,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext<PharmacySalesFormValues>();
	const addPaymentInformationTenderTypeUU = useWatch<
		PharmacySalesPaymentLineItemTableFormValues,
		'addPaymentInformation.TenderType.UU'
	>({
		name: `addPaymentInformation.TenderType.UU`,
	});
	const { fields, append, remove } = useFieldArray<PharmacySalesPaymentLineItemTableFormValues, 'C_Payments', 'UU'>({
		name: 'C_Payments',
		keyName: 'UU',
	});

	const tenderTypes = graphqlClient.readQuery({
		query: Ad_Ref_ListTenderTypesDocument,
		variables: tenderTypesVariables,
	})?.AD_Ref_ListGet.Results;

	// This handles adding a new payment if the user selected a type
	useEffect(() => {
		if (addPaymentInformationTenderTypeUU) {
			// Add a new row
			append({
				UU: v4(),
				Description: getValues('addPaymentInformation.Description'),
				PayAmt: getValues('addPaymentInformation.PayAmt'),
				TenderType: { UU: getValues('addPaymentInformation.TenderType.UU') },
			});
			setValue(`addPaymentInformation.TenderType.UU`, '');
			setValue(`addPaymentInformation.PayAmt`, null);
			setValue(`addPaymentInformation.Description`, null);
		}
	}, [addPaymentInformationTenderTypeUU, append, setValue, getValues]);
	useEffect(() => {
		setValue('paymentLineCount', fields.length);
	}, [fields.length, setValue]);

	return (
		<>
			<input
				type="hidden"
				{...register('paymentLineCount', {
					valueAsNumber: true,
					validate: (value: number) => value > 0,
				})}
				defaultValue={fields.length}
			/>
			<input
				type="hidden"
				{...register('billBalance', {
					valueAsNumber: true,
					validate: (value: number) => value <= 0,
				})}
			/>
			<table className="bh-table--form">
				<thead>
					<tr>
						<th className="data-type-text">{t(uiText.visit.form.payment.table.TYPE)}</th>
						<th className="data-type-numeric">{t(uiText.visit.form.payment.table.AMOUNT_PAID)}</th>
						<th className="data-type-text">{t(uiText.visit.form.payment.table.DESCRIPTION)}</th>
						<th className="data-type-action print__d-none">{t(uiText.visit.button.DELETE)}</th>
					</tr>
				</thead>
				<tbody>
					{fields.map((field, index) => (
						<PharmacySalesPaymentTableRow key={field.UU} index={index} remove={remove} />
					))}
				</tbody>
				<tbody>
					<tr>
						<td>
							<Form.Select
								aria-label={t(uiText.visit.form.payment.table.TYPE)}
								{...register('addPaymentInformation.TenderType.UU')}
							>
								<option value=""></option>
								{sortPaymentTypesGraphQL(tenderTypes?.filter((tenderType) => tenderType.IsActive) || []).map(
									(paymentType) => (
										<option key={paymentType.UU} value={paymentType.UU}>
											{paymentType.Name}
										</option>
									),
								)}
							</Form.Select>
						</td>
						<td>
							<Controller
								name={`addPaymentInformation.PayAmt`}
								render={({ field }) => (
									<FormatNumberInput aria-label={t(uiText.visit.form.payment.table.AMOUNT_PAID)} min={0} {...field} />
								)}
							/>
						</td>
						<td>
							<Form.Control {...register(`addPaymentInformation.Description`)} />
						</td>
					</tr>
				</tbody>
				<tfoot>
					<PharmacySalesPaymentTableFooter />
				</tfoot>
			</table>
			{!!errors.C_Payments?.length && <div className="text-danger">{t(uiText.payment.error.MISSING_AMOUNT)}</div>}
			{!!errors.paymentLineCount && <div className="text-danger">{t(uiText.payment.error.PAYMENT_REQUIRED)}</div>}
			{!!errors.billBalance && <div className="text-danger">{t(uiText.payment.error.FULL_BALANCE_MUST_BE_PAID)}</div>}
		</>
	);
};

export default PharmacySalesPaymentLineItemTable;
