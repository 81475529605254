import { BaseMetadataDB } from './BaseMetadata';

export enum EncounterDiagnosticStatusCode {
	PENDING = 'P',
	COMPLETE = 'C',
}

export enum EncounterDiagnosticStatusCodeUU {
	Pending = '5d4d92c0-64a0-4552-aec7-0b73f3f4c189',
	Complete = '6c5382b6-6f12-4115-bcea-68a573400296',
}

export interface EncounterDiagnosticDB extends BaseMetadataDB {}
