import { useApolloClient } from '@apollo/client';
import { sortBy } from 'lodash';
import { useContext, useLayoutEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDeepCompareEffect } from 'react-use';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import UserContext from '../../contexts/UserContext';
import { Ad_RoleWithWindowAccessDocument, ChangeAccessDocument } from '../../graphql/__generated__/graphql';
import useService from '../../hooks/useService';
import { convertLocaleToBackend } from '../../models';
import { exception } from '../../utils/analytics';
import { transformAuthenticationDataToUserContextData } from '../../utils/AuthenticationUtil';
import { uiText } from '../../utils/Language';
import CustomPrompt from '../CustomPrompt/CustomPrompt';

type ChangeAccessModalProperties = {
	onHandleClose: () => void;
};

type ChangeAccessModalForm = {
	clientUuid: string;
	organizationUuid: string;
	roleUuid: string;
	warehouseUuid: string;
};

const sortRoles = <T extends { Name: string }>(clientName: string, roles: T[]): T[] =>
	sortBy(
		(JSON.parse(JSON.stringify(roles)) as typeof roles).map((role) => {
			role.Name = role.Name.replace(clientName, '');
			return role;
		}),
		'Name',
	);

const ChangeAccessModal = ({ onHandleClose }: ChangeAccessModalProperties) => {
	const { t, i18n } = useTranslation();
	const { authService } = useService();
	const graphqlClient = useApolloClient();
	const { availableClients: clients, organization, update, client, role, warehouse, user } = useContext(UserContext);

	const { register, handleSubmit, setValue, control } = useForm<ChangeAccessModalForm>({
		defaultValues: {
			clientUuid: client.UU,
			organizationUuid: organization.UU,
			roleUuid: role.UU,
			warehouseUuid: warehouse.UU,
		},
	});

	const context = useContext(NavBlockingContext);
	const [roles, setRoles] = useState(sortRoles(client.Name, organization.AD_Roles || []));
	const [organizations, setOrganizations] = useState(sortBy(client.AD_Orgs || [], 'Name'));
	const [warehouses, setWarehouses] = useState(sortBy(organization.M_Warehouses || [], 'Name'));
	const defaultClientUuid = client.UU;

	const clientUuid = useWatch({ control, name: 'clientUuid' });
	const organizationUuid = useWatch({ control, name: 'organizationUuid' });
	const roleUuid = useWatch({ control, name: 'roleUuid' });
	const warehouseUuid = useWatch({ control, name: 'warehouseUuid' });

	const defaultOrganizationUuid = organization.UU;
	const foundClient = clients.find((client) => client.UU === clientUuid);
	useDeepCompareEffect(() => {
		if (foundClient) {
			setOrganizations(sortBy(foundClient.AD_Orgs, 'Name'));

			// set default value
			if (clientUuid === defaultClientUuid) {
				setValue('organizationUuid', defaultOrganizationUuid);
			} else if (foundClient.AD_Orgs.length === 1) {
				setValue('organizationUuid', foundClient.AD_Orgs[0].UU);
			}
		}
	}, [clientUuid, foundClient, defaultClientUuid, setValue, defaultOrganizationUuid]);

	const defaultRoleUuid = role.UU;
	const defaultWarehouseUuid = warehouse.UU;
	const foundOrganization = organizations.find((organization) => organization.UU === organizationUuid);
	useDeepCompareEffect(() => {
		if (foundClient?.AD_Orgs.some((organization) => organization.UU === foundOrganization?.UU) && foundOrganization) {
			// reset roles and warehouses
			setValue('roleUuid', '');
			setValue('warehouseUuid', '');

			// load roles and warehouses.
			if (foundOrganization) {
				setRoles(sortRoles(foundClient.Name, foundOrganization.AD_Roles || []));
				// set default values
				if (clientUuid === defaultClientUuid) {
					setValue('roleUuid', defaultRoleUuid);
				} else if (foundOrganization.AD_Roles?.length) {
					setValue(
						'roleUuid',
						foundOrganization.AD_Roles.find((role) => role.Name === foundClient.Name + ' Clinic Admin')?.UU ||
							foundOrganization.AD_Roles.find((role) => role.Name === foundClient.Name + ' Admin')?.UU ||
							foundOrganization.AD_Roles[0].UU,
					);
				}

				setWarehouses(sortBy(foundOrganization.M_Warehouses || [], 'Name'));
				// set default values
				if (clientUuid === defaultClientUuid) {
					setValue('warehouseUuid', defaultWarehouseUuid);
				} else if (foundOrganization.M_Warehouses?.length) {
					setValue(
						'warehouseUuid',
						foundOrganization.M_Warehouses.find((warehouse) => warehouse.BH_DefaultWarehouse)?.UU ||
							foundOrganization.M_Warehouses[0].UU,
					);
				}
			}
		}
	}, [foundClient, foundOrganization, setValue, clientUuid, defaultRoleUuid, defaultWarehouseUuid, defaultClientUuid]);

	// Ensure the selects update after the form is repainted
	useLayoutEffect(() => {
		setValue('clientUuid', clientUuid);
		setValue('organizationUuid', organizationUuid);
		setValue('roleUuid', roleUuid);
		setValue('warehouseUuid', warehouseUuid);
	}, [setValue, clientUuid, organizationUuid, roleUuid, warehouseUuid]);

	const onHandleSubmit = async (data: ChangeAccessModalForm) => {
		if (context?.isBlocking) {
			onHandleClose();
			const userWantsToProceed = await CustomPrompt(t(uiText.notificationContainer.prompt.PROMPT_MESSAGE));
			if (!userWantsToProceed) {
				return;
			}
		}

		try {
			const restResponse = authService.changeAccess({
				clientUuid: data.clientUuid,
				organizationUuid: data.organizationUuid,
				warehouseUuid: data.warehouseUuid,
				roleUuid: data.roleUuid,
				language: convertLocaleToBackend(i18n.language),
				username: user.Name,
			});
			await graphqlClient.mutate({
				mutation: ChangeAccessDocument,
				variables: {
					Access: {
						AD_Client_UU: data.clientUuid,
						AD_Role_UU: data.roleUuid,
						AD_Org_UU: data.organizationUuid,
						M_Warehouse_UU: data.warehouseUuid,
					},
				},
			});
			const role = (
				await graphqlClient.query({
					query: Ad_RoleWithWindowAccessDocument,
					variables: { UU: data.roleUuid },
					fetchPolicy: 'cache-first',
				})
			).data.AD_Role;

			// invalidate the cache
			localStorage.clear();
			await graphqlClient.cache.reset();

			update(
				transformAuthenticationDataToUserContextData(
					user,
					clients,
					{
						clientUU: data.clientUuid,
						organizationUU: data.organizationUuid,
						warehouseUU: data.warehouseUuid,
					},
					role,
				),
			);

			// wait for the REST request to finish
			await restResponse;

			// refresh current page.. better ideas are welcome :-)
			onHandleClose();
			window.location.reload();
		} catch (error) {
			console.error(`Failed with ${error}`);
			exception({ description: `Change Access error: ${error}` });
			toast.error(t(uiText.login.error.ERROR_OCCURRED) + error);
		}
	};

	return (
		<Modal show={true}>
			<Form onSubmit={handleSubmit(onHandleSubmit)}>
				<Modal.Header>{t(uiText.changeAccess.TITLE)}</Modal.Header>
				<Modal.Body>
					<Form.Group as={Row} className={`my-2 ${clients.length > 1 ? '' : 'd-none'}`} controlId="client">
						<Form.Label column>{t(uiText.changeAccess.CLIENT)}</Form.Label>
						<Col xs={8}>
							<Form.Select {...register('clientUuid', { required: true })}>
								<option></option>
								{clients.map((client) => (
									<option key={client.UU} value={client.UU}>
										{client.Name}
									</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>

					<Form.Group
						as={Row}
						className={`my-2 ${clients.length > 1 || organizations.length > 1 ? '' : 'd-none'}`}
						controlId="organization"
					>
						<Form.Label column>{t(uiText.changeAccess.ORGANIZATION)}</Form.Label>
						<Col xs={8}>
							<Form.Select {...register('organizationUuid', { required: true })}>
								<option></option>
								{organizations.map((organization) => (
									<option key={organization.UU} value={organization.UU}>
										{organization.Name}
									</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>

					<Form.Group
						as={Row}
						className={`my-2 ${clients.length > 1 || organizations.length > 1 || roles.length > 1 ? '' : 'd-none'}`}
						controlId="role"
					>
						<Form.Label column>{t(uiText.changeAccess.ROLE)}</Form.Label>
						<Col xs={8}>
							<Form.Select {...register('roleUuid', { required: true })}>
								<option></option>
								{roles.map((role) => (
									<option key={role.UU} value={role.UU}>
										{role.Name}
									</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="my-2" controlId="storeroom">
						<Form.Label column>{t(uiText.changeAccess.STOREROOM)}</Form.Label>
						<Col xs={8}>
							<Form.Select {...register('warehouseUuid', { required: true })}>
								<option></option>
								{warehouses.map((warehouse) => (
									<option key={warehouse.UU} value={warehouse.UU}>
										{warehouse.Name}
									</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" type="button" onClick={onHandleClose}>
						{t(uiText.modal.button.CANCEL)}
					</Button>
					<Button variant="success" type="submit" className="ms-auto">
						{t(uiText.modal.button.CHANGE)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default ChangeAccessModal;
