import { BaseMetadataDB } from './BaseMetadata';

export enum OclConceptSource {
	BHGO = 'BHGO',
	BHLABS = 'BHLabs',
}

export interface OclOriginatingSourceDB extends BaseMetadataDB {
	bh_ocl_source: string;
}
