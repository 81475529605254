import { useApolloClient, useQuery } from '@apollo/client';
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { TableRowProps, UseRowSelectState, UseTableRowProps } from 'react-table';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';
import { ProductRefreshContext } from '../../contexts/ProductRefreshContext';
import UserContext from '../../contexts/UserContext';
import {
	Ad_ProcessRunDocument,
	M_StorageOnHandForInventoryListDocument,
	M_StorageOnHandForInventoryListQuery,
	M_WarehouseForInventoryListDocument,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import useListPageFunctionality from '../../hooks/useListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useStateWithReset from '../../hooks/useStateWithReset';
import { DBFilter, Filter, ListPageState, StorageOnHandDB } from '../../models';
import { CLEAN_EXPIRED_STOCK } from '../../models/Report';
import { exception } from '../../utils/analytics';
import { IS_ACTIVE } from '../../utils/CommonFilters';
import { pageUuid } from '../../utils/Constants';
import { getWarehouseByOrg } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import BasicButton from '../ActionButtons/BasicButton';
import BHGraphQLTable, { BHGraphQLTableProps } from '../BHTable/BHGraphQLTable';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import StockUpdateModal from '../StockTake/StockUpdateModal';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';

const availableFilters = {
	[uiText.inventory.filter.ALL]: IS_ACTIVE as unknown as Filter<StorageOnHandDB>,
	[uiText.inventory.filter.WITH_STOCK]: DBFilter<StorageOnHandDB>()
		.property('qtyonhand')
		.isGreaterThan(0)
		.property('isactive')
		.equals(true)
		.and(
			DBFilter<StorageOnHandDB>()
				.or(
					DBFilter<StorageOnHandDB>()
						.nested('m_attributesetinstance')
						.property('guaranteeDate')
						.isGreaterThanOrEqualTo(new Date())
						.up(),
				)
				.or(DBFilter<StorageOnHandDB>().nested('m_attributesetinstance').property('guaranteeDate').isNull().up()),
		),
	[uiText.inventory.filter.EXPIRED_PRODUCTS]: DBFilter<StorageOnHandDB>()
		.nested('m_attributesetinstance')
		.property('guaranteeDate')
		.isLessThanOrEqualTo(new Date())
		.up()
		.property('isactive')
		.equals(true),
} as const;

type InventoryListProps = {
	showSearch?: boolean;
	showCleanUpButton?: boolean;
	productUuid?: string;
	className?: string;
	paginationClassName?: string;
} & Pick<BHGraphQLTableProps<M_StorageOnHandForInventoryListQuery['M_StorageOnHandGet']['Results'][0]>, 'columns'>;

export type InventoryListRef = {
	refresh: () => void;
	areRefreshing?: boolean;
};

const InventoryList = forwardRef<InventoryListRef, InventoryListProps>(
	({ showSearch, showCleanUpButton, columns, productUuid }, ref) => {
		const { t } = useTranslation();
		const graphqlClient = useApolloClient();

		const [viewModal, setViewModal] = useState(false);

		const [searchText, setSearchText] = useState('');
		const {
			areRefreshing,
			data,
			isLoading,
			onFilterUpdate,
			refresh,
			reset,
			tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
			viewState: [viewState, setViewState],
		} = useListPageFunctionality<M_StorageOnHandForInventoryListQuery['M_StorageOnHandGet']['Results'][0]>({
			fetch: useCallback(
				async (variables) =>
					(
						await graphqlClient.query({
							query: M_StorageOnHandForInventoryListDocument,
							variables,
							fetchPolicy: 'network-only',
						})
					).data.M_StorageOnHandGet,
				[graphqlClient],
			),
			onError: useCallback(
				(error) => {
					if (error.response) {
						toast.error(t(uiText.inventory.loading.FAIL));
					}
					exception({ description: `Inventory fetch error: ${error}` });
				},
				[t],
			),
			refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
		});
		const [selectedData, setSelectedData] = useState<
			M_StorageOnHandForInventoryListQuery['M_StorageOnHandGet']['Results'][0] | undefined
		>();
		// Ensure the methods are available to the parent
		useImperativeHandle(ref, () => ({ refresh, areLoading: areRefreshing }), [refresh, areRefreshing]);

		const { organization } = useContext(UserContext);
		const { triggerUpdate, willTrigger } = useContext(ProductRefreshContext);

		// Filter state
		const [listFilter, setListFilter, { reset: resetListFilter }] = useStateWithReset<keyof typeof availableFilters>(
			uiText.inventory.filter.WITH_STOCK,
		);
		const [warehouseFilter, setWarehouseFilter, { reset: resetWarehouseFilter }] = useStateWithReset('');

		const { data: warehouses } = useQuery(M_WarehouseForInventoryListDocument, {
			variables: { Filter: getWarehouseByOrg(organization.UU).toString() },
			fetchPolicy: 'network-only',
		});

		// Handle searching and filtering and refreshes
		useEffect(() => {
			let defaultFilter = DBFilter<StorageOnHandDB>().and(availableFilters[listFilter]);
			if (searchText) {
				defaultFilter.and(DBFilter<StorageOnHandDB>().nested('m_product').property('name').contains(searchText).up());
			}
			if (productUuid) {
				defaultFilter.and(
					DBFilter<StorageOnHandDB>().nested('m_product').property('m_product_uu').equals(productUuid).up(),
				);
			}

			if (warehouseFilter) {
				// We don't allow filtering of DB IDs
				defaultFilter.and(
					DBFilter<StorageOnHandDB>()
						.nested('m_locator')
						.nested('m_warehouse')
						.property('M_Warehouse_UU')
						.equals(warehouseFilter)
						.up()
						.up(),
				);
			}

			onFilterUpdate(defaultFilter.toString());
		}, [searchText, onFilterUpdate, listFilter, warehouseFilter, productUuid]);

		useUpdateEffect(() => {
			refresh();
		}, [willTrigger]);

		useRefreshOnRepeatedRoute(() => {
			resetListFilter();
			resetWarehouseFilter();
			setSearchText('');
			reset();
		});

		const [{ loading: isStockBeingCleaned }, clean] = useCustomAsyncFn(
			async () =>
				await graphqlClient
					.mutate({
						mutation: Ad_ProcessRunDocument,
						variables: { UU: CLEAN_EXPIRED_STOCK },
					})
					.then(() => {
						toast.success(t(uiText.inventory.STOCK_CLEANED));
					})
					.catch((exceptionMessage) => {
						toast.error(t(uiText.inventory.ERROR_CLEANING));
						exception(t(uiText.inventory.ERROR_CLEANING + exceptionMessage));
					})
					.finally(() => {
						setViewModal(false);
						refresh();
					}),
			[graphqlClient],
		);

		const { disableWrite } = useActionPrivileges(pageUuid.INVENTORY);

		const tableRowProperties = (
			_state?: UseRowSelectState<M_StorageOnHandForInventoryListQuery['M_StorageOnHandGet']['Results'][0]>,
			rowInfo?: UseTableRowProps<M_StorageOnHandForInventoryListQuery['M_StorageOnHandGet']['Results'][0]>,
		): Partial<TableRowProps> & React.HTMLProps<HTMLTableRowElement> => {
			if (!rowInfo) {
				return {};
			}
			return {
				...rowProperties,
				onClick: (e) => {
					setSelectedData(rowInfo.original);
					rowProperties(_state, rowInfo).onClick?.(e);
				},
			};
		};

		return (
			<>
				{isStockBeingCleaned && <LoadSpinner title={t(uiText.inventory.PROCESSING)} />}
				<WorkspaceMenu>
					{showSearch && <WorkspaceMenu.Search initialText={searchText} onSearch={setSearchText} />}
					<WorkspaceMenu.Filters>
						<Form.Group controlId="itemsFilter">
							<Form.Label column>{t(uiText.inventory.filter.SHOW_ITEMS)}</Form.Label>
							<Form.Select
								className="ms-2 w-auto d-inline-block"
								value={listFilter}
								onChange={(e) => setListFilter(e.target.value)}
							>
								{Object.entries(availableFilters).map(([filter]) => (
									<option key={filter} value={filter}>
										{t(filter)}
									</option>
								))}
							</Form.Select>
						</Form.Group>
						{warehouses?.M_WarehouseGet.Results.length! > 1 && (
							<Form.Group controlId="storeroomFilter">
								<Form.Label column>{t(uiText.inventory.filter.SHOW_STOREROOMS)}</Form.Label>
								<Form.Select
									className="ms-2 w-auto d-inline-block"
									value={warehouseFilter}
									onChange={(e) => setWarehouseFilter(e.target.value)}
								>
									<option value="">{t(uiText.inventory.filter.ALL)}</option>
									{warehouses?.M_WarehouseGet.Results.map((warehouse) => (
										<option key={warehouse.UU} value={warehouse.UU}>
											{warehouse.Name}
										</option>
									))}
								</Form.Select>
							</Form.Group>
						)}
					</WorkspaceMenu.Filters>
					<Col xs="auto" className="my-2 ms-auto flex-shrink-0">
						{!disableWrite && showCleanUpButton && (
							<BasicButton
								name={uiText.inventory.button.CLEAN}
								text={t(uiText.inventory.button.CLEAN)}
								active={true}
								icon="trash"
								onClick={() => setViewModal(true)}
								variant="danger"
							/>
						)}
					</Col>
				</WorkspaceMenu>
				<Row className="bg-white ms-0">
					{!disableWrite && viewState === ListPageState.ADD_EDIT && selectedData && (
						<StockUpdateModal
							selectedData={selectedData}
							onFinish={() => {
								setViewState(ListPageState.LIST);
								refresh();
								triggerUpdate();
							}}
						/>
					)}

					<BHGraphQLTable<M_StorageOnHandForInventoryListQuery['M_StorageOnHandGet']['Results'][0]>
						data={data}
						columns={columns}
						defaultPageSize={pageSize}
						pages={pages}
						page={page}
						pageSizeOptions={pageSizeOptions}
						LoadingComponent={() => {
							return <LoadSpinner show={isLoading} title={t(uiText.inventory.loading.LOADING)} />;
						}}
						onFetchData={(data) => {
							if (data.sorted) {
								data.sorted = JSON.stringify(
									(JSON.parse(data.sorted) as Array<[string, string]>).map(([id, desc]) => {
										if (id === 'shelflife') {
											return ['m_attributesetinstance.guaranteedate', desc];
										}
										return [id, desc];
									}),
								);
							}
							onTableUpdate(data);
						}}
						getTrGroupProps={tableRowProperties}
						sortBy={sorted}
						totalRecordCount={totalRecordCount}
					/>
				</Row>
				<Modal show={viewModal}>
					<Modal.Header>{t(uiText.inventory.button.CLEAN)}</Modal.Header>
					<Modal.Body>{t(uiText.inventory.CLEAN_CONFIRMATION_MESSAGE)}</Modal.Body>
					<Modal.Footer>
						<Button variant="danger" onClick={() => setViewModal(false)}>
							{t(uiText.modal.button.CANCEL)}
						</Button>
						<Button variant="success" className="ms-auto" data-dismiss="modal" onClick={clean}>
							{t(uiText.modal.button.CONFIRM)}
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	},
);

export default InventoryList;
