import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, Col, ListGroup } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { useAsync } from 'react-use';
import { v4 } from 'uuid';
import logo from '../../assets/images/b-logo.png';
import MenuContext from '../../contexts/MenuContext';
import UserContext from '../../contexts/UserContext';
import { Ad_MenuForMainMenuPageDocument, Ad_MenuForMainMenuPageQuery } from '../../graphql/__generated__/graphql';
import { DBFilter, getMenuBrand, getMenuClassName, MenuDB, menuUuid } from '../../models';
import { HOME_PAGE, pageUuid } from '../../utils/Constants';
import { siteMap } from '../App/App';
import './MainMenu.scss';

type AD_MenuNode = NonNullable<
	NonNullable<Ad_MenuForMainMenuPageQuery['AD_MenuGet']['Results'][0]['ChildrenTree_NodeMMList']>[0]['Node']
>;

const getPath = (menu: { AD_Window?: { UU: string } | null }) => siteMap[menu.AD_Window?.UU || '']?.path || HOME_PAGE;

const getAllChildrenUUs = (menu: AD_MenuNode): string[] => {
	if (!!menu.ChildrenTree_NodeMMList?.length) {
		return menu.ChildrenTree_NodeMMList.filter((nodeList) => !!nodeList.Node).reduce(
			(menuUUList, childMenu) => [...menuUUList, ...getAllChildrenUUs(childMenu.Node!)],
			[] as string[],
		);
	}
	return [menu.UU];
};

const MenuItem = ({
	menu,
	canViewReportsMenu,
	nested = false,
	onPageSelected,
}: {
	menu: AD_MenuNode;
	canViewReportsMenu: boolean;
	nested?: boolean;
	onPageSelected: (allMenuUUSelections: string[], path?: string) => void;
}) => {
	const { organization } = useContext(UserContext);
	const {
		activeMenuUuid = '',
		selectedMenuUuids = [],
		setSelectedMenuUuids = () => {},
		activeMenuUuidFamily = [],
	} = useContext(MenuContext) || {};
	const allChildrenMenuUUs = useMemo(() => getAllChildrenUUs(menu), [menu]);
	const [hidden, setHidden] = useState<boolean>((menu && !activeMenuUuidFamily.includes(menu!.UU)) || false);
	const brand = getMenuBrand(menu);
	const className = getMenuClassName(menu);

	// If this isn't active, or it's an item needing more than one warehouse and they only have one, or it
	// doesn't have children and doesn't have a window it links to, return nothing
	if (
		!menu.IsActive ||
		(menu.AD_Window?.UU === pageUuid.TRANSFER_INVENTORY && (organization.M_Warehouses?.length || 0) <= 1) ||
		(!menu.ChildrenTree_NodeMMList?.length && !menu.AD_Window) ||
		(menu.AD_Window?.UU === pageUuid.REPORTS && !canViewReportsMenu)
	) {
		return <></>;
	}
	// If it has no children or none of it's children are supposed to be shown, just show a link
	if (!menu.ChildrenTree_NodeMMList?.length) {
		return (
			<ListGroup.Item
				as={NavLink}
				key={menu.UU}
				to={getPath(menu)}
				className={`${nested ? 'border-0 ps-5' : ''} ${activeMenuUuid === menu.UU ? 'active' : ''}`}
				action
				activeClassName={''}
				onClick={() => onPageSelected([menu.UU], getPath(menu))}
			>
				{className && <FontAwesomeIcon icon={[brand, className]} className="me-2 fa-fw" />}
				<span>{menu.Name}</span>
			</ListGroup.Item>
		);
	}
	return (
		<ListGroup.Item
			className={`border-top-0 p-0 ${allChildrenMenuUUs.includes(activeMenuUuid) ? 'active' : ''}`}
			action
			as="div"
		>
			<div role="heading" aria-level={nested ? 1 : 0}>
				<div
					className={`accordion-button py-2_5 cursor-pointer shadow-none pe-4 ${
						allChildrenMenuUUs.includes(activeMenuUuid) ? 'bh-accordion-selected' : ''
					} ${selectedMenuUuids.includes(menu.UU) ? '' : 'collapsed'}`}
					onClick={() => {
						// If this menu is part of the active family, we do nothing
						if (activeMenuUuidFamily.includes(menu.UU)) {
							return;
						}
						// Add/remove it as necessary
						if (selectedMenuUuids.includes(menu.UU)) {
							setSelectedMenuUuids([...activeMenuUuidFamily]);
						} else {
							setSelectedMenuUuids([...activeMenuUuidFamily, menu.UU]);
						}
					}}
					role="button"
					aria-expanded={selectedMenuUuids.includes(menu.UU) ? 'true' : 'false'}
					aria-controls={`${menu.UU}Region`}
					aria-disabled={activeMenuUuidFamily.includes(menu.UU) || undefined}
					id={`${menu.UU}Accordion`}
				>
					{className && <FontAwesomeIcon icon={[brand, className]} className="me-2 fa-fw" />}
					<span>{menu.Name}</span>
				</div>
			</div>
			<div
				role="region"
				id={`${menu.UU}Region`}
				aria-labelledby={`${menu.UU}Accordion`}
				hidden={!selectedMenuUuids.includes(menu.UU) && hidden}
			>
				<Accordion.Collapse
					eventKey={menu.UU}
					className="p-0"
					onExited={() => setHidden(true)}
					onEntered={() => setHidden(false)}
				>
					<>
						{sortBy(menu.ChildrenTree_NodeMMList, 'SeqNo').flatMap((nodeList) =>
							!!nodeList.Node
								? [
										<MenuItem
											menu={nodeList.Node}
											canViewReportsMenu={canViewReportsMenu}
											key={`${v4()}-${nodeList.Node.UU}`}
											nested={true}
											onPageSelected={(childSelections) => onPageSelected([...childSelections, menu.UU])}
										/>,
									]
								: [],
						)}
					</>
				</Accordion.Collapse>
			</div>
		</ListGroup.Item>
	);
};

const MainMenu = () => {
	const { role } = useContext(UserContext);
	const [activeMenuUuid, setActiveMenuUuid] = useState('');
	const [activeMenuUuidFamily, setActiveMenuUuidFamily] = useState<string[]>([]);
	const [selectedMenuUuids, setSelectedMenuUuids] = useState<string[]>([]);
	const { pathname } = useLocation();
	const renderedPathName = useRef(pathname);
	const graphqlClient = useApolloClient();

	const { value: menus = [] } = useAsync(async () => {
		return (
			await graphqlClient.query({
				query: Ad_MenuForMainMenuPageDocument,
				variables: {
					Filter: DBFilter<MenuDB>().property('ad_menu_uu').equals(menuUuid.GREENLIGHT_MAIN_MENU).toString(),
				},
				fetchPolicy: 'cache-first',
			})
		).data.AD_MenuGet.Results[0]?.ChildrenTree_NodeMMList?.flatMap((nodeList) =>
			!!nodeList.Node ? [nodeList.Node] : [],
		);
	}, [role.UU, graphqlClient]);

	// The report menu item is special (we need to see if the user has any reports they can run and display it, if so)
	const { value: canViewReportsMenu = false } = useAsync(
		async () =>
			!!(
				await graphqlClient.query({
					query: Ad_MenuForMainMenuPageDocument,
					variables: {
						Filter: DBFilter<MenuDB>().property('ad_menu_uu').equals(menuUuid.REPORTS_DROPDOWN).toString(),
					},
					fetchPolicy: 'cache-first',
				})
			).data.AD_MenuGet.Results[0].ChildrenTree_NodeMMList?.length,
		[graphqlClient, role.UU],
	);

	// For the initial page load, we need to set the selected menu family
	const haveSetInitialState = useRef(false);
	useEffect(() => {
		if ((haveSetInitialState.current && renderedPathName.current === pathname) || !menus.length || !pathname) {
			return;
		}
		haveSetInitialState.current = true;
		const selectedMenuFamilyUUs = (() => {
			let foundMenu = menus.find((menu) => getPath(menu) === pathname);
			if (foundMenu) {
				return [foundMenu];
			}

			foundMenu = menus.find((menu) =>
				menu.ChildrenTree_NodeMMList?.some((node) => !!node.Node && getPath(node.Node) === pathname),
			);
			if (!foundMenu) {
				return [];
			}
			return [
				foundMenu?.ChildrenTree_NodeMMList?.find((node) => node.Node && getPath(node.Node) === pathname)?.Node!,
				foundMenu,
			];
		})().map((menu) => menu.UU);
		renderedPathName.current = pathname;
		setActiveMenuUuid(selectedMenuFamilyUUs[0] || '');
		setSelectedMenuUuids(selectedMenuFamilyUUs);
		setActiveMenuUuidFamily(selectedMenuFamilyUUs);
	}, [menus, pathname]);

	return (
		<MenuContext.Provider value={{ activeMenuUuid, selectedMenuUuids, setSelectedMenuUuids, activeMenuUuidFamily }}>
			<Col className="px-0 shadow print__d-none menu-wrapper min-vh-100 bg-white">
				<div className="position-sticky top-0 vh-100">
					<div className="position-absolute">
						<div className="menu-logo w-100 d-flex justify-content-center py-1">
							<img src={logo} alt="BandaHealth Logo" />
						</div>
						<Accordion flush as={ListGroup} variant="flush" activeKey={selectedMenuUuids}>
							{sortBy(menus, 'SeqNo').map((menu) => (
								<MenuItem
									menu={menu}
									canViewReportsMenu={canViewReportsMenu}
									key={menu.UU}
									onPageSelected={(allSelections, path) => {
										setActiveMenuUuid(allSelections[0]);
										setSelectedMenuUuids(allSelections);
										setActiveMenuUuidFamily(allSelections);
										if (path) {
											renderedPathName.current = path;
										}
									}}
								/>
							))}
						</Accordion>
					</div>
				</div>
			</Col>
		</MenuContext.Provider>
	);
};

export default MainMenu;
