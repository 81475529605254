import { BaseEntityDB } from './BaseEntity';
import { chargeTypeDefaultNames } from './ChargeType';
import DBFilter from './DBFilter';

export const chargeDefaultNames = { BAD_DEBT: 'Bad debt write-off - DO NOT CHANGE' } as const;

export const chargeSubType = { Insurance: 'I', Waiver: 'W', Donation: 'D' } as const;

export interface ChargeDB extends BaseEntityDB {
	type: string;
	c_chargetype: BaseEntityDB;
	bh_subtype: string;
}

export const chargeNonPatientPaymentFilter = () =>
	DBFilter<ChargeDB>().nested('c_chargetype').property('name').equals(chargeTypeDefaultNames.NON_PATIENT_PAYMENTS).up();

export const chargeExpenseCategoryFilter = () =>
	DBFilter<ChargeDB>().nested('c_chargetype').property('name').equals(chargeTypeDefaultNames.EXPENSE_CATEGORY).up();

export const chargeOneOffsFilter = () =>
	DBFilter<ChargeDB>().nested('c_chargetype').property('name').equals(chargeTypeDefaultNames.ONE_OFFS).up();
