import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Controller, useFieldArray, UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { v4 } from 'uuid';
import {
	Ad_Ref_ListNonPatientPayerCategoriesForVisitsQuery,
	Ad_Ref_ListTenderTypesForVisitsQuery,
	Bh_Bp_Payer_InfoGetForVisitPatientQuery,
	C_BPartnerForVisitsInsurersAndDonorsQuery,
	C_ChargeForVisitsWaiversQuery,
} from '../../graphql/__generated__/graphql';
import { businessPartnerGroupSubType, sortPaymentTypesGraphQL } from '../../models';
import { chargeSubType } from '../../models/Charge';
import { uiText } from '../../utils/Language';
import DynamicSelect from '../dynamic-select/DynamicSelect';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import PayerInformationInput from '../Patient/PayerInformationInput';
import EditPatientInformation from './EditPatientInformation';
import { PaymentLineItemTableFormValues } from './PaymentLineItemTable';
import { VisitFormValues } from './VisitForm';

type PaymentInformationRowProps = {
	field?: PaymentLineItemTableFormValues['paymentInformationList'][0];
	index?: number;
	remove?: UseFieldArrayReturn['remove'];
	paymentTypes?: Ad_Ref_ListTenderTypesForVisitsQuery['AD_Ref_ListGet']['Results'];
	subTypes?: Ad_Ref_ListNonPatientPayerCategoriesForVisitsQuery['AD_Ref_ListGet']['Results'];
	insuranceAndDonorList?: C_BPartnerForVisitsInsurersAndDonorsQuery['C_BPartnerGet']['Results'];
	waiver?: C_ChargeForVisitsWaiversQuery['C_ChargeGet']['Results'][0];
	patientPayerInformationList?: Bh_Bp_Payer_InfoGetForVisitPatientQuery['BH_BP_Payer_InfoGet']['Results'];
	onChargesUpdated?: () => void;
	shouldDisplayTheDeleteColumn: boolean;
	isVisitFinished?: boolean;
};

const PaymentInformationRow = ({
	field,
	index,
	remove,
	paymentTypes = [],
	subTypes = [],
	insuranceAndDonorList = [],
	waiver,
	patientPayerInformationList = [],
	onChargesUpdated,
	shouldDisplayTheDeleteColumn,
	isVisitFinished,
}: PaymentInformationRowProps) => {
	const { t } = useTranslation();
	const { register, setValue } = useFormContext<PaymentLineItemTableFormValues>();
	const [viewModal, setViewModal] = useState(false);
	const isFirstRender = useRef(true);
	const areRemovingChargeInformation = useRef(false);
	const areReadyToRespondToPaymentTypeUuidChanges = useRef(false);
	const initialBusinessPartnerSpecificPayerInformationList = useRef(field?.BH_BP_Specific_Payer_InfoList || []);

	const patientUU = useWatch<VisitFormValues, 'Patient.UU'>({ name: 'Patient.UU' });

	const fieldPrefix = `paymentInformationList.${index}`;
	const {
		fields: businessPartnerSpecificPayerInformationList,
		remove: removeBusinessPartnerSpecificPayerInformation,
		append,
	} = useFieldArray<PaymentLineItemTableFormValues, 'paymentInformationList.0.BH_BP_Specific_Payer_InfoList', 'UU'>({
		name: `${fieldPrefix}.BH_BP_Specific_Payer_InfoList` as 'paymentInformationList.0.BH_BP_Specific_Payer_InfoList',
		keyName: 'UU',
	});
	const paymentTypeUuid = useWatch<PaymentLineItemTableFormValues, 'paymentInformationList.0.PaymentType.UU'>({
		name: `${fieldPrefix}.PaymentType.UU` as 'paymentInformationList.0.PaymentType.UU',
	});
	const insurerOrDonorUuid = useWatch<PaymentLineItemTableFormValues, 'paymentInformationList.0.Payer.UU'>({
		name: `${fieldPrefix}.Payer.UU` as 'paymentInformationList.0.Payer.UU',
	});
	const isPayment =
		useWatch<PaymentLineItemTableFormValues, 'paymentInformationList.0.isPayment'>({
			name: `${fieldPrefix}.isPayment` as 'paymentInformationList.0.isPayment',
		}) === 'true';
	const isWaiver =
		useWatch<PaymentLineItemTableFormValues, 'paymentInformationList.0.isWaiver'>({
			name: `${fieldPrefix}.isWaiver` as 'paymentInformationList.0.isWaiver',
		}) === 'true';

	// When the payment type uuid changes, handle it
	useUpdateEffect(() => {
		// Don't run this on first render
		if (isVisitFinished || !subTypes.length || !insuranceAndDonorList.length || !waiver) {
			return;
		}
		// We're not going to deal with interoperability of swapping from payments to invoice lines and whatnot,
		// so each change of payment type (which actually changes entity type) will generate a new UU
		setValue(`${fieldPrefix}.UU` as 'paymentInformationList.0.UU', v4());
		// If it's a sub type, we need to update the non-patient payment field
		let isPayment: boolean | undefined;
		let isWaiver: boolean | undefined;
		if (paymentTypeUuid) {
			isPayment = paymentTypes.some((paymentType) => paymentType.UU === paymentTypeUuid);
			isWaiver = subTypes.find((subType) => subType.UU === paymentTypeUuid)?.Value === chargeSubType.Waiver;
			setValue(`${fieldPrefix}.isPayment` as 'paymentInformationList.0.isPayment', isPayment ? 'true' : 'false');
			setValue(`${fieldPrefix}.isWaiver` as 'paymentInformationList.0.isWaiver', isWaiver ? 'true' : 'false');
		}
		// Only do this AFTER the first pass-through of this effect (because sub types, BPs, and the waiver are loading then
		// and we only want to respond to payment type changes in here)
		if (areReadyToRespondToPaymentTypeUuidChanges.current) {
			// See if the selected payment type matches a any insurance or donors
			const businessPartnersForThisPaymentType = insuranceAndDonorList.filter(
				(businessPartner) => businessPartner.C_BP_Group.BH_SubType?.UU === paymentTypeUuid,
			);
			if (isWaiver === true) {
				// Clear the payer and set the charge
				setValue(`${fieldPrefix}.Payer.UU` as 'paymentInformationList.0.Payer.UU', '');
				setValue(`${fieldPrefix}.C_Charge.UU` as 'paymentInformationList.0.C_Charge.UU', waiver.UU);
			} else if (isPayment === false && isWaiver === false && businessPartnersForThisPaymentType.length === 1) {
				// We only have a single insurer/donor, so select everything
				setValue(
					`${fieldPrefix}.Payer.UU` as 'paymentInformationList.0.Payer.UU',
					businessPartnersForThisPaymentType[0].UU,
				);
				setValue(
					`${fieldPrefix}.C_Charge.UU` as 'paymentInformationList.0.C_Charge.UU',
					businessPartnersForThisPaymentType[0].C_BP_Group.AssociatedCustomerReceivablesCharge?.UU || '',
				);
			} else {
				// Just clear the payer & charge
				setValue(`${fieldPrefix}.Payer.UU` as 'paymentInformationList.0.Payer.UU', '');
				setValue(`${fieldPrefix}.C_Charge.UU` as 'paymentInformationList.0.C_Charge.UU', '');
			}
		}
		areReadyToRespondToPaymentTypeUuidChanges.current = true;
	}, [paymentTypes, subTypes, paymentTypeUuid, setValue, fieldPrefix, isVisitFinished, insuranceAndDonorList, waiver]);

	// As soon as the selected insurer/donor changes (and we can edit the visit), remove the entered payer information
	useUpdateEffect(() => {
		// Don't run this on first render (if there's no saved data)
		if (
			(isFirstRender.current && !initialBusinessPartnerSpecificPayerInformationList.current.length) ||
			isVisitFinished
		) {
			return;
		}
		areRemovingChargeInformation.current = true;
		removeBusinessPartnerSpecificPayerInformation();
	}, [insurerOrDonorUuid, removeBusinessPartnerSpecificPayerInformation, isVisitFinished]);

	// Define some helper functions that fetch data
	const selectedInsurerOrDonor = useMemo(
		() => insuranceAndDonorList.find((businessPartner) => businessPartner.UU === insurerOrDonorUuid),
		[insuranceAndDonorList, insurerOrDonorUuid],
	);
	const patientPayerInformation = useMemo(
		() =>
			patientPayerInformationList.find(
				(patientPayerInformation) => patientPayerInformation.BH_Payer.UU === selectedInsurerOrDonor?.UU,
			),
		[patientPayerInformationList, selectedInsurerOrDonor],
	);
	const selectedPayerInformationFieldList = useMemo(
		() =>
			sortBy(selectedInsurerOrDonor?.BH_Payer_Info_FldList || [], 'Line').filter(
				(payerInformationField) => payerInformationField.IsActive,
			),
		[selectedInsurerOrDonor],
	);

	// If the selected insurer/donor changes, we need to add data (an effect above handles clearing the data)
	useUpdateEffect(() => {
		// If there's no insurer/donor selected (or none to select), there's nothing else to do
		if (!insurerOrDonorUuid || !insuranceAndDonorList.length || isVisitFinished) {
			return;
		}
		// Update the correct insurer/donor
		setValue(
			`${fieldPrefix}.C_Charge.UU` as 'paymentInformationList.0.C_Charge.UU',
			insuranceAndDonorList.find((businessPartner) => businessPartner.UU === insurerOrDonorUuid)?.C_BP_Group
				.AssociatedCustomerReceivablesCharge?.UU || '',
		);
		// If the selected insurer/donor has no information, there's nothing else to do
		if (!selectedPayerInformationFieldList.length) {
			return;
		}
		// If there aren't already information fields added, do it
		// Else, update values to ensure the most recent information is displayed
		if (!businessPartnerSpecificPayerInformationList?.length) {
			// If there is initial data, use that
			const dataToUse = initialBusinessPartnerSpecificPayerInformationList.current.length
				? initialBusinessPartnerSpecificPayerInformationList.current
				: businessPartnerSpecificPayerInformationList;
			append(
				selectedPayerInformationFieldList.map((payerInformationField) => {
					let businessPartnerSpecificPayerInformation:
						| PaymentLineItemTableFormValues['paymentInformationList'][0]['BH_BP_Specific_Payer_InfoList'][0]
						| undefined = undefined;
					if (isVisitFinished || !payerInformationField.BH_FillFromPatient) {
						businessPartnerSpecificPayerInformation = dataToUse.find(
							(invoiceLinePayerInformation) =>
								invoiceLinePayerInformation.BH_Payer_Info_Fld.UU === payerInformationField.UU,
						);
					} else {
						// Otherwise, get what's on the patient
						businessPartnerSpecificPayerInformation = patientPayerInformation?.BH_BP_General_Payer_InfoList?.find(
							(businessPartnerGeneralPayerInformation) =>
								businessPartnerGeneralPayerInformation.BH_Payer_Info_Fld.UU === payerInformationField.UU,
						);
					}
					return {
						UU: businessPartnerSpecificPayerInformation?.UU || v4(),
						BH_Payer_Info_Fld: { UU: payerInformationField.UU },
						Name: businessPartnerSpecificPayerInformation?.Name || null,
					};
				}),
			);
			// The chance to use initial data is over, so clear it
			initialBusinessPartnerSpecificPayerInformationList.current = [];
		} else if (!areRemovingChargeInformation.current) {
			// The above is necessary because, if a charge already exists and a user is changing it, this will run
			// at the same time as the remove, but orderLineChargeInformationList will not have been updated yet
			businessPartnerSpecificPayerInformationList.forEach(
				(businessPartnerSpecificPayerInformation, businessPartnerSpecificPayerInformationIndex) => {
					// The associated charge information might come back null if the user is changing from one charge to another
					let associatedPayerInformationField = selectedPayerInformationFieldList.find(
						(payerInformationField) =>
							payerInformationField.UU === businessPartnerSpecificPayerInformation.BH_Payer_Info_Fld.UU,
					);
					let nameToUse = businessPartnerSpecificPayerInformation.Name;
					// If the visit isn't finished, pull from the patient
					if (!isVisitFinished && associatedPayerInformationField?.BH_FillFromPatient) {
						nameToUse =
							(
								patientPayerInformation?.BH_BP_General_Payer_InfoList?.find(
									(businessPartnerGeneralPayerInformation) =>
										businessPartnerGeneralPayerInformation.BH_Payer_Info_Fld.UU === associatedPayerInformationField?.UU,
								) || {}
							).Name || null;
					}
					setValue(
						`${fieldPrefix}.BH_BP_Specific_Payer_InfoList.${businessPartnerSpecificPayerInformationIndex}.Name` as 'paymentInformationList.0.BH_BP_Specific_Payer_InfoList.0.Name',
						nameToUse,
					);
				},
			);
		}
	}, [
		insurerOrDonorUuid,
		isVisitFinished,
		businessPartnerSpecificPayerInformationList,
		selectedPayerInformationFieldList,
		append,
		patientPayerInformation,
		fieldPrefix,
		setValue,
		insuranceAndDonorList,
		isVisitFinished,
	]);

	// Hide the row if we have one charge (it will be auto-selected) and there is no data to collect for the one charge selected
	const shouldHideAdditionalRow =
		insuranceAndDonorList.filter((businessPartner) => businessPartner.C_BP_Group.BH_SubType?.UU === paymentTypeUuid)
			.length === 1 && !businessPartnerSpecificPayerInformationList.length;

	// Sometimes the dropdown display isn't updated, so make sure it gets updated when the values come through
	let fieldPayerUuid = field?.Payer?.UU;
	if (!fieldPayerUuid && shouldHideAdditionalRow) {
		fieldPayerUuid = insuranceAndDonorList.filter(
			(businessPartner) => businessPartner.C_BP_Group.BH_SubType?.UU === paymentTypeUuid,
		)[0].UU;
	}
	useEffect(() => {
		if (insuranceAndDonorList.length && !isVisitFinished) {
			setValue(`${fieldPrefix}.Payer.UU` as 'paymentInformationList.0.Payer.UU', fieldPayerUuid || '');
		}
	}, [insuranceAndDonorList, fieldPayerUuid, fieldPrefix, setValue, isVisitFinished, shouldHideAdditionalRow]);

	// Now that we're returning data, say the first render is over
	isFirstRender.current = false;
	areRemovingChargeInformation.current = false;

	return (
		<>
			<tr>
				<td>
					<input type="hidden" {...register(`${fieldPrefix}.UU` as 'paymentInformationList.0.UU')} />
					<input type="hidden" {...register(`${fieldPrefix}.isPayment` as 'paymentInformationList.0.isPayment')} />
					<input type="hidden" {...register(`${fieldPrefix}.isWaiver` as 'paymentInformationList.0.isWaiver')} />
					<Form.Select
						aria-label={t(uiText.visit.form.payment.table.TYPE)}
						{...register(`${fieldPrefix}.PaymentType.UU` as 'paymentInformationList.0.PaymentType.UU')}
					>
						{[...sortPaymentTypesGraphQL(paymentTypes.filter((paymentType) => paymentType.IsActive)), ...subTypes].map(
							(type) => (
								<option key={type.UU} value={type.UU}>
									{type.Name}
								</option>
							),
						)}
					</Form.Select>
				</td>
				<td>
					<Controller<PaymentLineItemTableFormValues, 'paymentInformationList.0.PayAmt'>
						name={`${fieldPrefix}.PayAmt` as 'paymentInformationList.0.PayAmt'}
						rules={{ validate: (value) => (value && value > 0) || false }}
						render={({ field }) => (
							<FormatNumberInput aria-label={t(uiText.visit.form.payment.table.AMOUNT_PAID)} min={0} {...field} />
						)}
					/>
				</td>

				<td>
					<Form.Control {...register(`${fieldPrefix}.Description` as 'paymentInformationList.0.Description')} />
				</td>
				{shouldDisplayTheDeleteColumn ? (
					<td className="align-middle text-center print__d-none" onClick={() => remove && remove(index)}>
						<button
							type="button"
							className="btn p-0 w-100 flex-grow-1"
							tabIndex={-1}
							aria-label={t(uiText.visit.button.DELETE)}
						>
							<FontAwesomeIcon icon={faTrash} />
						</button>
					</td>
				) : null}
			</tr>
			{!isPayment && !isWaiver && (
				<tr className={shouldHideAdditionalRow ? 'd-none' : ''}>
					<td colSpan={4}>
						<Row className="align-items-center">
							<Col xs={2}>
								<DynamicSelect
									aria-label={t(
										selectedInsurerOrDonor?.C_BP_Group.BH_SubType?.Value === businessPartnerGroupSubType.Donation
											? uiText.visit.form.additionalInformation.DONOR
											: uiText.visit.form.additionalInformation.INSURER,
									)}
									{...register(`${fieldPrefix}.Payer.UU` as 'paymentInformationList.0.Payer.UU', { required: true })}
									className="keep-border"
									isLoading={!insuranceAndDonorList.length}
								>
									<option value="">{t(uiText.visit.form.additionalInformation.SELECT_TYPE)}</option>
									{insuranceAndDonorList
										.filter((businessPartner) => businessPartner.C_BP_Group.BH_SubType?.UU === paymentTypeUuid)
										.map((businessPartner) => (
											<option key={businessPartner.UU} value={businessPartner.UU}>
												{businessPartner.Name}
											</option>
										))}
								</DynamicSelect>
							</Col>
							<Col xs={10}>
								<Row className="gy-3">
									{businessPartnerSpecificPayerInformationList.map(
										(businessPartnerSpecificPayerInformation, businessPartnerSpecificPayerInformationIndex) => {
											const payerInformationField = selectedPayerInformationFieldList.find(
												(payerInformationField) =>
													payerInformationField.UU === businessPartnerSpecificPayerInformation.BH_Payer_Info_Fld.UU,
											);
											if (!payerInformationField) {
												return null;
											}
											return (
												<Col xs={3} key={businessPartnerSpecificPayerInformation.UU}>
													<input
														type="hidden"
														{...register(
															`${fieldPrefix}.BH_BP_Specific_Payer_InfoList.${businessPartnerSpecificPayerInformationIndex}.BH_Payer_Info_Fld.UU` as 'paymentInformationList.0.BH_BP_Specific_Payer_InfoList.0.BH_Payer_Info_Fld.UU',
														)}
													/>
													<input
														type="hidden"
														{...register(
															`${fieldPrefix}.BH_BP_Specific_Payer_InfoList.${businessPartnerSpecificPayerInformationIndex}.UU` as 'paymentInformationList.0.BH_BP_Specific_Payer_InfoList.0.UU',
														)}
													/>
													{isVisitFinished ? (
														<Form.Control
															className="keep-border"
															readOnly
															{...register(
																`${fieldPrefix}.BH_BP_Specific_Payer_InfoList.${businessPartnerSpecificPayerInformationIndex}.Name` as 'paymentInformationList.0.BH_BP_Specific_Payer_InfoList.0.Name',
															)}
														/>
													) : !!payerInformationField?.BH_FillFromPatient ? (
														<InputGroup className="input-group" onClick={() => setViewModal(true)}>
															<Form.Control
																type="text"
																className="keep-border cursor-pointer"
																placeholder={`${t(uiText.patient.additionalInformation.ENTER_PREFIX)} ${
																	payerInformationField.Name
																}`}
																readOnly
																{...register(
																	`${fieldPrefix}.BH_BP_Specific_Payer_InfoList.${businessPartnerSpecificPayerInformationIndex}.Name` as 'paymentInformationList.0.BH_BP_Specific_Payer_InfoList.0.Name',
																)}
															/>
															<InputGroup.Text className="keep-border cursor-pointer">
																<FontAwesomeIcon icon={['fas', 'edit']} />
															</InputGroup.Text>
														</InputGroup>
													) : (
														<PayerInformationInput
															payerInformationField={payerInformationField!}
															inputPlaceholder={`${t(uiText.patient.additionalInformation.ENTER_PREFIX)} ${
																payerInformationField?.Name || ''
															}`}
															selectPlaceholder={`${t(uiText.patient.additionalInformation.SELECT_PREFIX)} ${
																payerInformationField?.Name || ''
															}`}
															registerReturn={register(
																`${fieldPrefix}.BH_BP_Specific_Payer_InfoList.${businessPartnerSpecificPayerInformationIndex}.Name` as 'paymentInformationList.0.BH_BP_Specific_Payer_InfoList.0.Name',
															)}
														/>
													)}
												</Col>
											);
										},
									)}
								</Row>
							</Col>
						</Row>
						{viewModal && selectedInsurerOrDonor && patientUU && (
							<EditPatientInformation
								patientUU={patientUU}
								onCancel={() => setViewModal(false)}
								onSave={() => {
									setViewModal(false);
									if (onChargesUpdated) {
										onChargesUpdated();
									}
								}}
								businessPartnerPayerInformation={patientPayerInformation}
								selectedPayer={selectedInsurerOrDonor}
							/>
						)}
					</td>
				</tr>
			)}
		</>
	);
};

export default PaymentInformationRow;
