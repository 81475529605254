import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useContext, useState } from 'react';
import { Card, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import FormModalContext from '../../contexts/FormModalContext';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import UserContext from '../../contexts/UserContext';
import {
	Ad_Ref_ListBusinessPartnerNeedsDocument,
	C_BPartnerForSupplierEditingDocument,
	C_BPartnerForSupplierEditingQuery,
	C_BPartnerInsurerOrDonorSaveMutationVariables,
	C_BPartnerSaveForSuppliersDocument,
	C_LocationForOrganizationDocument,
	C_PaymentTermForBusinessPartnersDocument,
	M_PriceListsForBusinessPartnersDocument,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import { BaseEntityDB, DBFilter, LocationDB, PriceListDB, ReferenceListDB, referenceUuids } from '../../models';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import { pageUuid } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import BasicButton from '../ActionButtons/BasicButton';
import BHDropdownButton from '../ActionButtons/BHDropdownButton';
import { withFormModalSuspenseWrapper } from '../HOCs/withFormModalSuspenseWrapper';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';

export type SupplierFormProps = EntityFormProperties;
type SupplierFormFields = {
	UU: string;
	BH_EMail: string | null;
	BH_Phone: string | null;
	Contact: { UU: string };
	Name: string;
	IsActive: boolean;
	submitEvent: '' | 'save' | 'saveAndNew';
};

const getTitle = (uuid?: string) => (uuid ? uiText.supplier.title.UPDATE : uiText.supplier.title.NEW);

const convertToFormFields: (initialData?: C_BPartnerForSupplierEditingQuery['C_BPartner']) => SupplierFormFields = (
	initialData,
) => {
	if (!initialData) {
		return {
			UU: v4(),
			BH_EMail: null,
			BH_Phone: null,
			Contact: { UU: v4() },
			Name: '',
			IsActive: true,
			submitEvent: '',
		};
	}
	return {
		UU: initialData.UU,
		BH_EMail: initialData.BH_EMail || null,
		BH_Phone: initialData.BH_Phone || null,
		Contact: { UU: initialData.Contacts?.[0]?.UU || v4() },
		Name: initialData.Name,
		IsActive: initialData.IsActive,
		submitEvent: '',
	};
};

const SupplierForm = ({ uuid, onFinish, renderAsModal, canSaveMany }: SupplierFormProps) => {
	const graphqlClient = useApolloClient();
	const { organization } = useContext(UserContext);
	const {
		data: [selectedData, locationForOrganization, businessPartnerReferenceLists, paymentTerms, priceLists] = [],
	} = useSuspenseAsync(uuid || 'add-supplier', async () =>
		Promise.all([
			uuid
				? graphqlClient
						.query({
							query: C_BPartnerForSupplierEditingDocument,
							variables: { UU: uuid },
							fetchPolicy: 'network-only',
						})
						.then((response) => response?.data.C_BPartner)
				: undefined,
			graphqlClient.query({
				query: C_LocationForOrganizationDocument,
				variables: {
					Filter: DBFilter<LocationDB>()
						.nested('ad_orginfo')
						.nested('ad_org')
						.property('ad_org_uu')
						.equals(organization.UU)
						.up()
						.up()
						.toString(),
				},
				fetchPolicy: 'cache-first',
			}),
			graphqlClient.query({
				query: Ad_Ref_ListBusinessPartnerNeedsDocument,
				variables: {
					Filter: DBFilter<ReferenceListDB>()
						.nested('ad_reference')
						.property('ad_reference_uu')
						.isIn([
							referenceUuids.BUSINESS_PARTNER_SALES_ORDER_CREDIT_STATUS,
							referenceUuids.ORDER_INVOICE_RULES,
							referenceUuids.PAYMENT_TYPES,
							referenceUuids.USER_NOTIFICATION_TYPES,
						])
						.up()
						.toString(),
				},
				fetchPolicy: 'cache-first',
			}),
			graphqlClient.query({
				query: C_PaymentTermForBusinessPartnersDocument,
				variables: { Size: 1, Filter: DBFilter<BaseEntityDB>().property('name').equals('Immediate').toString() },
				fetchPolicy: 'cache-first',
			}),
			graphqlClient.query({
				query: M_PriceListsForBusinessPartnersDocument,
				variables: {
					Sort: JSON.stringify([['created', 'desc']]),
					Filter: DBFilter<PriceListDB>()
						.property('isdefault')
						.equals(true)
						.property('isactive')
						.equals(true)
						.toString(),
				},
				fetchPolicy: 'cache-first',
			}),
		]),
	);

	const { t } = useTranslation();
	const { disableWrite, disableDeactivate } = useActionPrivileges(pageUuid.SUPPLIERS);
	const { toggleNavBlocking } = useContext(NavBlockingContext);

	const [initialData, setInitialData] = useState(selectedData);
	const title = getTitle(initialData ? uuid : undefined);

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors, isDirty },
	} = useForm<SupplierFormFields>({
		defaultValues: convertToFormFields(initialData),
	});

	const { dataWasSaved, savedData, wasDataSaved } = useContext(FormModalContext);

	const [{ loading }, onSubmit] = useCustomAsyncFn<SubmitHandler<SupplierFormFields>>(async (data) => {
		let immediateOrderInvoiceRule = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
			(referenceList) =>
				referenceList.AD_Reference.UU === referenceUuids.ORDER_INVOICE_RULES && referenceList.Value === 'I', // Immediate
		);
		let directDepositPaymentRule = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
			(referenceList) => referenceList.AD_Reference.UU === referenceUuids.PAYMENT_TYPES && referenceList.Value === 'T', // Direct Deposit
		);
		let onCreditPaymentRule = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
			(referenceList) => referenceList.AD_Reference.UU === referenceUuids.PAYMENT_TYPES && referenceList.Value === 'P', // On Credit
		);
		let noNotification = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
			(referenceList) =>
				referenceList.AD_Reference.UU === referenceUuids.USER_NOTIFICATION_TYPES && referenceList.Value === 'X', // No Notification
		);
		let purchasePriceListUU = priceLists?.data.M_PriceListGet.Results.find((priceList) => !priceList.IsSOPriceList)?.UU;
		let salesPriceListUU = priceLists?.data.M_PriceListGet.Results.find((priceList) => priceList.IsSOPriceList)?.UU;

		let locationUU =
			initialData?.C_BPartner_Locations?.[0].C_Location.UU ||
			locationForOrganization?.data.C_LocationGet.Results[0]?.UU;
		let location: C_BPartnerInsurerOrDonorSaveMutationVariables['C_Location'] = locationUU
			? { UU: locationUU }
			: { UU: v4() };
		// If we don't have a location UUID by now, we need to get or create one
		if (!locationUU) {
			let locationFilter = DBFilter<LocationDB>();
			let organizationLocation = locationForOrganization?.data.C_LocationGet.Results[0];
			if (organizationLocation?.C_Country.UU) {
				locationFilter = locationFilter
					.nested('c_country')
					.property('c_country_uu')
					.equals(organizationLocation.C_Country.UU)
					.up();
			}
			if (organizationLocation?.C_Region?.UU) {
				locationFilter = locationFilter
					.nested('c_region')
					.property('c_region_uu')
					.equals(organizationLocation.C_Region.UU)
					.up();
			}
			let searchedLocationUU = (
				await graphqlClient.query({
					query: C_LocationForOrganizationDocument,
					variables: { Size: 1, Filter: locationFilter.toString() },
				})
			).data.C_LocationGet.Results[0]?.UU;
			// If we found something, use it
			if (searchedLocationUU) {
				location.UU = searchedLocationUU;
			} else {
				// Otherwise, make our own
				location.C_Country = organizationLocation?.C_Country.UU ? { UU: organizationLocation.C_Country.UU } : undefined;
				location.C_Region = organizationLocation?.C_Region?.UU ? { UU: organizationLocation.C_Region.UU } : undefined;
			}
		}

		try {
			await graphqlClient.mutate({
				mutation: C_BPartnerSaveForSuppliersDocument,
				variables: {
					C_BPartner: {
						UU: data.UU,
						BH_NeedAdditionalVisitInfo: undefined,
						BH_Phone: data.BH_Phone,
						BH_EMail: data.BH_EMail,
						C_PaymentTerm: paymentTerms?.data.C_PaymentTermGet.Results[0]?.UU
							? { UU: paymentTerms.data.C_PaymentTermGet.Results[0].UU }
							: undefined,
						InvoiceRule: immediateOrderInvoiceRule ? { UU: immediateOrderInvoiceRule.UU } : undefined,
						IsActive: data.IsActive,
						IsCustomer: false,
						IsVendor: true,
						M_PriceList: salesPriceListUU ? { UU: salesPriceListUU } : undefined,
						Name: data.Name,
						PaymentRule: onCreditPaymentRule ? { UU: onCreditPaymentRule.UU } : undefined,
						PaymentRulePO: directDepositPaymentRule ? { UU: directDepositPaymentRule.UU } : undefined,
						PO_PaymentTerm: paymentTerms?.data.C_PaymentTermGet.Results[0]?.UU
							? { UU: paymentTerms.data.C_PaymentTermGet.Results[0].UU }
							: undefined,
						PO_PriceList: purchasePriceListUU ? { UU: purchasePriceListUU } : undefined,
					},
					C_Location: location,
					C_BPartner_Location: initialData?.C_BPartner_Locations?.[0]?.UU
						? { UU: initialData.C_BPartner_Locations[0].UU }
						: {
								C_BPartner: { UU: data.UU },
								C_Location: { UU: location.UU! },
								Name: data.Name + ' Location',
							},
					AD_User: {
						UU: data.Contact.UU,
						C_BPartner: { UU: data.UU },
						IsFullBPAccess: false,
						IsActive: true,
						Name: data.Name,
						NotificationType: noNotification?.UU ? { UU: noNotification.UU } : undefined,
					},
				},
			});

			const savedSupplier = (
				await graphqlClient.query({
					query: C_BPartnerForSupplierEditingDocument,
					variables: { UU: data.UU },
					fetchPolicy: 'network-only',
				})
			).data.C_BPartner;

			if (!savedSupplier) {
				throw new Error('no saved BP found');
			}

			toggleNavBlocking(false);
			toast.success(t(uiText.supplier.userMessages.UPDATE_SUCCESS));
			const shouldResetForm = data.submitEvent === 'saveAndNew';
			if (shouldResetForm) {
				const newSupplier = convertToFormFields();
				reset(newSupplier);
				setInitialData(undefined);
				dataWasSaved(savedSupplier.UU);
			} else {
				onFinish(true, canSaveMany === false ? savedSupplier.UU : undefined);
			}
		} catch (error) {
			exception({ description: `Supplier save error: ${error}` });
			toast.error(t(uiText.supplier.error.COULD_NOT_SAVE, { error }));
		}
	});

	useConfirmRefresh(isDirty);

	const inputs = (
		<Form onSubmit={handleSubmit(onSubmit)} className="px-0">
			<fieldset disabled={disableWrite}>
				<input type="hidden" {...register('UU')} />
				<input type="hidden" {...register('Contact.UU')} />
				<Row className="gy-3">
					<Form.Group as={Fragment} controlId="Name">
						<Col xs={renderAsModal ? 2 : 1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.supplier.name.LABEL)}</Form.Label>
						</Col>
						<Col xs={renderAsModal ? 10 : 8}>
							<Form.Control
								{...register('Name', { required: true })}
								placeholder={t(uiText.supplier.name.byOthers.LABEL)}
							/>
							{errors.Name && <span className="text-danger">{t(uiText.supplier.name.VALIDATION2)}</span>}
						</Col>
					</Form.Group>
					{!renderAsModal && <Col xs={3} />}

					<Form.Group as={Fragment} controlId="BH_Phone">
						<Col xs={renderAsModal ? 2 : 1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.supplier.phone.ABBREVIATION)}</Form.Label>
						</Col>
						<Col xs={renderAsModal ? 10 : 8}>
							<Form.Control placeholder={t(uiText.supplier.phone.LABEL)} {...register('BH_Phone')} />
							{errors.BH_Phone && <span className="text-danger">{t(uiText.supplier.phone.VALIDATION)}</span>}
						</Col>
					</Form.Group>
					{!renderAsModal && <Col xs={3} />}

					<Form.Group as={Fragment} controlId="BH_EMail">
						<Col xs={renderAsModal ? 2 : 1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.supplier.email.LABEL)}</Form.Label>
						</Col>
						<Col xs={renderAsModal ? 10 : 8}>
							<Form.Control type="email" placeholder={t(uiText.supplier.email.HELP)} {...register('BH_EMail')} />
							<div className="invalid-feedback">{t(uiText.supplier.email.VALIDATION)}</div>
						</Col>
					</Form.Group>
					{!renderAsModal && <Col xs={3} />}

					<Form.Group as={Col} controlId="IsActive" xs={{ span: 'auto', offset: renderAsModal ? 2 : 1 }}>
						<fieldset disabled={disableDeactivate}>
							<Form.Check label={t(uiText.supplier.button.ACTIVE)} {...register('IsActive')} />
						</fieldset>
					</Form.Group>
				</Row>
			</fieldset>
		</Form>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'my-4'}`}>
			{disableWrite ? (
				<Col xs="auto">
					<BasicButton
						name={uiText.product.button.BACK}
						text={t(uiText.product.button.BACK)}
						variant="danger"
						icon="arrow-left"
						active={true}
						onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
					/>
				</Col>
			) : (
				<Fragment>
					<Col xs="auto" className="me-auto me-auto">
						<BasicButton
							name={uiText.supplier.button.CANCEL}
							text={t(uiText.supplier.button.CANCEL)}
							variant="danger"
							icon="times"
							active={true}
							onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
						/>
					</Col>
					<Col xs="auto">
						<input type="hidden" {...register('submitEvent')} defaultValue={''} />
						{canSaveMany === false ? (
							<BasicButton
								name={uiText.supplier.button.SAVE_AND_CLOSE}
								text={t(uiText.supplier.button.SAVE_AND_CLOSE)}
								variant="success"
								icon="save"
								active
								onClick={handleSubmit(onSubmit)}
							/>
						) : (
							<BHDropdownButton title={t(uiText.supplier.button.SAVE)} variant="success" icon="check">
								<Dropdown.Item onClick={handleSubmit(onSubmit)}>
									<FontAwesomeIcon icon="save" className="me-2 fa-fw" />
									{t(uiText.supplier.button.SAVE_AND_CLOSE)}
								</Dropdown.Item>
								<Dropdown.Item
									onClick={(e) => {
										setValue('submitEvent', 'saveAndNew');
										handleSubmit(onSubmit)(e);
									}}
								>
									<FontAwesomeIcon icon="paste" className="me-2 fa-fw" />
									{t(uiText.supplier.button.SAVE_AND_NEW_SUPPLIER)}
								</Dropdown.Item>
							</BHDropdownButton>
						)}
					</Col>
				</Fragment>
			)}
			{!renderAsModal && <Col xs={3} />}
		</Row>
	);

	return renderAsModal ? (
		<>
			<Modal.Header closeButton>
				<Modal.Title>{t(title)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{loading ? <LoadSpinner inline title={t(uiText.supplier.message.PROCESSING)} /> : inputs}</Modal.Body>
			{!loading ? (
				<Modal.Footer>
					<div className="w-100">{buttons}</div>
				</Modal.Footer>
			) : null}
		</>
	) : (
		<>
			<Layout.Header>
				<Layout.Title title={t(title)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<div className="me-n2_5 pb-0_5 bg-white">
					<Card className="bh-card">
						<Card.Body>
							{loading ? (
								<LoadSpinner title={t(uiText.supplier.message.PROCESSING)} />
							) : (
								<>
									{inputs}
									{buttons}
								</>
							)}
						</Card.Body>
					</Card>
				</div>
			</Layout.Body>
		</>
	);
};

export default withFormModalSuspenseWrapper<SupplierFormProps>({
	loadingLabel: uiText.supplier.loading.LOADING,
	getTitle,
	size: 'lg',
})(SupplierForm);
