import { startOfDay } from 'date-fns';
import {
	AttributeSetInstanceDB,
	BaseEntityDB,
	BusinessPartnerDB,
	businessPartnerGroupName,
	ConceptClass,
	ConceptDB,
	ConceptExtraKey,
	ConceptMappingDB,
	ConceptMappingMapType,
	ConceptSource,
	DBFilter,
	OclConceptSource,
	ProductDB,
	ProductTypeValue,
	StorageOnHandDB,
	WarehouseDB,
} from '../models';

export function getCodedDiagnosisDefaultSearchFilter(query: string) {
	return DBFilter<ConceptDB>()
		.property('isactive')
		.equals(true)
		.property('bh_source')
		.equals(ConceptSource.BHGo)
		.or(DBFilter<ConceptDB>().property('bh_display_name').contains(query))
		.or(
			DBFilter<ConceptDB>()
				.nested('bh_concept_mapping::bh_concept_id->from_bh_concept_id')
				.or(DBFilter<ConceptMappingDB>().property('bh_to_concept_code').contains(query))
				.up(),
		)
		.or(
			DBFilter<ConceptDB>()
				.nested('bh_concept_extra')
				.property('bh_key')
				.contains(ConceptExtraKey.INDEX_TERMS)
				.property('bh_value')
				.contains(query)
				.up(),
		);
}

export function getConceptTestsAndPanelsSearchFilter(query?: string) {
	let filter = DBFilter<ConceptDB>()
		.property('isactive')
		.equals(true)
		// BHLabs Originating source
		.nested('bh_ocl_originating_source')
		.property('bh_ocl_source')
		.equals(OclConceptSource.BHLABS)
		.up()
		// Concept class of Test or Panel
		.and(
			DBFilter<ConceptDB>()
				.or(DBFilter<ConceptDB>().property('bh_concept_class').equals(ConceptClass.TEST))
				.or(DBFilter<ConceptDB>().property('bh_concept_class').equals(ConceptClass.LAB_SET)),
		);

	if (query) {
		filter = filter
			// Use the given search query to look through a variety of places
			.and(
				DBFilter<ConceptDB>()
					.or(DBFilter<ConceptDB>().property('bh_display_name').contains(query))
					// Check for a local_name extra attached to the concept
					.or(
						DBFilter<ConceptDB>()
							.nested('bh_concept_extra')
							.property('bh_key')
							.equals(ConceptExtraKey.LOCAL_NAME)
							.property('bh_value')
							.contains(query)
							.up(),
					)
					// Check for a local_name extra attached to the parent mapping (which is normal for tests controlled by CIEL)
					.or(
						DBFilter<ConceptDB>()
							.nested('bh_concept_mapping::bh_concept_id->to_bh_concept_id')
							.property('bh_map_type')
							.equals(ConceptMappingMapType.CONCEPT_SET)
							.nested('bh_concept_extra')
							.property('bh_key')
							.equals(ConceptExtraKey.LOCAL_NAME)
							.property('bh_value')
							.contains(query)
							.up()
							.up(),
					),
			);
	}

	return filter;
}

export function getConceptTestsWithinPanelFilter(panelUuid: string) {
	return DBFilter<ConceptDB>()
		.property('isactive')
		.equals(true)
		.property('bh_concept_class')
		.equals(ConceptClass.TEST)
		.nested('bh_concept_mapping::bh_concept_id->to_bh_concept_id')
		.property('bh_map_type')
		.equals(ConceptMappingMapType.CONCEPT_SET)
		.property('isactive')
		.equals(true)
		.nested('bh_concept::from_bh_concept_id->bh_concept_id')
		.property('bh_concept_class')
		.equals(ConceptClass.LAB_SET)
		.property('bh_concept_uu')
		.equals(panelUuid)
		.up()
		.up();
}

export function getDefaultSearchFilter(query: string) {
	return DBFilter<BaseEntityDB>().property('name').contains(query);
}

export function getTestQAndAFilter(testUuid: string) {
	return DBFilter<ConceptDB>()
		.property('isactive')
		.equals(true)
		.property('bh_concept_class')
		.isIn([ConceptClass.FINDING, ConceptClass.MISC])
		.nested('bh_concept_mapping::bh_concept_id->to_bh_concept_id')
		.property('bh_map_type')
		.equals(ConceptMappingMapType.Q_AND_A)
		.nested('bh_concept::from_bh_concept_id->bh_concept_id')
		.property('bh_concept_uu')
		.equals(testUuid)
		.up()
		.up();
}

export function getActiveServicesAndProductsWithInventory(query: string, locatorUU?: string) {
	let productWithStorageFilter = DBFilter<ProductDB>()
		.property('producttype')
		.equals(ProductTypeValue.Item)
		.property('name')
		.contains(query)
		.nested('m_storageonhand')
		.property('qtyonhand')
		.isGreaterThan(0)
		.property('isactive')
		.equals(true)
		.and(
			DBFilter<StorageOnHandDB>()
				.nested('m_attributesetinstance')
				.or(DBFilter<AttributeSetInstanceDB>().property('guaranteeDate').isNull())
				.or(DBFilter<AttributeSetInstanceDB>().property('guaranteeDate').isGreaterThanOrEqualTo(startOfDay(new Date())))
				.up(),
		);
	if (locatorUU) {
		productWithStorageFilter.nested('m_locator').property('m_locator_uu').equals(locatorUU).up();
	}

	return DBFilter<ProductDB>()
		.or(productWithStorageFilter.up())
		.or(
			DBFilter<ProductDB>()
				.property('producttype')
				.equals(ProductTypeValue.Service)
				.property('name')
				.contains(query)
				.property('isactive')
				.equals(true),
		);
}

export function getWarehouseByOrg(organizationUuid: string) {
	return DBFilter<WarehouseDB>()
		.and(DBFilter<WarehouseDB>().property('AD_Org.AD_Org_UU').equals(organizationUuid))
		.and(DBFilter<WarehouseDB>().property('name').doesNotEqual('Standard'))
		.and(DBFilter<WarehouseDB>().property('isactive').equals(true));
}

export function getConceptTestSearchFilter(searchName?: string) {
	let testFilter = DBFilter<ConceptDB>()
		// BHLabs Originating source
		.nested('bh_ocl_originating_source')
		.property('bh_ocl_source')
		.equals(OclConceptSource.BHLABS)
		.up()
		.property('bh_concept_class')
		.equals(ConceptClass.TEST);
	if (searchName) {
		testFilter
			// search in display name (which can be scientific name)
			.or(DBFilter<ConceptDB>().property('bh_display_name').contains(searchName))
			// search in any client concepts
			.or(DBFilter<ConceptDB>().nested('bh_client_concept').property('name').contains(searchName).up())
			// search in local name attributes attached to the concept
			.or(
				DBFilter<ConceptDB>()
					.nested('bh_concept_extra')
					.property('bh_key')
					.equals(ConceptExtraKey.LOCAL_NAME)
					.property('bh_value')
					.contains(searchName)
					.up(),
			)
			// Or check the parents
			.or(
				DBFilter<ConceptDB>()
					.nested('bh_concept_mapping::bh_concept_id->to_bh_concept_id')
					.property('bh_map_type')
					.equals(ConceptMappingMapType.CONCEPT_SET)
					// search in local name attributes attached to the mapping to parent
					.or(
						DBFilter<ConceptMappingDB>()
							.nested('bh_concept_extra')
							.property('bh_key')
							.equals(ConceptExtraKey.LOCAL_NAME)
							.property('bh_value')
							.contains(searchName)
							.up(),
					)
					// search in panel display name
					.or(
						DBFilter<ConceptMappingDB>()
							.nested('bh_concept::from_bh_concept_id->bh_concept_id')
							.property('bh_concept_class')
							.equals(ConceptClass.LAB_SET)
							.or(DBFilter<ConceptDB>().property('bh_display_name').contains(searchName))
							// search in panel local name
							.or(
								DBFilter<ConceptDB>()
									.nested('bh_concept_extra')
									.property('bh_key')
									.equals(ConceptExtraKey.LOCAL_NAME)
									.property('bh_value')
									.contains(searchName)
									.up(),
							)
							.up(),
					)
					.up(),
			);
	}
	return testFilter;
}

export function getBusinessPartnerDefaultSearchFilter(searchName: string) {
	return DBFilter<BusinessPartnerDB>().property('name').contains(searchName);
}

export function getPatientSearchFilter(query: string) {
	return DBFilter<BusinessPartnerDB>()
		.nested('c_bp_group')
		.property('name')
		.equals(businessPartnerGroupName.PATIENTS)
		.up()
		.and(
			DBFilter<BusinessPartnerDB>()
				.or(DBFilter<BusinessPartnerDB>().property('name').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('BH_PatientID').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('bh_phone').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('BH_Local_PatientID').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('nextofkin_contact').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('nationalid').contains(query)),
		)
		.and(DBFilter<BusinessPartnerDB>().property('isactive').equals(true));
}
